import request from "domain/SiteAPI";
import { GET } from "domain/SiteAPI/httpMethods";
import qs from "qs";

export const get = async ({
    gameId,
    playerId,
    code = ``,
    entity = "games",
}) => {
    code =
        code ||
        qs.parse(location.search, { ignoreQueryPrefix: true }).code ||
        ``;

    return await request({
        url: `${entity}/${gameId}/players/${playerId}`,
        method: GET,
        parameters: { code },
    });
};

export const search = async ({ query, per_page = 20, page = 1 }) => {
    return await request({
        url: `players`,
        method: GET,
        parameters: {
            query,
            per_page,
            page,
        },
    });
};

//Отличается от верхнего, тем что выдает только "знакомых игроков"
export const searchFamiliarPlayers = async ({
    query,
    per_page = 20,
    page = 1,
}) => {
    if (query?.length === 1) {
        return;
    }

    return await request({
        url: `lists/players/search`,
        method: GET,
        parameters: {
            query,
            per_page,
            page,
        },
    });
};
