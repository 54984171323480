export const NAMESPACE = `BOOKINGS`;

export const SET_ITEMS = `${NAMESPACE}.SET_ITEMS`;
export const ADD_ITEM = `${NAMESPACE}.ADD_ITEM`;
export const DELETE_ITEM = `${NAMESPACE}.DELETE_ITEM`;
export const UPDATE_ITEM = `${NAMESPACE}.UPDATE_ITEM`;

export const setItems = (bookings) => {
    return {
        type: SET_ITEMS,
        payload: bookings,
    };
};

export const addItem = (booking) => {
    return {
        type: ADD_ITEM,
        payload: booking,
    };
};

export const deleteItem = (bookingId) => {
    return {
        type: DELETE_ITEM,
        payload: bookingId,
    };
};

export const updateItem = (booking) => {
    return {
        type: UPDATE_ITEM,
        payload: booking,
    };
};
