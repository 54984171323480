import call from "domain/SiteAPI/reduxAction";
import { forget } from "domain/HttpRequests/actions";
import * as API from "./api";
import { reduce } from "lodash-es";

export const NAMESPACE = `GAMES_SEARCH`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${NAMESPACE}.GET_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${NAMESPACE}.GET_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${NAMESPACE}.GET_REQUEST_ERROR`;

export const ORDERS_CHANGE_PAGE = `${NAMESPACE}_CHANGE_PAGE`;
export const ORDERS_CHANGE_FILTER = `${NAMESPACE}_CHANGE_FILTER`;
export const ORDERS_SET_FILTER = `${NAMESPACE}_SET_FILTER`;
export const ORDERS_FORGET_SEARCH = `${NAMESPACE}_FORGET_SEARCH`;

export const UPDATE_GAME_DATA = `${NAMESPACE}.UPDATE_GAME_DATA`;
export const DROP_CARD = `${NAMESPACE}.DROP_CARD`;
export const SET_ITEMS = `${NAMESPACE}.SET_ITEMS`;
export const SET_ONLY_FILTER = `${NAMESPACE}_SET_ONLY_FILTER`;
export const SET_SCROLL_TO_TOP = `${NAMESPACE}_SET_SCROLL_TO_TOP`;

let prevSearchParams = null;

export const get = ({
    page = 1,
    city_id,
    type_id,
    page_size = 12,
    all,
    my,
    past,
    alias,
}) => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(GET, prevSearchParams));
    }
    const meta = { page, city_id, type_id, page_size, all, my, past, alias };
    prevSearchParams = { ...meta };

    dispatch(
        call({
            request: API.search.bind(null, {
                page,
                city_id,
                type_id,
                page_size,
                all,
                my,
                past,
                alias,
            }),
            namespace: GET,
            meta,
        })
    );
};

export const forgetSearch = () => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(GET, prevSearchParams));
    }

    dispatch({ type: ORDERS_FORGET_SEARCH });
};

export const changePage = (page) => (dispatch) => {
    dispatch({
        type: ORDERS_CHANGE_PAGE,
        payload: page,
    });
};

export const changeFilter = (filters) => (dispatch) => {
    dispatch({
        type: ORDERS_CHANGE_FILTER,
        payload: filters,
    });
};

export const setFilters = (filters) => (dispatch) => {
    dispatch({
        type: ORDERS_SET_FILTER,
        payload: filters,
    });
};

export const setOnlyFilters = (filters) => (dispatch) => {
    dispatch({
        type: SET_ONLY_FILTER,
        payload: filters,
    });
};

export const buildSearchParams = (filters = {}, page = 1) => {
    let searchParams = `?page=${page}`;

    searchParams += reduce(
        filters,
        (accum, value, key) => accum + `&${key}=${value}`,
        ""
    );
    return searchParams;
};

export const updateGameData = (id, data) => {
    return {
        type: UPDATE_GAME_DATA,
        payload: { id, data },
    };
};

export const dropCard = (id) => {
    return {
        type: DROP_CARD,
        payload: id,
    };
};

export const setActiveGameDate = (activeGameDate) => {
    return {
        type: "SET_ACTIVE_GAMEDATE",
        payload: activeGameDate,
    };
};

export const setItems = (items) => {
    return {
        type: SET_ITEMS,
        payload: items,
    };
};

export const setShouldScrollToTop = (shouldScrollToTop) => {
    return {
        type: SET_SCROLL_TO_TOP,
        payload: shouldScrollToTop,
    };
};
