import * as types from "./actions";

export const KEY = `complex`;

const initialState = { sportComplex: {} };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_ITEM:
            return {
                ...state,
                sportComplex: payload,
            };

        default:
            return state;
    }
};

export default reducer;
