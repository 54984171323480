import React from "react";
import PropTypes from "prop-types";

import Message from "../Message";
import LoadingIndicator from "../LoadingIndicator";

class GenericLoader extends React.Component {
    componentDidMount = () => this.loadIfNeeded();

    componentDidUpdate = (prevProps) => {
        // Боремся с двойной загрузкой меню
        if (prevProps.shouldLoad === false && this.props.shouldLoad === true) {
            this.loadIfNeeded();
        }
    };

    loadIfNeeded = () => {
        if (this.props.shouldLoad) {
            this.props.load();
        }
    };

    render() {
        if (
            this.props.shouldShowErrorMessage &&
            this.props.renderErrorMessage
        ) {
            return this.props.renderErrorMessage(this.props);
        }

        if (
            this.props.shouldShowLoadingIndicator &&
            this.props.renderLoadingIndicator
        ) {
            return this.props.renderLoadingIndicator(this.props);
        }

        if (this.props.renderContent) {
            return this.props.renderContent(this.props);
        }
    }
}

GenericLoader.propTypes = {
    shouldLoad: PropTypes.bool,
    shouldShowErrorMessage: PropTypes.bool,
    shouldShowLoadingIndicator: PropTypes.bool,
    load: PropTypes.func,
    renderContent: PropTypes.func,
    renderLoadingIndicator: PropTypes.func,
    renderErrorMessage: PropTypes.func,
};

/* eslint-disable react/display-name, react/prop-types */
GenericLoader.defaultProps = {
    renderContent: (props) => props.children,
    renderLoadingIndicator: () => <LoadingIndicator />,
    renderErrorMessage: ({ errorMessage }) => (
        <div className="container">
            <Message type="danger">{errorMessage}</Message>
        </div>
    ),
};
/* eslint-enable */

export default GenericLoader;
