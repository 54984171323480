import memoize from "lodash/memoize";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";

import {toArray as setToArray} from "lib/sets";
import {values} from "lodash-es";

export const toArray = memoize((value) => {
    if (value instanceof Set) {
        return setToArray(value);
    }

    if (isPlainObject(value)) {
        return values(value);
    }

    if (isArray(value)) {
        return value;
    }

    return [];
});
