import isPlainObject from "lodash-es/isPlainObject";
import isArray from "lodash-es/isArray";

import { isWrite, POST } from "./httpMethods";
import getToken from "./csrf";
import { isValidToken } from "domain/SiteAPI/index";

const makeOptions = (token, headers, method, body, signal, isService) => {
    headers = {
        Accept: `application/json`,
        "X-Requested-With": `XMLHttpRequest`,
        ...headers,
    };

    if (isValidToken(token)) {
        headers[`Authorization`] = `Bearer ${token.value}`;
    }

    if (isWrite(method)) {
        if (!body) {
            body = {};
        }

        if (body instanceof FormData) {
            body.append(`_token`, getToken());
            body.append(`_method`, method);
        } else {
            body._token = getToken();
            body._method = method;
        }

        method = POST;
    }

    if (isPlainObject(body) || isArray(body)) {
        body = JSON.stringify(body);
        headers[`Content-Type`] = `application/json`;
    }

    if (isService) {
        return {
            method,
            body,
            headers,
            signal,
        };
    }

    return {
        method,
        body,
        headers,
        signal,
        credentials: `include`,
    };
};

export default makeOptions;
