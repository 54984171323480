class Fatal {
    constructor(message) {
        this.message = message;
    }
}

Fatal.prototype = Object.create(Error.prototype);
Fatal.prototype.constructor = Fatal;
Fatal.prototype.name = `FatalError`;

export default Fatal;
