import memoize from "lodash-es/memoize";

import { nonBreakingSpaces } from "./strings";
import numeral from "./numeral";

export const nbsp = `\u00A0`;
export const mdash = `\u2014`;

export const formatMoney = (sum, includeCents = true) => {
    const result = formatNumber(sum / 100, includeCents);

    if (!result.endsWith(`.00`)) {
        return result;
    }

    return result.substring(0, result.length - 3);
};

export const formatNumber = memoize(
    (sum, withDecimal = false) => {
        if (!withDecimal) {
            sum = Math.floor(sum);
        }

        return nonBreakingSpaces(
            withDecimal ? numeral(sum).format(`0,0.00`) : numeral(sum).format(`0,0`)
        );
    },
    (sum, withDecimal) => `${sum}-${withDecimal}`
);
