import React from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import ErrorBoundary from "components/ErrorBoundary";

import debounce from "lodash-es/debounce";
import ChooseCity from "components/ChooseCity";
import SEOMeta from "components/SEOMeta";
import SetSeoMeta from "components/SetSeoMeta";

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.switchVersion = debounce(this.switchVersion, 300).bind(this);
    }

    componentDidMount() {
        window.addEventListener(`resize`, this.switchVersion);
        this.switchVersion();
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.switchVersion);
    }

    switchVersion() {
        this.props.switchVersion(window.innerWidth);
    }

    render() {
        const url = document.location.pathname;

        const isCreateProfile = url === `/create_profile:`;
        const isAbout = url.startsWith(`/about`);

        return (
            <>
                {!isAbout && (
                    <ErrorBoundary>
                        <ChooseCity />
                    </ErrorBoundary>
                )}

                {!isCreateProfile && !isAbout && (
                    <ErrorBoundary>
                        <Header />
                    </ErrorBoundary>
                )}

                <ErrorBoundary>
                    <SEOMeta
                        type="page"
                        value={url}
                        defaultImage="49a/e6e/logo.svg"
                    />
                    <SetSeoMeta type="page" value={url} />
                </ErrorBoundary>

                <ErrorBoundary>{this.props.children}</ErrorBoundary>
            </>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    switchVersion: PropTypes.func.isRequired,
};

export default Layout;
