export const setUserField = (field, value) => {
    // noinspection JSUnresolvedVariable
    if (window.bugsnagClient) {
        window.bugsnagClient.user = window.bugsnagClient.user || {};

        window.bugsnagClient.user[field] = value;
    }
};

export const setMetadataField = (field, value) => {
    // noinspection JSUnresolvedVariable
    if (window.bugsnagClient) {
        window.bugsnagClient.metaData = window.bugsnagClient.metaData || {};

        window.bugsnagClient.metaData[field] = value;
    }
};

export const leaveBreadcrumb = (text, metadata) => {
    // noinspection JSUnresolvedVariable
    if (window.bugsnagClient && window.bugsnagClient.leaveBreadcrumb) {
        window.bugsnagClient.leaveBreadcrumb(text, metadata);
    }
};
