export const NAMESPACE = `EASY_SPORT_IMPORT`;

export const SET_IMPORTED_USER = `${NAMESPACE}.SET_IMPORTED_USER`;
export const SET_IMPORT_CONFIG = `${NAMESPACE}.SET_IMPORT_CONFIG`;
export const SET_NEED_IMPORT = `${NAMESPACE}.SET_NEED_IMPORT`;
export const SET_SHOW_MESSAGE = `${NAMESPACE}.SET_SHOW_MESSAGE`;

export const setImportedUser = (data) => {
    return {
        type: SET_IMPORTED_USER,
        payload: data,
    };
};

export const setImportedConfig = ({ easyId, hash }) => {
    return {
        type: SET_IMPORT_CONFIG,
        payload: { easyId, hash },
    };
};

export const setNeedImport = (isNeed) => {
    return {
        type: SET_NEED_IMPORT,
        payload: isNeed,
    };
};

//1- сообщение о переносе данных для авторизованного юзера
export const setShowMessage = (number) => {
    return {
        type: SET_SHOW_MESSAGE,
        payload: number,
    };
};
