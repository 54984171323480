import { connect } from "react-redux";

import { getState } from "domain/User/selectors";

import Component from "./Component";
import { getCities } from "domain/Directories/selectors";
import { getWidth } from "domain/Version/selectors";
import { setShowedCitySelector } from "domain/User/actions";
import { updateCookieCityId } from "domain/User/actions";

const mapStateToProps = (state) => {
    return {
        cityId: getState(state).cityId,
        cities: getCities(state),
        minWidth: getWidth(state),
    };
};

const mapDispatchToProps = {
    updateCityIdForUnAuth: updateCookieCityId,
    setShowedCitySelector,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
