import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Icon from "components/Icon";
import { iconTypes } from "components/Icon/types";
import { noop } from "lodash-es";
import { Link } from "react-router-dom";

const Button = ({
    size,
    children,
    iconLeft,
    iconRight,
    disabled,
    mixes,
    linkTo,
    externalLink,
    type,
    ...props
}) => {
    let Button = "button";
    let buttonProps = { type };

    if (linkTo) {
        if (externalLink) {
            Button = "a";
            buttonProps = {
                href: linkTo,
                type: null,
                target: "_blank",
                rel: "nofollow noopener noreferrer",
            };
        } else {
            Button = Link;
            buttonProps = {
                type: null,
                to: linkTo,
            };
        }
    }

    return (
        <Button
            className={cn(
                "btn",
                {
                    "btn--primary": props.primary,
                    "btn--secondary": props.secondary,
                    "btn--text": props.text,
                    "btn--subtle": props.subtle,
                    "btn--disabled": disabled,
                    "btn--minimized": props.minimized,
                    "btn--full-width": props.fullWidth,
                    "btn--tab": props.tab,
                    "btn--large": size === "large",
                    "btn--medium": size === "medium",
                    "btn--small": size === "small",
                    "btn--extra-small": size === "extra-small",
                    "btn--link": linkTo,
                    "btn--grey": props.grey
                },
                mixes
            )}
            disabled={disabled}
            form={props.form}
            onClick={props.onClick}
            {...buttonProps}
        >
            {iconLeft && (
                <span className="icon-container mr-15">
                    <Icon type={iconLeft} inheritColor />
                </span>
            )}
            {children}
            {iconRight && (
                <span className="icon-container ml-auto">
                    <Icon type={iconRight} inheritColor />
                </span>
            )}
        </Button>
    );
};

Button.defaultProps = {
    onClick: noop,
    type: "button",
    size: "medium",
    mixes: [],
};

Button.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large", "no-border", "extra-small"]),
    linkTo: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    text: PropTypes.bool,
    subtle: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    minimized: PropTypes.bool,
    grey: PropTypes.bool,
    iconLeft: iconTypes,
    iconRight: iconTypes,
    type: PropTypes.oneOf(["submit", "button"]),
    form: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    mixes: PropTypes.arrayOf(PropTypes.string),
    externalLink: PropTypes.bool,
    tab: PropTypes.bool,
};

export default Button;
