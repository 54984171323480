import { connect } from "react-redux";

import Component from "./Component";
import { getShowMessage } from "domain/EasySportImport/selectors";
import { setShowMessage } from "domain/EasySportImport/actions";
import { get as getProfile } from "domain/User/actions";
import { getProfileLastName, getProfileName } from "domain/User/selectors";

const mapStateToProps = (state) => {
    return {
        importMessageCode: getShowMessage(state),
        userFullName: `${getProfileLastName(state)} ${getProfileName(state)}`,
    };
};
const mapDispatchToProps = { setShowMessage, getProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
