import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";

export const NAMESPACE = `user`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${GET}_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${GET}_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${GET}_REQUEST_ERROR`;

export const SHOULD_FILL_PROFILE = `${NAMESPACE}.SHOULD_FILL_PROFILE`;
export const UPDATE_INITIAL_PHONE = `${NAMESPACE}.UPDATE_INITIAL_PHONE`;
export const UPDATE_COOKIE_CITY_ID = `${NAMESPACE}.UPDATE_COOKIE_CITY_ID`;
export const SET_SHOWED_CITY_SELECTOR = `${NAMESPACE}.SET_SHOWED_CITY_SELECTOR`;
export const UPDATE_COOKIE_POPOVER = `${NAMESPACE}.UPDATE_COOKIE_POPOVER`;

export const UPDATE = `${NAMESPACE}.UPDATE`;
export const UPDATE_REQUEST_START = `${UPDATE}_REQUEST_START`;
export const UPDATE_REQUEST_SUCCESS = `${UPDATE}_REQUEST_SUCCESS`;
export const UPDATE_REQUEST_ERROR = `${UPDATE}_REQUEST_ERROR`;

export const CHANGE_ORGANIZED_REGULAR_GAMES_COUNT = `${NAMESPACE}_CHANGE_ORGANIZED_REGULAR_GAMES_COUNT`;

export const REPLACE = `${NAMESPACE}.REPLACE`;

export const get = () =>
    call({
        request: API.get,
        namespace: GET,
    });

export const shouldFillProfile = (value) => {
    return {
        type: SHOULD_FILL_PROFILE,
        payload: value,
    };
};

export const updateInitialPhone = (value) => {
    return {
        type: UPDATE_INITIAL_PHONE,
        payload: value,
    };
};

export const updateCookieCityId = (value) => {
    return {
        type: UPDATE_COOKIE_CITY_ID,
        payload: value,
    };
};

export const updateCookiePopover = (value) => {
    return {
        type: UPDATE_COOKIE_POPOVER,
        payload: value,
    };
};

export const update = (fields) =>
    call({
        request: API.update.bind(null, fields),
        namespace: UPDATE,
    });

export const replace = (data) => {
    return {
        type: REPLACE,
        payload: data,
    };
};

export const changeOrganizedRegularGamesCount = (value) => {
    return {
        type: CHANGE_ORGANIZED_REGULAR_GAMES_COUNT,
        payload: value,
    };
};

export const setShowedCitySelector = (value = "true") => {
    return {
        type: SET_SHOWED_CITY_SELECTOR,
        payload: value,
    };
};
