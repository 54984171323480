import Fatal from "./Fatal";

class AuthExpired {
    message = `Необходима повторная авторизация`;
}

AuthExpired.prototype = Object.create(Fatal.prototype);
AuthExpired.prototype.constructor = AuthExpired;
AuthExpired.prototype.name = `AuthExpiredError`;

export default AuthExpired;
