import { values } from "lodash-es";

export const KEY = `events`;

export const filterValues = {
    all: "all",
    myGames: "my",
    past: "past",
};

const initialState = {
    filters: {
        ...values(filterValues).reduce(
            (accum, key) => ({
                ...accum,
                [key]: false,
            }),
            {}
        ),
        [filterValues.all]: 1,
        city_id: null,
        type_id: null,
        alias: null,
        cursor: 0,
        trainings: null,
        school_id: null,
        school_group_id: null,
        trainer_id: null,
    },
    page: 1,
    page_size: 12,
    count: 0,
    items: [],
    isItemsLoaded: false,
    aboutFilters: {},
    activeGameDate: "",
    shouldScrollToTop: false,
};

export default initialState;
