import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";
import { forget } from "domain/HttpRequests/actions";

export const NAMESPACE = `GROUPS`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${GET}_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${GET}_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${GET}_REQUEST_ERROR`;

export const SEARCH = `${NAMESPACE}.SEARCH`;
export const SEARCH_REQUEST_START = `${NAMESPACE}.SEARCH_REQUEST_START`;
export const SEARCH_REQUEST_SUCCESS = `${NAMESPACE}.SEARCH_REQUEST_SUCCESS`;
export const SEARCH_REQUEST_ERROR = `${NAMESPACE}.SEARCH_REQUEST_ERROR`;

export const CREATE = `${NAMESPACE}.CREATE`;
export const CREATE_REQUEST_START = `${CREATE}_REQUEST_START`;
export const CREATE_REQUEST_SUCCESS = `${CREATE}_REQUEST_SUCCESS`;
export const CREATE_REQUEST_ERROR = `${CREATE}_REQUEST_ERROR`;

export const UPDATE_GROUPS_STATE = `${NAMESPACE}.UPDATE_GROUPS_STATE`;
export const UPDATE_GROUP_DATA = `${NAMESPACE}.UPDATE_GROUP_DATA`;
export const SET_QUERY = `${NAMESPACE}.SET_QUERY`;
export const REMOVE = `${NAMESPACE}.REMOVE`;

let prevSearchParams = null;

export const search = ({ query }) => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(SEARCH, prevSearchParams));
    }
    prevSearchParams = { query };

    dispatch(
        call({
            request: API.search.bind(null, query),
            namespace: SEARCH,
            meta: { query },
        })
    );
};

export const get = (id) => {
    return call({
        request: API.get.bind(null, id),
        namespace: GET,
        meta: { id },
    });
};

export const recall = ({ id }) => (dispatch) => {
    dispatch(forget(GET, { id }));
    dispatch(get({ id }));
};

export const create = (fields) => (dispatch) => {
    dispatch(
        call({
            request: API.create.bind(null, fields),
            namespace: GET,
            meta: { fields },
        })
    );
};

export const updateGroupsState = (groups = {}) => {
    return {
        type: UPDATE_GROUPS_STATE,
        payload: groups,
    };
};

export const updateGroupData = (groupId, group = {}) => {
    return {
        type: UPDATE_GROUP_DATA,
        payload: {groupId, group},
    };
};

export const setQuery = (query) => {
    return {
        type: SET_QUERY,
        payload: query,
    };
};

export const remove = (groupId) => {
    return {
        type: REMOVE,
        payload: groupId,
    };
};
