import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AnimatedModal from "components/Modal/AnimatedModal";
import Button from "ui/Button";

const EasySportImportModal = ({
    importMessageCode,
    userFullName,
    setShowMessage,
    getProfile,
}) => {
    useEffect(() => {
        getProfile();
    }, []);

    const hideImportWindow = () => {
        setShowMessage(0);
        getProfile();
    };
    return (
        <>
            {importMessageCode === 1 && (
                <AnimatedModal
                    title="Привязка аккаунта с Easysport"
                    visible
                    onClose={hideImportWindow}
                    modificators={[`small-col`]}
                >
                    {() => (
                        <>
                            <p className="text-center c-gs-medium-em">
                                Привет, {userFullName}! Ваша учетная запись с
                                Easysport успешно связана с данными учетной
                                записи Наигру. В частности, обновлен ваш аватар
                                по данным Easysport.
                            </p>
                            <div className="btn-group-col">
                                <Button
                                    primary
                                    size="medium"
                                    onClick={hideImportWindow}
                                >
                                    <span className="btn__span">Ok</span>
                                </Button>
                            </div>
                        </>
                    )}
                </AnimatedModal>
            )}
        </>
    );
};

EasySportImportModal.propTypes = {
    importMessageCode: PropTypes.bool,
    userFullName: PropTypes.string,
    setShowMessage: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
};

export default EasySportImportModal;
