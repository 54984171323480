import { all, spawn, call } from "redux-saga/effects";

import AnalyticsSaga from "domain/Analytics/saga";

function* rootSaga() {
    const sagas = [AnalyticsSaga];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                    }
                }
            })
        )
    );
}
export default rootSaga;
