import request from "domain/SiteAPI";
import { GET, POST } from "domain/SiteAPI/httpMethods";
import list from "./list";

export const load = async (name) => {
    return await request({
        route: name,
        method: GET,
    });
};

export const addCities = async (field) => {
    return await request({
        url: "cities/create",
        method: POST,
        body: field,
    });
};

export const getTrainingsCountInCity = async (city) => {
    return await request({
        url: `cities/${city}/training-count`,
        method: GET,
    });
};

const handlers = {};
list.forEach((item) => (handlers[item] = load.bind(null, item)));

export default handlers;
