import * as types from "./actions";
import { cloneDeep } from "lodash-es";

export const KEY = `playgrounds`;

export const initialState = {
    items: {},
};

export const reducer = (state = cloneDeep(initialState), { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                items: { ...cloneDeep(state.items), [payload.id]: payload },
            };

        default:
            return state;
    }
};

export default reducer;
