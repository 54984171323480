import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import uniqueId from "lodash/uniqueId";

import blurInputs from "utils/blurInputs";
import controlFocusModal from "utils/controlFocusModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const IS_IOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const BODY_CLASS_OPENED = "modal-open";
export const ANIMATION_CLASS_OPENED = "modal-anim-open";
export const ANIMATION_CLASS_CLOSED = "modal-anim-close";
export const ANIMATION_DURATION = 300;

const containerId = `modals-container`;
let $container = document.querySelector(`#${containerId}`);
const root = document.querySelector("html");

if (!$container) {
    $container = document.createElement(`div`);
    $container.id = containerId;
    document.body.appendChild($container);
}

const openModals = new Set();

class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.$el = document.createElement("div");
        this.appendElementId = uniqueId(`Modal`);
        this.windowHeight = window.innerHeight;
        this.screenHeight = window.screen.height;
        this.onBodyUnfix = this.onBodyUnfix.bind(this);
        this.onBodyFix = this.onBodyFix.bind(this);

        this.scrollPosition =
            window.pageYOffset || document.documentElement.scrollTop;

        this.bodyClasses = this.props.bodyModificators.map(
            (m) => `modal-open--${m}`
        );
    }

    componentDidMount() {
        $container.appendChild(this.$el);
    }

    componentWillUnmount() {
        this.hide();
        $container.removeChild(this.$el);
    }

    onBodyFix() {
        this.scrollPosition = this.props.offsetY || this.scrollPosition;

        document.body.setAttribute(
            "data-body-scrolled",
            `${this.scrollPosition}`
        );
        document.body.style.top = `-${this.scrollPosition}px`;
        document.body.style.position = `fixed`;
        document.body.style.width = `100%`;

        if (IS_IOS) {
            controlFocusModal.fix();
        }
    }

    onBodyUnfix() {
        if (document.body.hasAttribute("data-body-scrolled")) {
            document.body.removeAttribute("data-body-scrolled");
            document.body.style.position = "";
            document.body.style.top = "";
            document.body.style.width = "";
            if (!this.props.windowScrollToTop) {
                setTimeout(() => window.scrollTo(0, this.scrollPosition), 3);

                if (IS_IOS) {
                    setTimeout(
                        () =>
                            document.documentElement.scrollTo(
                                0,
                                this.scrollPosition
                            ),
                        3
                    );
                    // setTimeout(()=>{document.documentElement.scrollTop = this.scrollPosition;}, 200);
                }
            }

            if (IS_IOS && this.type === "modal") {
                controlFocusModal.unfix();
            }
        }
    }

    onModalScroll() {
        if (IS_IOS) {
            blurInputs($container);
        }
    }

    show() {
        const {
            title,
            children,
            closeOnOverlay,
            modificators,
            image,
            onClose,
            withAnimation,
            logoWithLink,
        } = this.props;

        this.onBodyFix();
        document.body.classList.add(BODY_CLASS_OPENED);
        root.classList.add(BODY_CLASS_OPENED);

        if (withAnimation) {
            document.body.classList.add(ANIMATION_CLASS_OPENED);
        }

        if (this.bodyClasses.length) {
            this.bodyClasses.forEach((cn) => {
                document.body.classList.add(cn);
            });
        }

        const modalStyle = {
            "--winHeight": this.windowHeight + "px",
            "--screenHeight": this.screenHeight + "px",
        };

        return createPortal(
            <div
                tabIndex="-1"
                role="dialog"
                aria-labelledby={`${this.appendElementId}-title`}
                style={modalStyle}
                className={classnames(
                    `modal`,
                    `modal--open`,
                    modificators.map((m) => `modal--${m}`),
                    {
                        "modal--with-animation": withAnimation,
                    }
                )}
                onScroll={this.onModalScroll}
            >
                <div className="modal__wrapper">
                    {logoWithLink && (
                        <Link to="/" className="modal__link-logo"></Link>
                    )}
                    <button
                        className="modal__overlay"
                        onClick={closeOnOverlay ? onClose : undefined}
                    >
                        &nbsp;
                    </button>

                    <div className="modal__container">
                        <div className="modal__content">
                            {image !== undefined ? (
                                <div className="modal__image">
                                    <img alt="" src={`/images/${image}.svg`} />
                                </div>
                            ) : null}
                            {title !== undefined ? (
                                <div
                                    id={`${this.appendElementId}-title`}
                                    className="modal__title"
                                >
                                    {title}
                                </div>
                            ) : null}
                            <div className="modal__body">{children}</div>
                        </div>

                        <button
                            type="button"
                            className="close modal__close"
                            data-dismiss="modal"
                            aria-label="Закрыть"
                            onClick={onClose}
                        >
                            &nbsp;
                        </button>
                    </div>
                </div>
            </div>,
            this.$el
        );
    }

    hide() {
        if (openModals.has(this.appendElementId)) {
            openModals.delete(this.appendElementId);
        }

        if (!openModals.size) {
            document.body.classList.remove(BODY_CLASS_OPENED);
            root.classList.remove(BODY_CLASS_OPENED);

            if (this.bodyClasses.length) {
                this.bodyClasses.forEach((cn) => {
                    document.body.classList.remove(cn);
                });
            }

            this.onBodyUnfix();
        }

        return createPortal(null, this.$el);
    }

    render() {
        return this.props.visible ? this.show() : this.hide();
    }
}

Modal.defaultProps = {
    visible: true,
    closeOnOverlay: false,
    modificators: [],
    bodyModificators: [],
    withAnimation: false,
};

export const modalPropTypes = {
    title: PropTypes.node,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnOverlay: PropTypes.bool,
    visible: PropTypes.bool,
    bodyModificators: PropTypes.arrayOf(PropTypes.string),
    modificators: PropTypes.arrayOf(PropTypes.string),
    image: PropTypes.oneOf(["successful", "birthday"]),
    offsetY: PropTypes.number,
    windowScrollToTop: PropTypes.bool,
    withAnimation: PropTypes.bool,
    logoWithLink: PropTypes.bool,
};

Modal.propTypes = modalPropTypes;

export default Modal;
