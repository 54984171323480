export const isDirty = ($form) => {
    if (!$form) {
        return false;
    }

    for (let i = 0; i < $form.elements.length; i++) {
        const $element = $form.elements[i];

        switch ($element.type) {
            case `checkbox`:
            case `radio`:
                // noinspection EqualityComparisonWithCoercionJS
                if ($element.checked != $element.defaultChecked) {
                    return true;
                }
                break;

            case `hidden`:
            case `password`:
            case `text`:
            case `textarea`:
                // noinspection EqualityComparisonWithCoercionJS
                if ($element.value != $element.defaultValue) {
                    return true;
                }
                break;

            case `select-one`:
            case `select-multiple`:
                for (let j = 0; j < $element.options.length; j++) {
                    // noinspection EqualityComparisonWithCoercionJS
                    if (
                        $element.options[j].selected !=
                        $element.options[j].defaultSelected
                    ) {
                        return true;
                    }
                }
                break;

            default:
                break;
        }
    }

    return false;
};

export const getFormDataValue = (formData = new FormData(), key = "") => {
    if (!isIterable(formData)) {
        return undefined;
    }

    for (const pair of formData.entries()) {
        if (pair[0] === key) {
            return pair[1];
        }
    }

    return undefined;
};

//https://stackoverflow.com/questions/18884249/checking-whether-something-is-iterable
function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === "function";
}
