import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";
import { forget } from "domain/HttpRequests/actions";

export const NAMESPACE = `PLAYGROUND_SEARCH`;

export const SEARCH = `${NAMESPACE}.SEARCH`;
export const SEARCH_REQUEST_START = `${NAMESPACE}.SEARCH_REQUEST_START`;
export const SEARCH_REQUEST_SUCCESS = `${NAMESPACE}.SEARCH_REQUEST_SUCCESS`;
export const SEARCH_REQUEST_ERROR = `${NAMESPACE}.SEARCH_REQUEST_ERROR`;

export const SEARCH_CHANGE_PAGE = `${NAMESPACE}.SEARCH_CHANGE_PAGE`;
export const SEARCH_CHANGE_QUERY = `${NAMESPACE}.SEARCH_CHANGE_QUERY`;
export const TO_INITIAL_STATE = `${NAMESPACE}.TO_INITIAL_STATE`;
export const SET_ITEMS = `${NAMESPACE}.SET_ITEMS`;

let prevSearchParams = null;

export const search = ({ query, city_id, page = 1, page_size = 10 }) => (
    dispatch
) => {
    if (prevSearchParams) {
        dispatch(forget(SEARCH, prevSearchParams));
    }
    prevSearchParams = { query, city_id, page, page_size };

    dispatch(
        call({
            request: API.search.bind(null, { query, city_id, page, page_size }),
            namespace: SEARCH,
            meta: { query, city_id, page, page_size },
        })
    );
};

export const changePage = (page) => {
    return {
        type: SEARCH_CHANGE_PAGE,
        payload: page,
    };
};

export const changeQuery = (query) => {
    return {
        type: SEARCH_CHANGE_QUERY,
        payload: query,
    };
};

export const setItems = (playgrounds) => {
    return {
        type: SET_ITEMS,
        payload: playgrounds,
    };
};

export const changeToInitialState = () => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(SEARCH, prevSearchParams));
    }
    dispatch({
        type: TO_INITIAL_STATE,
    });
};
