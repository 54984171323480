/*import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";*/

export const NAMESPACE = `seo`;

export const SEO = `${NAMESPACE}.GET`;
export const SEO_REQUEST_START = `${SEO}_REQUEST_START`;
export const SEO_REQUEST_SUCCESS = `${SEO}_REQUEST_SUCCESS`;
export const SEO_REQUEST_ERROR = `${SEO}_REQUEST_ERROR`;

export const getSEOMeta = (type, value, cityId) => (dispatch) => {
    const key = `${type}-${value}-${cityId}`;

    if (window.NAIGRU && window.NAIGRU.seo && window.NAIGRU.seo[key]) {
        dispatch({
            type: SEO_REQUEST_SUCCESS,
            payload: window.NAIGRU.seo[key],
            meta: { type, value, cityId },
        });

        delete window.NAIGRU.seo[key]; // При следующих запросах пойдём на сервер
    } else {
        dispatch({
            type: SEO_REQUEST_SUCCESS,
            payload: {
                description: null,
                keywords: null,
                og_description: null,
                og_image: null,
                og_image_id: null,
                og_locale: null,
                og_section: null,
                og_site_name: null,
                og_title: null,
                og_type: null,
                og_url: null,
                robots: null,
                text: null,
                title: "Naigru – Запись на спортивные игры на Наигру",
                title_h1: null,
                twitter_card: null,
                twitter_description: null,
                twitter_image: null,
                twitter_image_id: null,
                twitter_title: null,
            },
            meta: { type, value, cityId },
        });
        //TODO: вернуть когда будет бэк
        /*dispatch(
            call({
                request: API.SEOMeta.bind(null, type, value, cityId),
                namespace: SEO,
                meta: { type, value, cityId }
            })
        );*/
    }
};
export const makeFakeSeo = (type, value, cityId, options={}) => {
    return{
        type: SEO_REQUEST_SUCCESS,
        payload: {
            description: null,
            keywords: null,
            og_description: null,
            og_image: null,
            og_image_id: null,
            og_locale: null,
            og_section: null,
            og_site_name: null,
            og_title: null,
            og_type: null,
            og_url: null,
            robots: null,
            text: null,
            title: null,
            title_h1: null,
            twitter_card: null,
            twitter_description: null,
            twitter_image: null,
            twitter_image_id: null,
            twitter_title: null,
            ...options
        },
        meta: { type, value, cityId },
    }
}
