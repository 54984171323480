import React from "react";
import PropTypes from "prop-types";

import Alert from "components/Alert";

class Message extends React.Component {
    render() {
        const text = this.props.text || this.props.children;

        if (!text) {
            return null;
        }

        return (
            <Alert
                visible={this.props.visible}
                type={this.props.type}
                onDismiss={this.props.onDismiss}
            >
                <p className="text--level-2" dangerouslySetInnerHTML={{ __html: text }} />
            </Alert>
        );
    }
}

Message.defaultProps = {
    visible: true,
    type: `error`,
    text: null,
    icon: null,
    onDismiss: null
};

Message.propTypes = {
    visible: PropTypes.bool,
    type: PropTypes.oneOf([`error`, `warning`, `warning-accent`, `info`, `success`]).isRequired,
    children: PropTypes.node,
    text: PropTypes.string,
    icon: PropTypes.oneOf([]),
    onDismiss: PropTypes.func
};

export default Message;
