import * as types from "./actions";

export const KEY = `version`;

const MODE_SMALL = `small`;
const MODE_MEDIUM = `medium`;
const MODE_LARGE = `large`;

const mode = width => {
    if (width < 600) {
        return MODE_SMALL;
    }

    if (width >= 1280) {
        return MODE_LARGE;
    }

    return MODE_MEDIUM;
};

const isMobile = width => mode(width) === MODE_SMALL;
const isDesktop = width => mode(width) === MODE_LARGE;

export const initialState = {
    width: window.innerWidth,
    mode: mode(window.innerWidth),
    isMobile: isMobile(window.innerWidth),
    isDesktop: isDesktop(window.innerWidth)
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SWITCH:
            return {
                width: payload,
                mode: mode(payload),
                isMobile: isMobile(payload),
                isDesktop: isDesktop(payload)
            };

        default:
            return state;
    }
};

export default reducer;
