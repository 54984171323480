export const NAMESPACE = `version`;

export const SWITCH = `${NAMESPACE}.switch`;

export const switchVersion = width => dispatch => {
    dispatch({
        type: SWITCH,
        payload: width
    });
};
