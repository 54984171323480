const makeURL = (url, parameters) => {
    const GETParameters = new URLSearchParams();
    Object.entries(parameters).forEach(([key, value]) => {
        if (value) {
            GETParameters.append(key, value);
        }
    });

    if (!GETParameters.toString()) {
        return url;
    }

    return url + `?` + GETParameters.toString();
};

export default makeURL;
