import { connect } from "react-redux";

import Component from "./Component";
import { getGameLink } from "domain/Game/selectors";
import { getGameTypeById } from "domain/Directories/selectors";
import { setViewed } from "domain/Notifications/actions";

const mapStateToProps = (state, { notification, withLink }) => {
    let gameLink = undefined;

    if (withLink) {
        gameLink = getGameLink({
            state,
            gameData: {
                id: notification?.game?.id,
                private_code: notification?.game?.private_code,
                is_public: notification?.game?.is_public,
                playground: { city_id: notification.game?.city_id },
            },
        });
    }

    return {
        gameLink,
        gameType: getGameTypeById(state, notification?.game?.type_id),
    };
};

const mapDispatchToProps = {
    setViewed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
