import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getUserId } from "domain/User/selectors";
import { setSeen } from "domain/Notifications/api";
import { add as addNotification } from "domain/Notifications/actions";
import { toast } from "react-toastify";
import NotificationsItem from "components/NotificationsItem";

const WebSocketNotifications = ({ userId, addNotification, client }) => {
    useEffect(() => {
        if (!client || !userId) {
            return null;
        }
        const channelName = `notifications.${userId}`;

        if (client.connector.channels[channelName]) {
            //не даем дублировать канал
            return null;
        }

        client
            .private(channelName)
            .listen(`NotificationWasCreated`, async (data) => {
                // делаем отметку о том, что увидели всплывающее уведомление
                await setSeen();

                addNotification(data.notification, { pauseOnHover: true });

                toast.warn(
                    <NotificationsItem notification={data.notification} />
                );
            });
    }, [client, userId]);

    return null;
};

const mapStateToProps = (state) => {
    return { userId: getUserId(state) };
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WebSocketNotifications);
