export const NAMESPACE = `analytics`;

export const TRACK_USER_CREATED = `${NAMESPACE}.TRACK_USER_CREATED`;
export const TRACK_GAME_CREATED = `${NAMESPACE}.TRACK_GAME_CREATED`;
export const TRACK_PLAYER_JOIN = `${NAMESPACE}.TRACK_PLAYER_JOIN`;
export const TRACK_PLAYER_LEFT = `${NAMESPACE}.TRACK_PLAYER_LEFT`;
export const GO_TO_STORE = `${NAMESPACE}.GO_TO_STORE`;

export const trackUserCreated = (userId) => {
    return {
        type: TRACK_USER_CREATED,
        payload: userId,
    };
};

export const trackGameCreated = (gameId) => {
    return {
        type: TRACK_GAME_CREATED,
        payload: gameId,
    };
};

export const trackPlayerJoin = (userId, gameId) => {
    return {
        type: TRACK_PLAYER_JOIN,
        payload: { userId, gameId },
    };
};

export const trackPlayerLeft = (userId, gameId) => {
    return {
        type: TRACK_PLAYER_LEFT,
        payload: { userId, gameId },
    };
};

export const goToStore = () => {
    return {
        type: GO_TO_STORE,
    };
};
