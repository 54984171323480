import * as types from "./actions";
import initialState from "./initialState";
import { cloneDeep, isEqual, values } from "lodash-es";
import * as UserTypes from "domain/User/actions";

export const KEY = `gamesSearch`;

export const getFilterState = (state, name) =>
    state.filters[name] || initialState.filters[name];

//ToDo: менять searchParams при смене фильтра
const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                page: payload.page,
                items: [...state.items, ...values(payload.items)],
                page_size: payload.page_size,
                count: payload.count,
                isItemsLoaded: true,
                aboutFilters: payload.filters,
            };

        case types.ORDERS_CHANGE_FILTER:
            // eslint-disable-next-line no-case-declarations
            const filters = { ...state.filters, ...payload };
            if (isEqual(state.filters, filters)) {
                return state;
            }
            // eslint-disable-next-line no-case-declarations
            const items = isEqual(filters, state.filters)
                ? [...state.items]
                : [];
            return {
                ...cloneDeep(state),
                filters,
                items,
                page: 1,
                isItemsLoaded: false,
            };

        case types.ORDERS_SET_FILTER:
            return {
                ...cloneDeep(state),
                filters: { ...cloneDeep(initialState.filters), ...payload },
                items: isEqual(payload, state.filters) ? [...state.items] : [],
                page: 1,
                aboutFilters: payload.filters,
            };

        case types.ORDERS_CHANGE_PAGE:
            return {
                ...cloneDeep(state),
                page: payload,
            };

        case types.ORDERS_FORGET_SEARCH:
            return {
                ...cloneDeep(state),
                items: [],
                page: 1,
                isItemsLoaded: false,
            };

        case types.DROP_CARD:
            return {
                ...cloneDeep(state),
                items: state.items.filter((item) => item.id !== payload),
            };

        case types.UPDATE_GAME_DATA:
            //Если в поиске нет игры, то и обновлять не надо
            if (
                state.items.find((item) => item.id === payload.id) === undefined
            ) {
                return state;
            }

            return {
                ...cloneDeep(state),
                items: cloneDeep(state.items).map((item) =>
                    item.id === payload.id ? payload.data : item
                ),
            };

        //ловим смену города, чтобы показать что сбросили список игр
        case UserTypes.UPDATE_COOKIE_CITY_ID:
            // eslint-disable-next-line no-case-declarations
            if (state.filters.city_id !== payload) {
                return { ...cloneDeep(state), isItemsLoaded: false };
            }
            return state;

        case "SET_ACTIVE_GAMEDATE":
            return {
                ...state,
                activeGameDate: payload,
            };

        case types.SET_ITEMS:
            return {
                ...state,
                items: payload,
            };

        case types.SET_ONLY_FILTER:
            return {
                ...state,
                filters: { ...state.filters, ...payload },
            };

        case types.SET_SCROLL_TO_TOP:
            return {
                ...state,
                shouldScrollToTop: payload,
            };

        default:
            return state;
    }
};

export default reducer;
