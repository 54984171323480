import React from "react";
import PropTypes from "prop-types";

import replaceVarToValue from "domain/SEOmeta/replaceVarToValue";

// ВНИМАНИЕ: изменения логики работы нужно вносить и в бекендовый шаблон

class SEOMeta extends React.Component {
    constructor(props) {
        super(props);

        const { city, name } = props;

        this.replaceDict = { city, name };

        this.setTitle = this.setTitle.bind(this);
        this.setTag = this.setTag.bind(this);
        this.setOgUrl = this.setOgUrl.bind(this);
        this.setCanonical = this.setCanonical.bind(this);
        this.setSchemaScript = this.setSchemaScript.bind(this);
    }

    componentDidMount() {
        Object.entries(this.props.fields).map(([key, value]) => {
            this.setTag(key, value);
        });
        this.setCanonical();
        this.setOgUrl();
        this.setSchemaScript();
    }

    componentDidUpdate(prevProps) {
        Object.entries(this.props.fields).map(([key, value]) => {
            if (!prevProps.fields || value !== prevProps.fields[key]) {
                this.setTag(key, value);
            }
        });

        if (this.props.url !== prevProps.url) {
            this.setCanonical();
            this.setOgUrl();
        }

        this.setSchemaScript();
    }

    setTag(name, value) {
        if (name === `title`) {
            this.setTitle(value);
        } else {
            this.setMeta(
                name?.startsWith("og_") ? name.replace(/_/g, `:`) : name,
                value
            );
        }
    }

    setTitle(value) {
        let $title = document.head.querySelector(`title`);

        if (!$title) {
            $title = document.createElement(`title`);
            document.head.appendChild($title);
        }

        $title.textContent =
            replaceVarToValue(value, this.replaceDict) ||
            "Наигру - Сервис организации спортивных игр";
    }

    setMeta(name, value) {
        const tagName = name.includes(`:`) ? `property` : `name`;

        let $meta = document.head.querySelector(`meta[${tagName}="${name}"]`);

        if (!value) {
            $meta && $meta.remove();
            return;
        }

        if (!$meta) {
            $meta = document.createElement(`meta`);
            $meta.setAttribute(tagName, name);
            document.head.appendChild($meta);
        }

        $meta.setAttribute(
            `content`,
            replaceVarToValue(value, this.replaceDict) || ``
        );
    }

    setOgUrl() {
        let $ogUrl = document.head.querySelector(`meta[property="og:url"]`);

        if (!$ogUrl) {
            $ogUrl = document.createElement(`meta`);
            $ogUrl.setAttribute(`property`, `og:url`);
            document.head.appendChild($ogUrl);
        }

        $ogUrl.setAttribute(`content`, this.props.url);
    }

    setCanonical() {
        let $canonical = document.head.querySelector(`link[rel=canonical]`);

        if (!$canonical) {
            $canonical = document.createElement(`link`);
            $canonical.setAttribute(`rel`, `canonical`);
            document.head.appendChild($canonical);
        }

        $canonical.setAttribute(`href`, this.props.url);
    }

    scheduleSeoConverter(list) {
        let string = "";

        if (!list) {
            return string;
        }

        list.some((item, index) => {
            if (item.time === "Выходной") {
                item = {};
                return;
            }

            if (index !== list.length) {
                item.time = " " + item.time;
                string += item.days + item.time + ` `;
            } else {
                string += item.days + item.time;
            }
        });

        string = string.replace(/ - /g, "-").slice(0, -1);

        return string;
    }

    setSchemaScript() {
        const $scripts = document.head.querySelectorAll(
            `script[type="application/ld+json"]`
        );

        for (let i = 0; i < $scripts.length; i++) {
            $scripts[i].remove();
        }

        if (this.props.value === "/") {
            const data = {
                "@context": "http://schema.org",
                //"@type": "Restaurant",
                name: "Наигру",
                description: replaceVarToValue(
                    this.props.fields.description,
                    this.replaceDict
                ),
            };

            const $script = document.createElement(`script`);
            $script.type = "application/ld+json";
            $script.text = JSON.stringify(data, null, `\t`);
            document.head.appendChild($script);
        }
    }

    render() {
        return null;
    }
}

SEOMeta.propTypes = {
    fields: PropTypes.objectOf(PropTypes.string).isRequired,
    cityName: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    url: PropTypes.string.isRequired,
    city: PropTypes.string,
};

export default SEOMeta;
