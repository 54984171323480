import React from "react";
import { connect } from "react-redux";

import NotificationsCountLoader from "components/Loaders/NotificationsCheck";
import { getUnreadNotificationsCount } from "domain/Notifications/selectors";
import Component from "./Component";

const NotificationsCounterContainer = (props) => (
    <NotificationsCountLoader renderErrorMessage={() => null}>
        <Component {...props} />
    </NotificationsCountLoader>
);

const mapStateToProps = (state) => {
    return {
        count: getUnreadNotificationsCount(state),
    };
};

export default connect(mapStateToProps)(NotificationsCounterContainer);
