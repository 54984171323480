import { applyMiddleware, compose, createStore } from "redux";
import reduxThunk from "redux-thunk";
/*import logger from "redux-logger";*/
import createSagaMiddleware from "redux-saga";

import siteAPI from "domain/SiteAPI/reduxMiddleware";
import reducer from "./reducer";
import rootSaga from "./saga";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

/* eslint-disable no-underscore-dangle */
const store = createStore(
    reducer,
    undefined,
    composeEnhancers(
        compose(applyMiddleware(reduxThunk, siteAPI, /*logger,*/ sagaMiddleware))
    )
);
/* eslint-enable */

sagaMiddleware.run(rootSaga);

export default store;
