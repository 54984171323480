import trim from "lodash-es/trim";
import { getCityForUser } from "domain/User/selectors";
import { getInitialFilters } from "components/Loaders/GamesList";
import { getGameById } from "domain/Game/selectors";
import {
    getCityById,
    getGameTypeById,
    getLevelById,
} from "domain/Directories/selectors";
import { DateTime as Luxon } from "luxon";
import { getAboutFilters } from "domain/GamesSearch/selectors";
import { getSchool } from "domain/Schools/selectors";
import { getSportComplex } from "domain/SportComplex/selectors";

export const constructOptions = (state) => {
    let options = {
        title: `Спортивные игры | Наигру`,
        description: `Запись на тренировки и игры в Волейбол, Баскетбол, Футбол в удобном приложении "Наигру" для организации спортивных игр.`,
        og_title: `Спортивные игры | Наигру`,
        og_description: `Запись на тренировки и игры в Волейбол, Баскетбол, Футбол`,
        og_image: `${document.location.origin}/images/main-vk.png`,
        "vk:image": `${document.location.origin}/images/main-vk.png`,
        "fb:image": `${document.location.origin}/images/main-fb.png`,
        og_url: document.location.href,
        og_type: "website",
    };

    const pathname = document.location.pathname;
    const path = trim(pathname, "/").split("/");

    let city = getCityForUser(state);

    if (path[1] === "games") {
        //список игр
        const filters = getInitialFilters(state);
        const aboutFilters = getAboutFilters(state);

        if (filters.my) {
            //Список игр. фильтр: мои
            return { title: "Мои игры – Игры с моим участием на Наигру" };
        }

        if (filters.past) {
            //Список игр. фильтр: прошедшие
            return {
                title:
                    "Прошедшие игры – Завершенные игры с моим участием на Наигру",
            };
        }

        //Список игр. формируем в зависимости от города
        if (filters.city_id) {
            city = getCityById(state, filters.city_id);
            if (!city?.name) {
                return options;
            }

            if (aboutFilters.alias) {
                return {
                    ...options,
                    title: `Игры от организатора ${aboutFilters.alias?.organizer?.name} ${aboutFilters.alias?.organizer?.family_name} в г. ${city.name} | Наигру`,
                    og_title: `Игры от организатора ${aboutFilters.alias?.organizer?.name} ${aboutFilters.alias?.organizer?.family_name} в г. ${city.name} | Наигру`,
                    og_description: `Запись на тренировки и игры от организатора ${aboutFilters.alias?.organizer?.name} ${aboutFilters.alias?.organizer?.family_name} в г. ${city.name}`,
                    description: `Запись на тренировки и игры от организатора ${aboutFilters.alias?.organizer?.name} ${aboutFilters.alias?.organizer?.family_name} в г. ${city.name}`,
                };
            }

            if (filters.type_id) {
                const gameType = getGameTypeById(state, filters.type_id);

                if (gameType?.image_url) {
                    options["og_image"] = gameType.image_url;
                    options["vk:image"] = gameType.image_url;
                    options["fb:image"] = gameType.image_url;
                }

                if (!gameType?.name) {
                    return {
                        ...options,
                        title: `Спортивные игры в г. ${city.name} | Наигру`,
                        og_title: `Спортивные игры в г. ${city.name} | Наигру`,
                        og_description: `Запись на тренировки и игры в Волейбол, Баскетбол, Футбол в г. ${city.name}`,
                        description: `Запись на тренировки и игры в Волейбол, Баскетбол, Футбол в г. ${city.name}`,
                    };
                }

                return {
                    ...options,
                    title: `Игры в ${gameType.name} в г. ${city.name} | Наигру`,
                    og_title: `Игры в ${gameType.name} в г. ${city.name} | Наигру`,
                    og_description: `Запись на тренировки и игры в ${gameType.name} в г. ${city.name}`,
                    description: `Запись на тренировки и игры в ${gameType.name} в г. ${city.name}`,
                };
            } else {
                return {
                    ...options,
                    title: `Спортивные игры в г. ${city.name} | Наигру`,
                    og_title: `Спортивные игры в г. ${city.name} | Наигру`,
                    og_description: `Запись на тренировки и игры в Волейбол, Баскетбол, Футбол в г. ${city.name}`,
                    description: `Запись на тренировки и игры в Волейбол, Баскетбол, Футбол в г. ${city.name}`,
                };
            }
        }
    }

    //Второй вариант еслси первая часть - это название города
    if (path[1] === "game" || path[2] === "game") {
        //Создание игры
        if (path[2] === "create") {
            if (!city?.name) {
                return options;
            }
            return {
                title: `Создание игры – Организация игры и подбор игроков в ${city.name} на Наигру`,
            };
        } else {
            //страница игры
            const id = parseInt(path[2]);
            const game = getGameById(state, id);

            if (!game?.id) {
                options.title = `Игра №${id} | Наигру`;
                return options;
            }

            const gameType = getGameTypeById(state, game.type_id);
            const gameLevel = getLevelById(state, game.level_id).name || "";
            const dateTime = Luxon.fromSQL(game.starts_at);
            const organizer = `${game.organizer?.family_name} ${game.organizer?.name}`;
            const playground = game.playground?.name || "";
            const playgroundAddress = game.playground?.address || "";
            const readableDateTime = `${dateTime.toFormat(
                "dd.MM"
            )} в ${dateTime.toLocaleString(Luxon.TIME_24_SIMPLE)}`;

            if (gameType?.image_url) {
                options["og_image"] = gameType.image_url;
                options["vk:image"] = gameType.image_url;
                options["fb:image"] = gameType.image_url;
            }

            const slice = `${gameType.name} ${readableDateTime}`;

            if (game?.is_past) {
                //страница прошедшей игры
                options.title = `Прошедшая игра №${game.id} | Наигру`;
                options.description = `Тренировка по ${gameType.name} для ${gameLevel} с ${organizer} прошла ${readableDateTime} в ${playground} по адресу: ${playgroundAddress}. Выбирайте следующее мероприятие и записывайтесь на игру! №${game.id}`;
            } else if (!game?.is_public) {
                //страница приватной игры
                options.title = `Закрытая игра №${game.id} | Наигру`;
                options.description = `Тренировка по ${gameType.name} для ${gameLevel} с ${organizer} состоится ${readableDateTime}. Пройдет мероприятие в ${playground} по адресу: ${playgroundAddress}. Участие в игре по приглашению организатора.  №${game.id}`;
            } else {
                //страница обычной игры
                options.title = `${gameType.name} ${readableDateTime} в ${playground} с ${organizer} №${game.id} | Наигру`;
                options.description = `Тренировка по ${gameType.name} для ${gameLevel} с ${organizer} состоится ${readableDateTime}. Пройдет мероприятие в ${playground} по адресу: ${playgroundAddress}. Участие в игре по предварительной записи.  №${game.id}`;
            }
            return {
                ...options,
                og_title: slice,
                og_description: options.description,
            };
        }
    }

    if (path[1] === "schools") {
        return {
            ...options,
            title: `Тренировки в городе ${city.name} | Наигру`,
            og_title: `Тренировки в городе ${city.name} | Наигру`,
            og_description: `Спортивные школы и тренировки в городе ${city.name} для взрослых и детей`,
            description: `Спортивные школы и тренировки в городе ${city.name} для взрослых и детей`,
        };
    }

    if (path[1] === "school") {
        const id = parseInt(path[2]);
        const school = getSchool(state);

        if (!school?.id) {
            options.title = `Спортивная школа №${id} в г.${city.name} | Наигру`;
            return options;
        }

        if (path[3] === "edit") {
            return {
                ...options,
                title: `Редактирование спортивной школы ${
                    school?.title || ""
                } | Наигру`,
                og_title: `Редактирование спортивной школы ${
                    school?.title || ""
                } | Наигру`,
                og_description: `Редактирование спортивной школы ${
                    school?.title || ""
                } в городе ${city.name} | Наигру`,
                description: `Редактирование спортивной школы ${
                    school?.title || ""
                } в городе ${city.name} | Наигру`,
            };
        }

        return {
            ...options,
            title: `Спортивная школа ${school?.title || ""} в городе ${
                city.name
            } | Наигру`,
            og_title: `Спортивная школа ${school?.title || ""} в городе ${
                city.name
            } | Наигру`,
            og_description: `Информация и тренировки спортивной школы ${
                school.title || ""
            } в городе ${city.name}`,
            description: `Информация и тренировки спортивной школы ${
                school.title || ""
            } в городе ${city.name}`,
        };
    }

    if (path[1] === "sportcourts") {
        if (path[2] === "new_request") {
            return {
                ...options,
                title: `Заявка на добавление арендодателя | Наигру`,
                og_title: `Заявка на добавление арендодателя | Наигру`,
                og_description: `Заявка на добавление арендодателя`,
                description: `Заявка на добавление арендодателя`,
            };
        }
        return {
            ...options,
            title: `Спортивные комплексы в г. ${city.name} | Наигру`,
            og_title: `Спортивные комплексы в г. ${city.name} | Наигру`,
            og_description: `Спортивные комплексы в г. ${city.name} - бронирование и аренда.`,
            description: `Спортивные комплексы в г. ${city.name} - бронирование и аренда.`,
        };
    }

    if (path[1] === "sportcourt") {
        if (path[0] === city.code) {
            const complex = getSportComplex(state);
            const text = `${
                complex?.name ? complex?.name : "Спортивный комплекс"
            } | Наигру`;
            return {
                ...options,
                title: text,
                og_title: text,
                og_description: text,
                description: text,
            };
        }

        return {
            ...options,
            title: `Редактирование спортивного комплекса | Наигру`,
            og_title: `Редактирование спортивного комплекса | Наигру`,
            og_description: `Редактирование спортивного комплекса`,
            description: `Редактирование спортивного комплекса`,
        };
    }

    if (path[1] === "cabinet") {
        return {
            ...options,
            title: `Кабинет организации | Наигру`,
            og_title: `Кабинет организации | Наигру`,
            og_description: `Кабинет организации`,
            description: `Кабинет организации`,
        };
    }

    if (path[0] === "settings" && path[1] === "password_reset") {
        return {
            title: "Смена пароля в сервисе Наигру",
            description:
                "Смените пароль для входа в личный кабинет Наигру - сервис для организации спортивных игр. Продолжайте создавать тренировки или участвовать в них.",
        };
    }

    switch (path[0]) {
        case "signup":
            return { title: "Регистрация" };
        case "privacy":
            return {
                title: "Политика конфиденциальности | Наигру",
                description:
                    'Настоящая Политика разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных". Чтобы узнать больше, пожалуйста, ознакомьтесь с Политикой конфиденциальности Наигру.',
            };
        case "signin":
            return {
                title: "Вход в сервис Наигру",
                description:
                    "Авторизуйтесь в сервисе организации спортивных игр. Создавайте тренировки по командным видам спорта в пару кликов или участвуйте в них.",
            };
        case "contacts":
            return {
                title: "Контакты Наигру",
                description:
                    "Возникли вопросы по сервису или хотите оставить пожелания? Напишите в Телеграм лично руководителю проекта! https://t.me/suharevvitaly ",
            };
        case "agreement":
            return {
                title: "Пользовательское соглашение Наигру",
                description:
                    "Настоящее Соглашение заключается между владельцем сервиса Наигру и любым лицом, становящимся после принятия условий данного соглашения пользователем сайта или мобильного приложения. Подробнее об условиях Пользовательского соглашения в документе.",
            };
        case "groups":
            return {
                title: "Списки игроков | Наигру",
                description:
                    "Просматривайте и редактируйте списки игроков, которые уже посещали с вами тренировку - приглашайте на следующие события в сервисе для организации спортивных игр Наигру.",
            };
        case "notifications":
            return {
                title: "Уведомления Наигру",
                description:
                    "Отслеживайте уведомления о важных событиях в сервисе для организации спортивных игр Наигру - изменения в ваших тренировках или играх, на которые вы записались, переход в основной состав игроков и другое.",
            };
        case "profile":
            return path[1] === "edit"
                ? {
                      title: "Редактирование профиля | Наигру",
                      description:
                          "Смените пароль для входа в личный кабинет Наигру - сервис для организации спортивных игр. Продолжайте создавать тренировки или участвовать в них.",
                  }
                : {
                      title: "Личный кабинет пользователя | Наигру",
                      description:
                          "Отслеживайте расписание своих тренировок в личном кабинете Наигру - контролируйте организованные вами игры или тренировки, на которые вы записались в качестве участника.",
                  };
        case "about":
            return {
                title: "О сервисе Наигру",
                description:
                    "Приложение для организации спортивных игр. Быстро и удобно создавайте тренировки или участвуйте в них",
            };
    }

    return options;
};
