import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { noop } from "lodash-es";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PaidSelect from "../../../shared/PaidSelect/PaidSelect";

const PersonPreview = ({
    avatar,
    family_name,
    name,
    mods,
    with_position,
    position,
    onClick,
    id,
    fullAvatar,
    showCroppedAvatar = false,
    school,
    linkToProfile,
    payment,
    isMobile,
    paidSelectProp,
    isOrganizer,
}) => {
    const handleClick = () => {
        onClick(id);
    };

    const getImageSource = (image) => {
        let source = image?.url;

        if (image?.small_image_url?.length > 0) {
            source = image?.small_image_url;
        }

        if (fullAvatar && image?.full_image_url?.length > 0) {
            source = image?.full_image_url;
        }

        if (showCroppedAvatar && image?.cropped_image_url?.length > 0) {
            source = image?.cropped_image_url;
        }

        return source;
    };

    let avatarSource = school
        ? getImageSource(school.logo)
        : getImageSource(avatar);

    return (
        <div
            className={classNames(`person`, mods, {
                "person_full-avatar": fullAvatar,
            })}
            onClick={handleClick}
        >
            {linkToProfile ? (
                <Link to={"/profile"}>
                    <div className="person__avatar">
                        {avatarSource?.length > 0 && (
                            <img
                                src={avatarSource}
                                alt={family_name + " " + name}
                                loading="lazy"
                            />
                        )}
                    </div>
                </Link>
            ) : (
                <div className="person__avatar">
                    {avatarSource?.length > 0 && (
                        <img
                            src={avatarSource}
                            alt={family_name + " " + name}
                            loading="lazy"
                        />
                    )}
                </div>
            )}

            <div className="person__right-col">
                {linkToProfile ? (
                    <Link to={"/profile"}>
                        <div className="person__name">
                            {school ? (
                                <p>{school.title}</p>
                            ) : (
                                <>
                                    <p>{family_name}</p>
                                    <p>{name}</p>
                                </>
                            )}
                        </div>
                    </Link>
                ) : (
                    <div className="person__name">
                        {school ? (
                            <p>{school.title}</p>
                        ) : (
                            <>
                                <p>{family_name}</p>
                                <p>{name}</p>
                            </>
                        )}
                    </div>
                )}

                {with_position && (
                    <p className="person__position fs-caption c-gs-label">
                        {position}
                    </p>
                )}

                {payment && (
                    <p className="person__position fs-caption">{payment}</p>
                )}

                {isMobile && paidSelectProp.is_came && isOrganizer && (
                    <PaidSelect
                        id={id}
                        is_online_pay={paidSelectProp.is_online_pay}
                        is_paid={paidSelectProp.is_paid}
                        changeUnpaidPlayers={paidSelectProp.changeUnpaidPlayers}
                        needReport={paidSelectProp.needReport}
                    />
                )}
            </div>
        </div>
    );
};

PersonPreview.defaultProps = {
    avatar: {},
    family_name: "",
    name: "",
    mods: [],
    with_position: false,
    position: "Организатор",
    onClick: noop,
    linkToProfile: false,
};

PersonPreview.propTypes = {
    id: PropTypes.number.isRequired,
    avatar: PropTypes.shape({
        url: PropTypes.string,
        full_image_url: PropTypes.string,
        cropped_image_url: PropTypes.string,
        small_image_url: PropTypes.string,
    }),
    family_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mods: PropTypes.array,
    with_position: PropTypes.bool,
    position: PropTypes.string,
    onClick: PropTypes.func,
    fullAvatar: PropTypes.bool,
    showCroppedAvatar: PropTypes.bool,
    school: PropTypes.object,
    linkToProfile: PropTypes.bool,
    payment: PropTypes.string,
    isMobile: PropTypes.bool,
    paidSelectProp: PropTypes.object,
    isOrganizer: PropTypes.bool,
};

export default PersonPreview;
