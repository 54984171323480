import isPlainObject from "lodash-es/isPlainObject";
import isString from "lodash-es/isString";
import isFunction from "lodash-es/isFunction";

import AuthExpired from "errors/AuthExpired";
import inDevelopmentMode from "lib/inDevelopmentMode";
import { logout } from "domain/Auth/actions";

export const CALL = `CALL`;

export const START_SUFFIX = "_REQUEST_START"
export const SUCCESS_SUFFIX = "_REQUEST_SUCCESS"
export const ERROR_SUFFIX = "_REQUEST_ERROR"

const siteAPI = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== CALL) {
        next(action);
        return;
    }

    const { namespace, meta } = action;
    let { actions: { start, success, error } = {} } = action;

    if (namespace) {
        if (!start) {
            start = namespace + START_SUFFIX;
        }

        if (!success) {
            success = namespace + SUCCESS_SUFFIX;
        }

        if (!error) {
            error = namespace + ERROR_SUFFIX;
        }
    }

    try {
        next(await buildAction(start, null, meta));

        const response = await action.request();

        next(await buildAction(success, response, meta));

        return true;
    } catch (err) {
        next(await buildAction(error, err, meta));

        if (err instanceof AuthExpired) {
            dispatch(logout());
        }

        return false;
    }
};

const buildAction = async (action, payload = null, meta = null) => {
    if (isFunction(action)) {
        action = action(payload);
    }

    if (action instanceof Promise) {
        action = await action;
    }

    if (isString(action)) {
        action = {
            type: action,
            payload,
            meta,
        };
    } else if (!isPlainObject(action)) {
        if (inDevelopmentMode) {
            // eslint-disable-next-line no-console
            console.warn(
                `[SiteAPI]: action must be a string, plain object or a function; ${typeof action} given.`,
                action
            );
        }
    }

    return action;
};

export default siteAPI;
