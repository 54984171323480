import * as types from "./actions";
import { cloneDeep } from "lodash-es";

export const KEY = `playgroundSearch`;

export const initialState = {
    items: [],
    page_size: 10,
    page: 1,
    count: 0,
    query: null,
};

export const reducer = (state = cloneDeep(initialState), { type, payload }) => {
    switch (type) {
        case types.SEARCH_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: [...cloneDeep(state.items), ...payload.items],
                count: payload.count,
                page: payload.page,
            };

        case types.SEARCH_CHANGE_PAGE:
            return {
                ...cloneDeep(state),
                page: payload,
            };

        case types.SEARCH_CHANGE_QUERY:
            return {
                ...cloneDeep(state),
                items: [],
                page: 1,
                query: payload,
                count: 0,
            };

        case types.TO_INITIAL_STATE:
            return initialState;

        case types.SET_ITEMS:
            return {
                items: payload,
            };

        default:
            return state;
    }
};

export default reducer;
