import { combineReducers } from "redux";

import httpRequests, {
    KEY as HTTP_REQUESTS_KEY,
} from "domain/HttpRequests/reducer";
import auth, { KEY as AUTH_KEY } from "domain/Auth/reducer";
import directories, {
    KEY as DIRECTORIES_KEY,
} from "domain/Directories/reducer";
import user, { KEY as USER_KEY } from "domain/User/reducer";
import captcha, { KEY as CAPTCHA_KEY } from "domain/Captcha/reducer";
import version, { KEY as VERSION_KEY } from "domain/Version/reducer";
import gamesSearch, {
    KEY as GAMES_SEARCH_KEY,
} from "domain/GamesSearch/reducer";
import game, { KEY as GAME_KEY } from "domain/Game/reducer";
import playgroundSearch, {
    KEY as PLAYGROUND_SEARCH_KEY,
} from "domain/PlaygroundSeacrh/reducer";
import playgrounds, {
    KEY as PLAYGROUNDS_KEY,
} from "domain/Playgrounds/reducer";
import seoMeta, { KEY as SEO_META_KEY } from "domain/SEOmeta/reducer";
import users, { KEY as USERS_KEY } from "domain/Users/reducer";
import notifications, {
    KEY as NOTIFICATIONS_KEY,
} from "domain/Notifications/reducer";
import groups, { KEY as GROUPS_KEY } from "domain/Groups/reducer";
import easySportImport, {
    KEY as EASY_SPORT_IMPORT_KEY,
} from "domain/EasySportImport/reducer";
import blacklists, { KEY as BLACKLISTS_KEY } from "domain/BlackLists/reducer";
import regularGames, {
    KEY as REGULAR_GAMES,
} from "domain/RegularGames/reducer";
import schools, { KEY as SCHOOLS } from "domain/Schools/reducer";
import sportcourts, {
    KEY as SPORTCOURTS,
} from "domain/CourtsInComplex/reducer";
import bookings, { KEY as BOOKINGS } from "domain/Bookings/reducer";
import sportComplex, { KEY as SPORTCOMPLEX } from "domain/SportComplex/reducer";
import services, { KEY as SERVICES } from "domain/ServicesInComplex/reducer";

export default combineReducers({
    [HTTP_REQUESTS_KEY]: httpRequests,
    [AUTH_KEY]: auth,
    [DIRECTORIES_KEY]: directories,
    [USER_KEY]: user,
    [CAPTCHA_KEY]: captcha,
    [VERSION_KEY]: version,
    [GAMES_SEARCH_KEY]: gamesSearch,
    [GAME_KEY]: game,
    [PLAYGROUND_SEARCH_KEY]: playgroundSearch,
    [PLAYGROUNDS_KEY]: playgrounds,
    [SEO_META_KEY]: seoMeta,
    [USERS_KEY]: users,
    [NOTIFICATIONS_KEY]: notifications,
    [GROUPS_KEY]: groups,
    [EASY_SPORT_IMPORT_KEY]: easySportImport,
    [BLACKLISTS_KEY]: blacklists,
    [REGULAR_GAMES]: regularGames,
    [SCHOOLS]: schools,
    [SPORTCOURTS]: sportcourts,
    [BOOKINGS]: bookings,
    [SPORTCOMPLEX]: sportComplex,
    [SERVICES]: services,
});
