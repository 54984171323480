import request from "domain/SiteAPI";
import { GET, POST } from "domain/SiteAPI/httpMethods";

export const get = async ({ id }) => {
    return await request({
        url: `playgrounds/${id}`,
        method: GET,
    });
};

export const create = async (fields) => {
    return await request({
        url: `playgrounds`,
        method: POST,
        body: fields._blob ? fields._blob() : fields,
    });
};

export const addLandlord = async (fields) => {
    return await request({
        url: `playgrounds/request`,
        method: POST,
        body: fields._blob ? fields._blob() : fields,
    });
};

//временный запрос, чтобы показывать раздел только нужным юзерам
export const canShow = async () => {
    return await request({
        url: `playgrounds/can_show`,
        method: GET,
    });
};
