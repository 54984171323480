import React from "react";
import PropTypes from "prop-types";

const Popover = ({ children }) => {
    return (
        <div className="sidebar__link">
            <div className="tooltip__popup_right popup">
                Все ваши регулярные игры в новом пункте меню
                {children}
            </div>
            <div className="tooltip__btn tooltip__btn_placement-right" />
        </div>
    );
};

Popover.propTypes = {
    children: PropTypes.node,
};

export default Popover;
