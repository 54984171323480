import * as types from "./actions";

export const KEY = `schools`;

const initialState = { schools: [], school: {} };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SAVE_ITEMS:
            return {
                ...state,
                schools: payload,
            };

        case types.SAVE_ITEM:
            return {
                ...state,
                school: payload,
            };

        case types.UPDATE_ITEM:
            return {
                ...state,
                school: {
                    ...state.school,
                    [payload.key]: payload.value,
                },
            };

        default:
            return state;
    }
};

export default reducer;
