import uniq from "lodash-es/uniq";
import flatten from "lodash-es/flatten";

import { toArray } from "lib/converters";
import SiteAPI from "./SiteAPI";

class Validation {
    constructor(response) {
        this.status = 422;
        this.message = response.description;
        this.errors = response.payload.messages;
        this.flatErrors = uniq(flatten(toArray(this.errors)));
        this.fields = new Set(
            flatten(Object.keys(this.errors || {}).map(name => [name, name.split(`.`)[0]]))
        );
    }
}

Validation.prototype = Object.create(SiteAPI.prototype);
Validation.prototype.constructor = Validation;
Validation.prototype.name = `ValidationError`;

export default Validation;
