import { useSelector } from "react-redux";
import { KEY } from "./reducer";

export const getState = (state) => state[KEY] || [];

export const getSchools = (state) => getState(state).schools || [];

export const getSchool = (state) => getState(state).school || {};

export const getSchoolById = (state, id) =>
    getSchools(state).find((item) => item.id == id);

export const getSchoolsSelector = () =>
    useSelector((state) => getSchools(state));

export const getSchoolSelector = () => useSelector((state) => getSchool(state));
