import React from "react";
import forEach from "lodash-es/forEach";

import { notificationsTypes } from "domain/Notifications/types";
import { gameTypeTypes, levelTypes } from "domain/Directories/types";
import { DateTime as Luxon } from "luxon";
import { getGenderByCode } from "domain/Directories/selectors";
import { formatMoney } from "lib/typography";

/*eslint-disable*/
const ChangedField = ({
    fieldName,
    oldValue,
    newValue,
    afterNewValue,
    beforeNewValue,
}) => (
    /*eslint-enable */
    <div className="notification__changed-field">
        {fieldName}: {oldValue} <span className="arrow">→</span>{" "}
        {beforeNewValue && " " + beforeNewValue}
        <span className="notification__new-field">{newValue}</span>{" "}
        {afterNewValue && " " + afterNewValue}
    </div>
);

const ChangedFields = ({ notification, gameTypes, levels }) => {
    const { payload, type } = notification;

    if (type !== "game_changed") {
        return null;
    }

    const additionalData = [];

    forEach(payload.before, (oldValue, key) => {
        const newValue = payload.after[key];
        switch (key) {
            case "starts_at":
                // eslint-disable-next-line no-case-declarations
                const oldDate = Luxon.fromSQL(oldValue),
                    newDate = Luxon.fromSQL(newValue),
                    dateChanged =
                        newDate.toFormat("dd.LL") !==
                        oldDate.toFormat("dd.LL"),
                    timeChanged =
                        newDate.toFormat("H:mm") !== oldDate.toFormat("H:mm");

                additionalData.push(
                    <ChangedField
                        key={key}
                        fieldName={gameFields[key]}
                        oldValue={oldDate.toFormat("H:mm dd.LL")}
                        beforeNewValue={
                            !timeChanged ? newDate.toFormat("H:mm") : null
                        }
                        newValue={newDate.toFormat(
                            `${timeChanged ? "H:mm" : ""} ${
                                dateChanged ? "dd.LL" : ""
                            }`
                        )}
                        afterNewValue={
                            !dateChanged ? newDate.toFormat("dd.LL") : null
                        }
                    />
                );
                break;

            case "level_id":
                additionalData.push(
                    <ChangedField
                        key={key}
                        fieldName={gameFields[key]}
                        oldValue={
                            levels.find((level) => level.id === oldValue).name
                        }
                        newValue={
                            levels.find((level) => level.id === newValue).name
                        }
                    />
                );
                break;
            case "type_id":
                additionalData.push(
                    <ChangedField
                        key={key}
                        fieldName={gameFields[key]}
                        oldValue={
                            gameTypes.find((type) => type.id === oldValue).name
                        }
                        newValue={
                            gameTypes.find((type) => type.id === newValue).name
                        }
                    />
                );
                break;

            case "players_gender":
                additionalData.push(
                    <ChangedField
                        key={key}
                        fieldName={gameFields[key]}
                        oldValue={getGenderByCode(oldValue)}
                        newValue={getGenderByCode(newValue)}
                    />
                );
                break;

            case "private_code":
                additionalData.push(
                    <div key={key}>
                        Игра стала {!newValue ? "публичной" : "закрытой"}
                    </div>
                );
                break;
            case "is_public":
                additionalData.push(
                    <div key={key}>
                        Игра стала {newValue ? "публичной" : "закрытой"}
                    </div>
                );
                break;

            case "description":
                additionalData.push(
                    <div key={key}>Изменилось описание игры</div>
                );
                break;

            case "cost":
                additionalData.push(
                    <ChangedField
                        key={key}
                        fieldName={gameFields[key]}
                        oldValue={formatMoney(oldValue)}
                        newValue={formatMoney(newValue)}
                    />
                );
                break;

            case "ends_at":
                break;

            case "duration":
                additionalData.push(
                    <div key={key}>
                        Изменена длительность игры
                    </div>
                );
                break;

            default:
                additionalData.push(
                    <ChangedField
                        key={key}
                        fieldName={gameFields[key]}
                        oldValue={oldValue}
                        newValue={newValue}
                    />
                );
        }
    });

    return (
        <div className="notification__main">
            <div className="notification__additional">{additionalData}</div>
        </div>
    );
};

ChangedFields.propTypes = {
    notification: notificationsTypes,
    gameTypes: gameTypeTypes,
    levels: levelTypes,
};

const gameFields = {
    starts_at: "Начало игры",
    ends_at: "Окончание игры",
    max_players: "Число игроков",
    players_gender: "Пол игроков",
    playground: "Площадка",
    level_id: "Уровень игроков",
    type_id: "Тип игры",
    description: "Описание",
    is_public: "Публичность игры",
    private_code: "Публичность игры",
    duration: "Продолжительность игры",
    cost: "Стоимость",
};
export default ChangedFields;
