export default [
    "cities",
    "game_types",
    "game_levels",
    "playground_options",
    "playground_types",
    "countries",
    "metro_lines",
    "metro_stations",
];
