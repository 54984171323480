import React from "react";
import { Provider } from "react-redux";

import { readToken } from "domain/SiteAPI";
import Router from "./components/Router";
import store from "./store";
import DirectoriesLoader from "components/Loaders/Directories";
import directories from "domain/Directories/list";
import UserLoader from "components/Loaders/User";
import { ToastContainer } from "react-toastify";
import WebSocketManager from "components/WebSocket/WebSocketManager";
import WebSocketNotifications from "components/WebSocket/Notifications";
import StandAloneModals from "components/StandAloneModals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

class Main extends React.Component {
    componentDidMount() {
        readToken();
    }

    render() {
        return (
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <StandAloneModals />
                    <WebSocketManager>
                        {(client) => {
                            return <WebSocketNotifications client={client} />;
                        }}
                    </WebSocketManager>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        closeButton={false}
                        pauseOnHover
                    />

                    <DirectoriesLoader names={directories}>
                        <Router />
                    </DirectoriesLoader>

                    <UserLoader
                        renderContent={() => null}
                        renderLoadingIndicator={() => null}
                        renderErrorMessage={() => null}
                    />
                </QueryClientProvider>
            </Provider>
        );
    }
}

Main.propTypes = {};

export default Main;
