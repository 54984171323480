import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";
import {
    ERROR_SUFFIX,
    START_SUFFIX,
    SUCCESS_SUFFIX,
} from "domain/SiteAPI/reduxMiddleware";

export const NAMESPACE = `NOTIFICATIONS`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = GET + START_SUFFIX;
export const GET_REQUEST_SUCCESS = GET + SUCCESS_SUFFIX;
export const GET_REQUEST_ERROR = GET + ERROR_SUFFIX;

export const GET_CHANGE_OFFSET = `${GET}_CHANGE_OFFSET`;

export const CHECK = `${NAMESPACE}.CHECK`;
export const CHECK_REQUEST_START = CHECK + START_SUFFIX;
export const CHECK_REQUEST_SUCCESS = CHECK + SUCCESS_SUFFIX;
export const CHECK_REQUEST_ERROR = CHECK + ERROR_SUFFIX;

export const ADD = `${NAMESPACE}.ADD`;
export const SET_DEFAULT_STATE = `${NAMESPACE}.SET_DEFAULT_STATE`;

export const SET_VIEWED = `${NAMESPACE}.SET_VIEWED`;
export const SET_VIEWED_REQUEST_START = SET_VIEWED + START_SUFFIX;
export const SET_VIEWED_REQUEST_SUCCESS = SET_VIEWED + SUCCESS_SUFFIX;
export const SET_VIEWED_REQUEST_ERROR = SET_VIEWED + ERROR_SUFFIX;

export const setDefaultState = () => {
    return {
        type: SET_DEFAULT_STATE,
    };
};

export const get = ({ offset = 0, page_size = 10 }) => {
    return call({
        request: API.get.bind(null, { offset, page_size }),
        namespace: GET,
        meta: { offset, page_size },
    });
};

export const changeOffset = (offset) => {
    return {
        type: GET_CHANGE_OFFSET,
        payload: offset,
    };
};

export const check = () => {
    return call({
        request: API.check,
        namespace: CHECK,
    });
};

export const setViewed = (ids) => {
    return call({
        request: API.setViewed.bind(null, ids),
        namespace: SET_VIEWED,
        meta: { id: Array.isArray(ids) ? ids.join(",") : ids },
    });
};

export const add = (notification) => {
    return {
        type: ADD,
        payload: notification,
    };
};
