import request from "domain/SiteAPI";
import { GET } from "domain/SiteAPI/httpMethods";

export const search = async ({ query, city_id, page, page_size }) => {
    return await request({
        url: `playgrounds`,
        method: GET,
        parameters: { query, city_id, page, page_size },
    });
};
