import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class Alert extends React.Component {
    render() {
        if (!this.props.visible || !this.props.children) {
            return null;
        }

        const dismissable = !!this.props.onDismiss;

        return (
            <div
                className={classnames(
                    `alert`,
                    `alert--${this.props.type}`,
                    { "alert--dismissable": dismissable },
                    this.props.modificators.map(m => `alert--${m}`)
                )}
                style={this.props.style}
                role="alert"
            >
                {this.props.children}

                {dismissable && (
                    <button
                        type="button"
                        className="close"
                        aria-label="Закрыть"
                        onClick={this.props.onDismiss}
                    >
                        &nbsp;
                    </button>
                )}
            </div>
        );
    }
}

Alert.defaultProps = {
    visible: true,
    type: `danger`,
    children: null,
    onDismiss: null,
    modificators: [],
    style: {}
};

Alert.propTypes = {
    visible: PropTypes.bool,
    type: PropTypes.oneOf([`danger`, `warning`, `warning-accent`, `info`, `success`]).isRequired,
    children: PropTypes.node.isRequired,
    onDismiss: PropTypes.func,
    modificators: PropTypes.array,
    style: PropTypes.object
};

export default Alert;
