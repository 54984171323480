import React from "react";
import PropTypes from "prop-types";

class FallbackComponent extends React.Component {
    render() {
        return (
            <div className="alert alert-danger">
                Произошла ошибка
            </div>
        );
    }
}

FallbackComponent.propTypes = {
    error: PropTypes.instanceOf(Error),
};

class DefaultErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    static getDerivedStateFromError(error) {
        return {
            error,
        };
    }

    render() {
        if (this.state.error) {
            return this.props.renderFallback(this.state.error);
        }

        return this.props.children;
    }
}

DefaultErrorBoundary.propTypes = {
    renderFallback: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

const ErrorBoundary = ({children}) => {
    return (
        <DefaultErrorBoundary
            renderFallback={(error) => <FallbackComponent error={error}/>}
        >
            {children}
        </DefaultErrorBoundary>
    );
};

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
