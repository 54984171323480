import * as types from "./types";
import list from "./list";

export const KEY = `directories`;

export const initialState = {};

export const initialItemState = null;

export const getDirectoryState = (state, name) => state[name] || initialItemState;

const map = {};
list.forEach((item) => map[types[`${item.toUpperCase()}_REQUEST_SUCCESS`]] = item);

const reducer = (state = initialState, {type, payload}) => {
    if (!Object.prototype.hasOwnProperty.call(map, type)) {
        return state;
    }

    return {
        ...state,
        [map[type]]: payload,
    };
};

export default reducer;
