import { takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { ym } from "domain/Analytics/helpers";

function* onCreateGame(/*{ payload }*/) {
    yield ym("reachGoal", "game_created");
}

function* onCreateUser(/*{ payload }*/) {
    yield ym("reachGoal", "user_registered");
}

function* onPlayerJoin(/*{ payload: { userId, gameId } }*/) {
    yield ym("reachGoal", "game_player_joined");
}

function* onPlayerLeave(/*{ payload: { userId, gameId } }*/) {
    yield ym("reachGoal", "game_player_left");
}

function* goToStore(/*{ payload: { userId, gameId } }*/) {
    yield ym("reachGoal", "goToStore");
}

function* mySaga() {
    yield takeEvery(actions.TRACK_GAME_CREATED, onCreateGame);
    yield takeEvery(actions.TRACK_USER_CREATED, onCreateUser);
    yield takeEvery(actions.TRACK_PLAYER_JOIN, onPlayerJoin);
    yield takeEvery(actions.TRACK_PLAYER_LEFT, onPlayerLeave);
    yield takeEvery(actions.GO_TO_STORE, goToStore);
}

export default mySaga;
