import memoize from "lodash-es/memoize";

import { getDirectoryState, KEY } from "./reducer";
import { useSelector } from "react-redux";

export const getItems = (state) => state[KEY];

export const getItem = (state, id) => {
    return getDirectoryState(getItems(state), id);
};

export const getGameTypes = (state) => getItem(state, `game_types`) || [];

export const getGameTypeById = memoize(
    (state, id) => {
        const types = getGameTypes(state);
        return (types && types.find((type) => type.id == id)) || {};
    },
    (state, id) => id
);

export const getGameTypeByCode = (state, code) =>
    getGameTypes(state).find((type) => type.code === code);

export const isIeBrowser = () =>
    window.navigator.userAgent.indexOf("MSIE") > -1;

export const isEdgeBrowser = () =>
    window.navigator.userAgent.includes("Edg" || "Edge" || "EdgiOS" || "EdgA");

export const isSafariBrowser = () =>
    window.navigator.userAgent.indexOf("Safari") > -1;

export const isChromeBrowser = () =>
    window.navigator.userAgent.indexOf("Chrome") > -1;

export const getGameTypeImg = (state, type_id) => {
    if (
        isEdgeBrowser() ||
        isIeBrowser() ||
        (isSafariBrowser() && !isChromeBrowser())
    ) {
        return getGameTypeById(state, type_id).image_url;
    }
    return getGameTypeById(state, type_id).webp_image_url;
};

export const getHour = (hour) => {
    const str = String(hour);
    const strLength = str.length;

    if (str === "1" || str[strLength - 1] === "1") {
        return "час";
    }

    if (
        str === "2" ||
        str === "3" ||
        str === "4" ||
        (str[0] !== "1" && str[strLength - 1] === "2") ||
        (str[0] !== "1" && str[strLength - 1] === "3") ||
        (str[0] !== "1" && str[strLength - 1] === "4")
    ) {
        return "часа";
    }

    return "часов";
};

export const getLevels = (state) => getItem(state, `game_levels`) || [];

export const getLevelById = memoize(
    (state, id) => {
        const levels = getLevels(state);

        return (levels && levels.find((level) => level.id === id)) || {};
    },
    (state, id) => id
);

export const getCities = (state) => getItem(state, "cities") || [];

export const getActualCities = (state) =>
    getItem(state, `cities`)?.filter((city) => city?.is_actual) || [];

export const getOfflineCities = (state) =>
    getItem(state, `cities`)?.filter((city) => !city?.is_actual) || [];

export const getCityById = memoize(
    (state, id) => {
        const cities = getCities(state);

        return (cities && cities.find((city) => city.id === id)) || {};
    },
    (state, id) => id
);

export const getCityByCode = (state, code) =>
    getCities(state).find((city) => city.code === code);

export const getCountries = (state) => getItem(state, "countries") || [];

export const getCurrency = (state) => getCountry(state)?.currency;

export const getCountry = (state) => {
    const countryId = getCountryId(state);
    return getCountries(state)?.find((country) => country.id == countryId);
};

export const getCountryId = (state) => {
    const cityId = state?.user?.cityId;
    return getCities(state)?.find((city) => city.id == cityId)?.country_id;
};

export const getCodeCountry = (state) => getCountry(state)?.code;

export const getGenders = () => [
    { code: "F", name: "Женский" },
    { code: "M", name: "Мужской" },
    { code: "MF", name: "Мужчины и женщины" },
];

export const getFromGenders = () => [
    { code: "F", name: "Женщин" },
    { code: "M", name: "Мужчин" },
    { code: "MF", name: "Мужчин и женщин" },
];

export const getGenderByCode = (code) => {
    const genders = getGenders();
    const gender = genders.find((gender) => gender.code === code);
    if (!gender) {
        return "Не важно";
    }
    return gender.name;
};

export const getPlaygroundTypes = (state) =>
    getItem(state, `playground_types`) || [];

export const getLinesSubway = (state) => getItem(state, `metro_lines`) || [];

export const getLinesSubwayByCity = (state) =>
    getLinesSubway(state).filter((line) => line.city_id == state.user.cityId);

export const getStationsSubway = (state) =>
    getItem(state, `metro_stations`) || [];

export const getPlaygroundTypeById = memoize(
    (state, id) => {
        const playgroundTypes = getPlaygroundTypes(state);

        return (
            (playgroundTypes &&
                playgroundTypes.find((city) => city.id === id)) ||
            {}
        );
    },
    (state, id) => id
);

export const getPlaygroundOptions = (state) =>
    getItem(state, `playground_options`) || [];

export const getPlaygroundOptionById = memoize(
    (state, id) => {
        const playgroundOptions = getPlaygroundOptions(state);

        return (
            (playgroundOptions &&
                playgroundOptions.find((option) => option.id === id)) ||
            {}
        );
    },
    (state, id) => id
);

export const getPeriodicity = {
    monday: "По понедельникам",
    tuesday: "По вторникам",
    wednesday: "По средам",
    thursday: "По четвергам",
    friday: "По пятницам",
    saturday: "По субботам",
    sunday: "По воскресеньям",
};

export const getShortWeekDay = {
    1: "Пн",
    2: "Вт",
    3: "Ср",
    4: "Чт",
    5: "Пт",
    6: "Сб",
    7: "Вс",
};

export const getCurrencySelector = () =>
    useSelector((state) => getCountry(state)?.currency);

export const getGameTypesSelector = () =>
    useSelector((state) => getItem(state, `game_types`)) || [];
