import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import Select, { components } from "react-select";

const PaidSelect = ({
    id,
    is_online_pay,
    changeUnpaidPlayers,
    is_paid,
    needReport,
}) => {
    const [selectedOption, setSelectedOption] = useState({});
    const [isLongValue, setIsLongValue] = useState(false);
    const options = [
        { value: "true", label: "Оплачено" },
        { value: "false", label: "Не оплачено" },
    ];

    useEffect(() => {
        if (is_online_pay) {
            setSelectedOption({
                value: "true",
                label: "Оплачено онлайн",
            });
            setIsLongValue(true);
        } else {
            if (!needReport && !is_paid) {
                setSelectedOption({ value: "false", label: "Не оплачено" });
            } else {
                setSelectedOption({ value: "true", label: "Оплачено" });
            }
        }
    }, []);

    const styles = {
        input: (styles) => ({
            ...styles,
        }),
        menuList: () => ({}),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                display: "flex",
                flexDirection: "row",
                background: isSelected ? "#F7F7FC" : "white",
                color: "#14142B",
            };
        },
        control: (provided) => ({
            ...provided,
            background: "none",
            borderRadius: "4px",
            border: "none",
            minWidth: isLongValue ? "167px" : "120px",
            boxShadow: "none",
            minHeight: "unset",
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: "4px 0",
            justifyContent: "end",
        }),
        menu: (styles) => ({
            ...styles,
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            boxShadow: "0px 20px 24px rgba(17, 17, 17, 0.06)",
            width: "167px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        indicatorsContainer: (provided, { isDisabled }) => ({
            ...provided,
            color: isDisabled && "#9F9EB0",
            display: !needReport && "none",
        }),
        dropdownIndicator: () => ({}),
        clearIndicator: () => ({}),
        singleValue: (styles) => ({
            ...styles,
            maxWidth: "none",
        }),
    };

    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <Icon
                type="arrow_drop_down"
                inheritColor
                className="school__select-indicator"
            />
        </components.DropdownIndicator>
    );

    const handleOptionChange = (selected) => {
        setSelectedOption(selected);
        changeUnpaidPlayers(id, selected.value);
    };

    return (
        <Select
            key={`paid-player-${id}`}
            id={`paid-player-${id}`}
            options={options}
            className="select-paid"
            onChange={handleOptionChange}
            value={selectedOption}
            isSearchable={false}
            components={{ DropdownIndicator }}
            styles={styles}
            isDisabled={is_online_pay || !needReport}
        />
    );
};

PaidSelect.propTypes = {
    id: PropTypes.number,
    is_online_pay: PropTypes.bool,
    changeUnpaidPlayers: PropTypes.func,
    is_paid: PropTypes.bool,
    needReport: PropTypes.bool,
};

export default PaidSelect;
