import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";
import { forget } from "domain/HttpRequests/actions";

export const NAMESPACE = `BLACKLISTS`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${GET}_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${GET}_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${GET}_REQUEST_ERROR`;

export const GET_BY_ID = `${NAMESPACE}.GET_BY_ID`;
export const GET_BY_ID_REQUEST_START = `${GET_BY_ID}_REQUEST_START`;
export const GET_BY_ID_REQUEST_SUCCESS = `${GET_BY_ID}_REQUEST_SUCCESS`;
export const GET_BY_ID_REQUEST_ERROR = `${GET_BY_ID}_REQUEST_ERROR`;

export const SEARCH_CANDIDATES = `${NAMESPACE}.SEARCH`;
export const SEARCH_CANDIDATES_REQUEST_START = `${SEARCH_CANDIDATES}_REQUEST_START`;
export const SEARCH_CANDIDATES_REQUEST_SUCCESS = `${SEARCH_CANDIDATES}_REQUEST_SUCCESS`;
export const SEARCH_CANDIDATES_REQUEST_ERROR = `${SEARCH_CANDIDATES}_REQUEST_ERROR`;

export const CREATE = `${NAMESPACE}.CREATE`;
export const CREATE_REQUEST_START = `${CREATE}_REQUEST_START`;
export const CREATE_REQUEST_SUCCESS = `${CREATE}_REQUEST_SUCCESS`;
export const CREATE_REQUEST_ERROR = `${CREATE}_REQUEST_ERROR`;

export const UPDATE_GROUPS_STATE = `${NAMESPACE}.UPDATE_GROUPS_STATE`;
export const UPDATE_GROUP_DATA = `${NAMESPACE}.UPDATE_GROUP_DATA`;
export const SET_QUERY = `${NAMESPACE}.SET_QUERY`;
export const REMOVE = `${NAMESPACE}.REMOVE`;

let prevSearchParams = null;

export const searchCandidates = ({ query }) => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(SEARCH_CANDIDATES, prevSearchParams));
    }
    prevSearchParams = { query };

    dispatch(
        call({
            request: API.searchCandidates().bind(null, query),
            namespace: SEARCH_CANDIDATES,
            meta: { query },
        })
    );
};

export const get = () => {
    return call({
        request: API.get.bind(null),
        namespace: GET,
    });
};

export const getById = (id) => {
    return call({
        request: API.getById.bind(null, id),
        namespace: GET_BY_ID,
        meta: { id },
    });
};

export const recall = ({ id }) => (dispatch) => {
    dispatch(forget(GET, { id }));
    dispatch(get({ id }));
};

export const updateGroupsState = (groups = {}) => {
    return {
        type: UPDATE_GROUPS_STATE,
        payload: groups,
    };
};

export const updateGroupData = (groupId, group = {}) => {
    return {
        type: UPDATE_GROUP_DATA,
        payload: { groupId, group },
    };
};

export const setQuery = (query) => {
    return {
        type: SET_QUERY,
        payload: query,
    };
};
