import request from "domain/SiteAPI";
import { GET, POST } from "domain/SiteAPI/httpMethods";

export const get = async () => {
    return await request({
        url: `profile/show`,
        method: GET,
    });
};

export const update = async (fields) => {
    return await request({
        url: `profile/update`,
        body: fields._blob ? fields._blob() : fields,
        method: POST,
    });
};

export const updatePhone = async (phone, password, other) => {
    return await request({
        url: `profile/phone`,
        body: { phone, password, ...other },
        method: POST,
    });
};

export const deleteProfile = async () => {
    return await request({
        url: `profile/delete`,
        method: POST,
    });
};

export const requestPasswordChange = async (uuid, token) => {
    return await request({
        url: `profile/phone`,
        body: { uuid, token },
        method: POST,
    });
};

export const confirmations = async (uuid, token) => {
    return await request({
        url: `auth/login`,
        body: { uuid, token },
        method: POST,
    });
};

//запрос на вывод средств
export const withdrawAmount = async (fields) => {
    return await request({
        url: `profile/withdrawal_request`,
        body: fields._blob ? fields._blob() : fields,
        method: POST,
    });
};

//список транзакций
export const getTransactions = async () => {
    return await request({
        url: `profile/balance-history`,
        method: GET,
    });
};

//получить ссылку на редирект vk
export const getVkUrl = async (redirect_url) => {
    return await request({
        url: `vk`,
        method: GET,
        parameters: {
            redirect_url,
        },
    });
};

export const connectVK = async (code, redirect_url) => {
    return await request({
        url: `profile/add-vk`,
        body: { code, redirect_url },
        method: POST,
    });
};

export const disconnectVK = async () => {
    return await request({
        url: `profile/cancel-vk`,
        method: POST,
    });
};
