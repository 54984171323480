import { useSelector } from "react-redux";
import { KEY } from "./reducer";

export const getState = (state) => state[KEY];

export const isMobile = (state) => getState(state).isMobile;

export const isDesktop = (state) => getState(state).isDesktop;

export const isTablet = (state) => !isDesktop(state) && !isMobile(state);

export const getWidth = (state) => getState(state).width;

export const getMode = (state) => getState(state).mode;

export const isDesktopSelector = () => useSelector((state) => isDesktop(state));

export const isMobileSelector = () => useSelector((state) => isMobile(state));

export const isTabletSelector = () => useSelector((state) => isTablet(state));
