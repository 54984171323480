import * as types from "./actions";

import { setUserField } from "lib/bugsnag";
import { getCookie, getCurrentDomain, setCookie } from "utils/cookies";

export const KEY = `user`;
export const SAVED_CITY = "userCityId";
export const SELECTOR_SHOWED = "SelectorShowed";

if (!getCookie(SELECTOR_SHOWED)) {
    setCookie(SELECTOR_SHOWED, false, {
        domain: getCurrentDomain(),
        expires: "Tue, 19 Jan 2038 03:14:07 GMT",
    });
}

const initialCitySelectorShowed = getCookie(SELECTOR_SHOWED)
    ? getCookie(SELECTOR_SHOWED) !== "false"
    : true;

export const initialState = {
    shouldFillProfile: false,
    profile: {},
    initialPhone: null,
    //Есть опасность, что получим несуществующий id
    //Нужно для неавторизованного
    cityId: parseInt(getCookie(SAVED_CITY)) || window?.NAIGRU?.nearest_city_id,
    citySelectorShowed: initialCitySelectorShowed,
    organized_regular_games_count: 0,
    showPopover: false,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                ...state,
                showPopover: getCookie("showPopover") || false,
                organized_regular_games_count:
                    payload.organized_regular_games_count,
                profile: payload,
            };

        case types.GET_REQUEST_ERROR:
            return {
                ...initialState,
                cityId: state.cityId,
                citySelectorShowed: state.citySelectorShowed,
            };

        case types.SHOULD_FILL_PROFILE:
            return {
                ...state,
                shouldFillProfile: !!payload,
            };

        case types.UPDATE_INITIAL_PHONE:
            return {
                ...state,
                initialPhone: payload,
            };

        case types.UPDATE_COOKIE_CITY_ID:
            setCookie(SAVED_CITY, payload, {
                domain: getCurrentDomain(),
                expires: "Tue, 19 Jan 2038 03:14:07 GMT",
            });
            return {
                ...state,
                cityId: payload,
            };

        case types.UPDATE_COOKIE_POPOVER:
            return {
                ...state,
                showPopover: payload,
            };

        case types.UPDATE_REQUEST_SUCCESS:
        case types.REPLACE:
            setUserField(`id`, payload ? payload.id : null);

            return {
                ...state,
                profile: payload,
            };

        case types.SET_SHOWED_CITY_SELECTOR:
            setCookie(SELECTOR_SHOWED, payload, {
                domain: getCurrentDomain(),
                expires: "Tue, 19 Jan 2038 03:14:07 GMT",
            });
            return { ...state, citySelectorShowed: payload };

        case types.CHANGE_ORGANIZED_REGULAR_GAMES_COUNT:
            return {
                ...state,
                organized_regular_games_count: payload,
            };

        default:
            return state;
    }
};

export default reducer;
