import PropTypes from "prop-types";

//Не подставил в Icon, тк там иначе ломаются подсказки
export const iconTypes = PropTypes.oneOf([
    `alert`,
    `arrow_drop_down`,
    `arrow_drop_up`,
    `back`,
    `backspace`,
    `bell`,
    `bell_pin`,
    `burger`,
    `calendar`,
    `call`,
    `camera`,
    `chart`,
    `chain`,
    `close`,
    `configure`,
    `copy`,
    `delete`,
    `done`,
    `down`,
    `edit`,
    `export`,
    `forward`,
    `group`,
    `height`,
    `hide`,
    `import`,
    `info`,
    `key`,
    `loading`,
    `location`,
    `minus`,
    `plus`,
    `plus_round`,
    `search`,
    `settings`,
    `sign_in_square`,
    `sign_out_square`,
    `start`,
    `time`,
    `time_attack`,
    `up`,
    `user`,
    `user_add`,
    `view`,
    `wallet`,
    `mir`,
    `mouse-action`,
    "warning",
    `telegram`,
    `refresh`,
    `reward`,
    `requisite`,
    `ball`,
    `trainings`,
    `sportcourts`,
    `mail`,
    `currency`,
    `map`,
    `list-col`,
    `vk`
]);
