import { KEY } from "./reducer";

export const getState = (state) => state[KEY] || {};

export const getNotifications = (state) => getState(state).items || [];

export const getOffset = (state) => getState(state).offset || 0;

export const getPageSize = (state) => getState(state).page_size || 10;

export const getCount = (state) => getState(state).count;

export const getSize = (state) => getState(state).size;

export const getUnreadNotificationsCount = state => getState(state).unreadCount || 0
