import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";
import { forget } from "domain/HttpRequests/actions";

export const NAMESPACE = `GAME`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${GET}_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${GET}_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${GET}_REQUEST_ERROR`;

export const CREATE = `${NAMESPACE}.CREATE`;
export const CREATE_REQUEST_START = `${CREATE}_REQUEST_START`;
export const CREATE_REQUEST_SUCCESS = `${CREATE}_REQUEST_SUCCESS`;
export const CREATE_REQUEST_ERROR = `${CREATE}_REQUEST_ERROR`;

export const UPDATE_GAME_DATA = `${NAMESPACE}.UPDATE_GAME_DATA`;
export const DELETE_REGULAR_ID = `${NAMESPACE}.DELETE_REGULAR_ID`;

export const get = ({ id, code, entity }) => {
    return call({
        request: API.get.bind(null, {
            id,
            code,
            entity,
        }),
        namespace: GET,
        meta: { id, code },
    });
};

export const recall = ({ id, code }) => (dispatch) => {
    dispatch(forget(GET, { id, code }));
    dispatch(get({ id, code }));
};

export const create = (fields) => (dispatch) => {
    dispatch(
        call({
            request: API.create.bind(null, fields),
            namespace: GET,
            meta: { fields },
        })
    );
};

export const updateGameData = (id, data) => {
    return {
        type: UPDATE_GAME_DATA,
        payload: { id, data },
    };
};

export const deleteRegularId = (id) => {
    return {
        type: DELETE_REGULAR_ID,
        payload: id,
    };
};
