import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import UserLoader from "components/Loaders/User";
import { isLoggedIn } from "domain/Auth/selectors";
import Component from "./Component";

const ProtectedRoute = (props) => {
    const renderErrorMessage = () => <Component {...props} />;
    return (
        <UserLoader renderErrorMessage={renderErrorMessage}>
            <Component {...props} />
        </UserLoader>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: isLoggedIn(state),
    };
};

ProtectedRoute.propTypes = {
    children: PropTypes.node,
};

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
