import call from "domain/SiteAPI/reduxAction";
import API from "./api";
import * as types from "./types";
import list from "./list";

const actions = {};

list.forEach((item) => {
    actions[item] = () => (dispatch) => {
        const namespace = item.toUpperCase();
        if (
            window.NAIGRU &&
            window.NAIGRU.directories &&
            window.NAIGRU.directories[item]
        ) {
            if (window.NAIGRU.directories[item].success) {
                dispatch({
                    type: types[`${namespace}_REQUEST_SUCCESS`],
                    //todo: убрать костыль когда бэк будет готов
                    payload: window.NAIGRU.directories[item].items,
                });
            } else {
                //eslint-disable-next-line
                console.error(`Не удалось получить directories ${item}`);
                dispatch({
                    type: types[`${namespace}_REQUEST_SUCCESS`],
                    payload: [],
                });
            }
            delete window.NAIGRU.directories[item]; // При следующих запросах пойдём на сервер
        } else {
            dispatch(
                call({
                    request: API[item],
                    namespace: types[namespace],
                })
            );
        }
    };
});

export default actions;
