class CacheItem {
    constructor(value, ttl) {
        this.value = value;
        this.expires_at = Date.now() + ttl;
    }

    get is_expired() {
        return this.expires_at <= Date.now();
    }
}

class Cache {
    constructor() {
        this.items = new Map();
    }

    add(key, value, ttl = 60 * 60 * 1000) {
        this.items.set(key, new CacheItem(value, ttl));
    }

    remove(key) {
        this.items.delete(key);
    }

    has(key) {
        if (!this.items.has(key)) {
            return false;
        }

        if (this.items.get(key).is_expired) {
            this.remove(key);
            return false;
        }

        return true;
    }

    get(key) {
        if (this.has(key)) {
            return this.items.get(key).value;
        } else {
            return undefined;
        }
    }
}

export default Cache;
