import React from "react";
import PropTypes from "prop-types";
import EasySportImportModal from "./EasySportImport/";

const StandAloneModals = () => {
    return (
        <>
            <EasySportImportModal />
        </>
    );
};

StandAloneModals.propTypes = {
    importMessageCode: PropTypes.bool,
    userFullName: PropTypes.string,
    setShowMessage: PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
};

export default StandAloneModals;
