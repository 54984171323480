import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { switchVersion } from "domain/Version/actions";
import Component from "./Component";

const mapDispatchToProps = {
    switchVersion
};

export default withRouter(connect(null, mapDispatchToProps)(Component));
