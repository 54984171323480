import { withRouter } from "react-router-dom";
import Component from "./Component";
import connect from "react-redux/es/connect/connect";

import { isLoggedIn } from "domain/Auth/selectors";
import { logout } from "domain/Auth/actions";

const mapStateToProps = (state) => {
    return {
        isLoggedIn: isLoggedIn(state),
    };
};

const mapDispatchToProps = {
    logout,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Component)
);
