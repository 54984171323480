import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...props }) => {
    return (
        <Route
            {...props}
            render={(componentProps) =>
                props.isLoggedIn ? (
                    <Component {...componentProps} />
                ) : (
                    <Redirect to={`/`} />
                )
            }
        />
    );
};

ProtectedRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object,
    ]).isRequired,
    isLoggedIn: PropTypes.bool,
};

export default ProtectedRoute;
