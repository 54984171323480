import { KEY, SAVED_CITY } from "./reducer";
import { getCities, getCityById } from "domain/Directories/selectors";
import { getCookie } from "utils/cookies";
import { isLoggedIn } from "domain/Auth/selectors";
import { useSelector } from "react-redux";

export const getState = (state) => state[KEY];

export const getProfile = (state) => getState(state).profile || {};

export const getUserDate = (state) => {
    const { id, avatar, family_name, name } = getProfile(state);
    return { id, avatar, family_name, name };
};

export const getNeedFillProfile = (state) => {
    let needFillProfile = false;
    if (
        isLoggedIn(state) &&
        (getProfileName(state) === "" ||
            getProfileLastName(state) === "" ||
            !getProfileCityId(state))
    ) {
        needFillProfile = true;
    }
    return needFillProfile;
};

export const getUserId = (state) => getProfile(state).id;
export const getUserAge = (state) => getProfile(state)?.age;
export const getProfileName = (state) => getProfile(state).name || "";
export const getProfileLastName = (state) =>
    getProfile(state).family_name || "";
export const getProfileCityId = (state) => getProfile(state).city_id;

export const getInitialPhone = (state) => getState(state).initialPhone;

export const getUserPhone = () =>
    useSelector((state) => getProfile(state)?.phone);

export const getUserHasOnlinePay = (state) => getProfile(state)?.has_online_pay;

export const getCityId = (state) => getProfile(state).city_id;

export const getCityForUser = (state) =>
    getCityById(state, getCityIdForUser(state));

export const getCodeCityForUser = (state) => getCityForUser(state)?.code;

export const getNearestCityId = () => window.NAIGRU?.nearest_city_id;

export const getCityIdFromCookie = (state) => {
    const citiesCollection = getCities(state);
    let cityIdFromCookie = parseInt(getCookie(SAVED_CITY));

    //если города нет в словаре - считаем что его нет
    if (
        cityIdFromCookie &&
        citiesCollection.find(({ id }) => id === cityIdFromCookie) === undefined
    ) {
        cityIdFromCookie = undefined;
    }
    return cityIdFromCookie;
};

//cityId берется либо из куки либо из nearest_city_id - см редьюсер
//нужно тк обновление куки не влечет за собой  обновление компонентов
export const getCookieCityIdFromState = (state) => getState(state).cityId;

/* Вернет подходящий id города.
 * вернет город для юзера
 * Порядок приоритета такой кука, профиль, город определенный бэком
 * в постановке задач подоразумевается, что город всегда берется из куки
 * Тут финт ушами и город в куки может быть не указан, но логика сработает. смотри как брать город тут:
 * https://portal.itgro.ru/company/personal/user/3136/tasks/task/view/63438/
 */
export const getCityIdForUser = (state) =>
    getCityIdFromCookie(state) || getCityId(state) || getNearestCityId();

export const getUserCity = (state) =>
    getCityById(state, getCityIdForUser(state)) || {};

export const getShouldShowCitySelector = (state) =>
    getState(state).citySelectorShowed;

export const getOrganizedRegularGamesCount = (state) =>
    getState(state).organized_regular_games_count;

export const getShowPopover = (state) => getState(state).showPopover;

export const userCitySelector = () =>
    useSelector((state) => getUserCity(state));

export const getUserIdSelector = () =>
    useSelector((state) => getProfile(state).id);

export const getProfileSelector = () =>
    useSelector((state) => getState(state).profile) || {};

export const getOrganizationName = () =>
    useSelector((state) => getProfile(state)?.organization_name);

export const getAvailableSum = () =>
    useSelector((state) => getProfile(state)?.available_sum_amount);

export const getBalanceSum = () =>
    useSelector((state) => getProfile(state)?.balance_amount);
