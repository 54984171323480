export const DECREASE_ATTEMPTS = "DECREASE_CONFIRM_ATTEMPTS";

export const decreaseLoginAttempts = () => decreaseAttempts("login");

export const decreaseConfirmAttempts = () => decreaseAttempts("confirm");

export const decreaseChangePhone = () => decreaseAttempts("change_phone");

export const decreaseDeleteProfile = () => decreaseAttempts("delete_profile");

export const decreaseAttempts = (key = "") => ({
    type: DECREASE_ATTEMPTS,
    payload: { key },
});
