import * as types from "./actions";
import { cloneDeep, find } from "lodash-es";

export const KEY = `NOTIFICATIONS`;

export const initialState = {
    items: [],
    unreadCount: 0,
    count: null,
    offset: 0,
    size: 0,
};

export const reducer = (state = cloneDeep(initialState), { type, payload }) => {
    switch (type) {
        case types.SET_DEFAULT_STATE:
            return {
                ...cloneDeep(initialState),
                unreadCount: state.unreadCount,
            };

        case types.GET_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: [...cloneDeep(state.items), ...payload.items],
                count: payload.count,
                size: state.size + payload.items.length,
            };

        case types.GET_CHANGE_OFFSET:
            return {
                ...cloneDeep(state),
                offset: payload,
            };

        case types.CHECK_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                unreadCount: parseInt(payload.unread),
            };

        case types.ADD:
            if (find(state.items, { id: payload.id })) {
                return state;
            }

            return {
                ...cloneDeep(state),
                items: [payload, ...cloneDeep(state.items)],
                unreadCount: state.unreadCount + 1,
                size: state.size + 1,
            };

        case types.SET_VIEWED_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                unreadCount: state.unreadCount - (payload?.length || 0),
                items: cloneDeep(state.items).map((notification) =>
                    find(payload, { id: notification.id })
                        ? { ...notification, viewed_at: payload[0].viewed_at }
                        : notification
                ),
            };

        default:
            return state;
    }
};

export default reducer;
