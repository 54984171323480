import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import noop from "lodash-es/noop";

const Icon = ({
    visible,
    type,
    inheritColor,
    inheritSize,
    className,
    ...props
}) => {
    if (!visible) {
        return null;
    }

    if (type === `pdf`) {
        return (
            <svg
                fill="none"
                viewBox="0 0 15 20"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="m8.4375 7h5.1563l-5.1563-5.5v5.5zm-6.5625-7h7.5l5.625 6v12c0 0.5304-0.1975 1.0391-0.5492 1.4142-0.3516 0.3751-0.8285 0.5858-1.3258 0.5858h-11.25c-1.0406 0-1.875-0.9-1.875-2v-16c0-0.53043 0.19754-1.0391 0.54918-1.4142 0.35163-0.37507 0.82854-0.58579 1.3258-0.58579zm4.6219 10.44c0.38437 0.9 0.87187 1.64 1.4344 2.15l0.38437 0.32c-0.81562 0.16-1.9406 0.44-3.1312 0.93l-0.10312 0.04 0.46875-1.04c0.42188-0.87 0.73125-1.66 0.94688-2.4zm6.075 3.81c0.1687-0.18 0.2531-0.41 0.2625-0.66 0.0281-0.2-0.0188-0.39-0.1125-0.55-0.2719-0.47-0.975-0.69-2.1375-0.69l-1.2094 0.07-0.81562-0.58c-0.59063-0.52-1.125-1.43-1.5-2.56l0.0375-0.14c0.30938-1.33 0.60001-2.94-0.01874-3.6-0.15-0.16-0.35626-0.24-0.57188-0.24h-0.225c-0.34688 0-0.65625 0.39-0.74063 0.77-0.34687 1.33-0.14062 2.06 0.20626 3.27v0.01c-0.23438 0.88-0.53438 1.9-1.0125 2.93l-0.9 1.8-0.83438 0.49c-1.125 0.75-1.6594 1.59-1.7625 2.12-0.0375 0.19-0.01875 0.36 0.04687 0.54l0.02813 0.05 0.45 0.31 0.4125 0.11c0.75938 0 1.6219-0.95 2.7844-3.07l0.16876-0.07c0.96562-0.33 2.1656-0.56 3.7781-0.75 0.96562 0.51 2.1 0.74 2.8126 0.74 0.4124 0 0.6937-0.11 0.8531-0.3zm-0.3844-0.71l0.0844 0.11c-0.0094 0.1-0.0375 0.11-0.0844 0.13h-0.0375l-0.1781 0.02c-0.4313 0-1.0969-0.19-1.7813-0.51 0.0844-0.1 0.1219-0.1 0.2157-0.1 1.3125 0 1.6874 0.25 1.7812 0.35zm-8.5969 1.46c-0.60937 1.19-1.1625 1.85-1.5844 2 0.04687-0.38 0.46875-1.04 1.1344-1.69l0.44999-0.31zm2.8313-6.91c-0.21563-0.9-0.22501-1.63-0.06563-2.05l0.06563-0.12 0.14062 0.05c0.15938 0.24 0.17813 0.56 0.08438 1.1l-0.02813 0.16-0.15 0.82-0.04687 0.04z"
                    fill="#E03838"
                />
            </svg>
        );
    }

    if (type === `mouse-action`) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                {...props}
            >
                <path d="M40 41.007v17.986C40 64.522 44.476 69 50 69c5.523 0 10-4.48 10-10.007V41.007C60 35.478 55.524 31 50 31c-5.523 0-10 4.48-10 10.007zm-2 0C38 34.376 43.371 29 50 29c6.627 0 12 5.373 12 12.007v17.986C62 65.624 56.629 71 50 71c-6.627 0-12-5.373-12-12.007V41.007z" />
                <path d="M49 36v4a1 1 0 002 0v-4a1 1 0 00-2 0z" />
                <path
                    d="M50 81.993l5.5-5.5a1 1 0 011.414 1.414l-6.207 6.207a.997.997 0 01-1.414 0l-6.255-6.254a1 1 0 011.415-1.414L50 81.993z"
                    className="MouseScroll--chevron"
                />
            </svg>
        );
    }

    return (
        <svg
            className={classNames(
                {
                    icon: true,
                    "inherit-color": !!inheritColor,
                    "inherit-size": !!inheritSize,
                },
                className
            )}
            {...props}
        >
            <use
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xlinkHref={`#icon-${type}`}
            />
        </svg>
    );
};

Icon.defaultProps = {
    visible: true,
    inheritColor: false,
    inheritSize: false,
    onClick: noop,
};

Icon.propTypes = {
    visible: PropTypes.bool,
    inheritColor: PropTypes.bool,
    inheritSize: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    //Добавил тут добавь и в ./types
    type: PropTypes.oneOf([
        `alert`,
        `arrow_drop_down`,
        `arrow_drop_up`,
        `back`,
        `backspace`,
        `bell`,
        `bell_pin`,
        `burger`,
        `calendar`,
        `call`,
        `camera`,
        `chart`,
        `chain`,
        `close`,
        `configure`,
        `copy`,
        `delete`,
        `done`,
        `down`,
        `edit`,
        `export`,
        `forward`,
        `height`,
        `hide`,
        `import`,
        `info`,
        `key`,
        `loading`,
        `location`,
        `minus`,
        `plus`,
        `plus_round`,
        `search`,
        `settings`,
        `sign_in_square`,
        `sign_out_square`,
        `start`,
        `time`,
        `time_attack`,
        `up`,
        `user`,
        `user_add`,
        `view`,
        `wallet`,
        `mir`,
        `mouse-action`,
        `group`,
        "warning",
        `telegram`,
        `refresh`,
        `reward`,
        `requisite`,
        `ball`,
        `trainings`,
        `sportcourts`,
        `mail`,
        `currency`,
        `map`,
        `list-col`,
        `vk`
    ]).isRequired,
};

export default Icon;
