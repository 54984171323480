import call from "domain/SiteAPI/reduxAction";
import { removeToken, setToken } from "domain/SiteAPI";
import { get as getUser } from "domain/User/actions";
import * as API from "./api";

export const NAMESPACE = `auth`;

export const LOG_IN = `${NAMESPACE}.LOG_IN`;
export const LOG_OUT = `${NAMESPACE}.LOG_OUT`;

export const REFRESH = `${NAMESPACE}.REFRESH`;
export const REFRESH_REQUEST_START = `${REFRESH}_REQUEST_START`;
export const REFRESH_REQUEST_SUCCESS = `${REFRESH}_REQUEST_SUCCESS`;
export const REFRESH_REQUEST_ERROR = `${REFRESH}_REQUEST_ERROR`;

export const login = (response) => (dispatch) => {
    setToken(response);

    dispatch(getUser());
};

export const refresh = () =>
    call({
        request: API.refresh,
        namespace: REFRESH,
    });

export const logout = () => (dispatch) => {
    try {
        // noinspection JSIgnoredPromiseFromCall
        API.logout(); // Здесь может быть бесконечный цикл, но далее мы обновляем страницу, так что пофиг
    } catch (e) {
        // No op
    }

    removeToken();

    dispatch({
        type: LOG_OUT
    });

    window.location = `/`;
};
