import * as types from "./actions";
import { cloneDeep } from "lodash-es";

export const KEY = `easySportImport`;

const initialState = {
    userId: null,
    hash: null,
    needImport: false,
    importedData: null,
    showMessage: false,
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_IMPORTED_USER:
            return {
                ...cloneDeep(state),
                importedData: payload,
            };

        case types.SET_NEED_IMPORT:
            return {
                ...cloneDeep(state),
                needImport: payload,
            };

        case types.SET_IMPORT_CONFIG:
            return {
                ...cloneDeep(state),
                hash: payload.hash,
                userId: payload.easyId,
            };

        case types.SET_SHOW_MESSAGE:
            return {
                ...cloneDeep(state),
                showMessage: payload,
            };

        default:
            return state;
    }
};

export default reducer;
