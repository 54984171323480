import { getFormDataValue } from "lib/form";

import request from "domain/SiteAPI";
import { GET, POST } from "domain/SiteAPI/httpMethods";

export const get = async ({ id, code }) => {
    return await request({
        url: `games/${id}`,
        method: GET,
        parameters: {
            code,
        },
    });
};

export const create = async (fields, entity = "games") => {
    return await request({
        url: entity,
        method: POST,
        body: fields,
    });
};

export const update = async (fields) => {
    return await request({
        url: `games/${getFormDataValue(fields, "id") || fields.id}/update`,
        method: POST,
        body: fields,
    });
};

export const cancel = async (fields) => {
    return await request({
        url: `games/${getFormDataValue(fields, "id") || fields.id}/cancel`,
        method: POST,
        body: fields,
    });
};

export const subscribe = async (id, code, from_reserve) => {
    return await request({
        url: `games/${id}/join`,
        method: POST,
        body: { code, from_reserve },
    });
};

export const unsubscribe = async (id, code) => {
    return await request({
        url: `games/${id}/leave`,
        method: POST,
        body: { code },
    });
};

export const createOrders = async (game_id, return_url) => {
    return await request({
        url: `orders`,
        method: POST,
        body: { game_id, return_url },
    });
};

export const refundOrders = async (game_id, user_id) => {
    return await request({
        url: `orders/refund`,
        method: POST,
        body: { game_id, user_id },
    });
};

//только для организатора
export const add = async (gameId, userId, entity = "games") => {
    const isRegular = entity !== "games";
    return await request({
        url: `${entity}/${gameId}/players/${userId}/${
            isRegular ? "join" : "add"
        }`,
        method: POST,
        body: {},
    });
};

//только для организатора
export const exclude = async (
    gameId,
    userId,
    entity = "games",
    isInvate = true
) => {
    const isRegular = entity !== "games";

    return await request({
        url: `${entity}/${gameId}/players/${userId}/${
            isRegular && !isInvate ? "expel" : "exclude"
        }`,
        method: POST,
        body: {},
    });
};

//только для организатора
export const sendReport = async (gameId, missed_players, unpaid_players) => {
    return await request({
        url: `games/${gameId}/report`,
        method: POST,
        body: { missed_players, unpaid_players },
    });
};

//только для организатора
export const invitePlayer = async (
    gameId,
    userId,
    entity = "games",
    isInvate = true
) => {
    return await request({
        url: `${entity}/${gameId}/players/${userId}/${
            isInvate ? "invite" : "join"
        }`,
        method: POST,
        body: {},
    });
};

//только для организатора
export const inviteGroup = async (
    gameId,
    groupId,
    entity = "games",
    isInvate = true
) => {
    return await request({
        url: `${entity}/${gameId}/lists/${groupId}/${
            isInvate ? "invite" : "join"
        }`,
        method: POST,
        body: {},
    });
};

export const addGroup = async (gameId, groupId, entity = "games") => {
    return await request({
        url: `${entity}/${gameId}/lists/${groupId}/join`,
        method: POST,
        body: {},
    });
};

export const excludeGroup = async (
    gameId,
    groupId,
    entity = "games",
    isInvate
) => {
    return await request({
        url: `${entity}/${gameId}/lists/${groupId}/${
            isInvate ? "exclude" : "expel"
        }`,
        method: POST,
        body: {},
    });
};

//только для организатора, перевод из резерва в основной состав
export const transferPlayer = async (gameId, userId) => {
    return await request({
        url: `games/${gameId}/players/${userId}/transfer`,
        method: POST,
    });
};

//перевод из основного состава в резерв
export const transferToReserve = async (gameId, userId) => {
    return await request({
        url: `games/${gameId}/players/${userId}/reserve`,
        method: POST,
    });
};
