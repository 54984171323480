import React from "react";
import PropTypes from "prop-types";
import trim from "lodash-es/trim";
import cn from "classnames";

import AnimatedModal from "components/Modal/AnimatedModal";
import Button from "ui/Button";
import { ANIMATION_DURATION } from "components/Modal";

class ChooseCity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cityId: props.cityId,
            isChanged: false,
            cities: [],
            actualCities: [],
            isFullList: false,
        };
    }

    redirectIfNeed = (cityId) => {
        const { history, filters, cities } = this.props;
        const pathname = document.location.pathname;
        const path = trim(pathname, "/").split("/");

        const city = cities.find((city) => city.id === cityId);

        if (path[1] === "regular_games") {
            history.push(`/${city.code}/regular_games`);
        }

        if (path[1] === "games") {
            if (filters.alias) {
                history.push(`/${city.code}/games/${filters.alias}`);
                return;
            }
            history.push(`/${city.code}/games`);
        }

        if (path[1] === "schools") {
            history.push(`/${city.code}/schools`);
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (props.cityId !== state.cityId && !state.isChanged) {
            return { ...state, cityId: props.cityId };
        }
        return null;
    }

    onSave = async (callback, event, id) => {
        const {
            updateCityIdForUnAuth,
            setShowedCitySelector,
            changeFilter,
            updateCityId,
            //forgetSearch FIXME: при входе неавторизованым валится загрузка
        } = this.props;

        this.setState({
            cityId: id,
            isChanged: true,
        });

        callback();

        setTimeout(() => {
            setShowedCitySelector(true);
        }, ANIMATION_DURATION);

        updateCityIdForUnAuth(id);
        updateCityId(id);
        changeFilter({ city_id: id });
        //forgetSearch() FIXME: при входе неавторизованым валится загрузка
        this.redirectIfNeed(id);
    };

    hideChooseCity = () => {
        const {
            updateCityIdForUnAuth,
            cityId,
            setShowedCitySelector,
            actualCities,
        } = this.props;
        setShowedCitySelector(true);
        updateCityIdForUnAuth(cityId);
        this.setState({ actualCities: [...actualCities], isFullList: false });
    };

    componentDidMount() {
        this.getCitiesList();
    }

    getCitiesList = () => {
        const { actualCities, offlineCities } = this.props;
        const { cityId } = this.state;

        if (offlineCities?.find((elem) => elem.id == cityId)) {
            this.setState({
                actualCities: [...actualCities, ...offlineCities],
                isFullList: true,
            });
            return;
        }

        this.setState({
            actualCities: [...actualCities],
            isFullList: false,
        });
    };

    showFullList = () => {
        const { actualCities, offlineCities } = this.props;
        this.setState({
            actualCities: [...actualCities, ...offlineCities],
            isFullList: true,
        });
    };

    cityWord = () => {
        const cities = String(this.props?.offlineCities?.length);

        if (!cities) {
            return "";
        }

        if (cities[cities.length - 1] === "1" && cities !== "11") {
            return "город";
        }

        if (
            (cities[cities.length - 1] === "2" ||
                cities[cities.length - 1] === "3" ||
                cities[cities.length - 1] === "4") &&
            cities !== "12" &&
            cities !== "13" &&
            cities !== "14"
        ) {
            return "города";
        }

        return "городов";
    };

    render() {
        const {
            cityId,
            offlineCities,
            isMobile,
            cities,
            setShowedCitySelector,
            shouldShowCitySelector,
        } = this.props;

        const { isFullList, actualCities } = this.state;

        if (shouldShowCitySelector) {
            return null;
        }

        const showThreeColumn = !isMobile && actualCities.length > 7;
        const showTwoColumn = !isMobile && actualCities.length <= 7;
        const showMoreCityButtonText = `Еще ${
            offlineCities.length
        } ${this.cityWord()}`;

        return (
            <AnimatedModal
                onClose={this.hideChooseCity}
                closeOnOverlay
                title="Выберите город"
                modificators={[cities.length > 7 ? "city" : "small-city"]}
            >
                {(close) => (
                    <>
                        <p className="mb-40 text-center c-gs-medium-em">
                            От выбранного города будет зависеть подбор игр.
                        </p>

                        <div className="grid-row-32 w-100">
                            <div
                                className={cn(
                                    "grid-row-24 ml-20-tablet w-100",
                                    {
                                        "grid-3-col": showThreeColumn,
                                        "grid-2-col": showTwoColumn,
                                    }
                                )}
                            >
                                {actualCities.map(({ id, name }) => (
                                    <Button
                                        key={id}
                                        minimized
                                        size="no-border"
                                        mixes={[`city-selector__btn-text`]}
                                        onClick={(event) =>
                                            this.onSave(close, event, id)
                                        }
                                    >
                                        <span
                                            className={
                                                id === cityId &&
                                                "btn--minimized-active"
                                            }
                                        >
                                            {name}
                                        </span>
                                    </Button>
                                ))}
                            </div>

                            <div className="modal__btn_group">
                                {!isFullList && offlineCities.length > 0 ? (
                                    <Button
                                        onClick={this.showFullList}
                                        primary
                                        size="medium"
                                    >
                                        <span>{showMoreCityButtonText}</span>
                                    </Button>
                                ) : (
                                    <Button
                                        linkTo="/add_city"
                                        primary
                                        size="medium"
                                        onClick={() =>
                                            setShowedCitySelector(true)
                                        }
                                    >
                                        <span className="btn">
                                            Добавить ваш город
                                        </span>
                                    </Button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </AnimatedModal>
        );
    }
}

ChooseCity.defaultProps = {
    citiesCollection: [],
};

ChooseCity.propTypes = {
    isLoggedIn: PropTypes.bool,
    cityIdFromCookie: PropTypes.number,
    cityId: PropTypes.number,
    citiesCollection: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
    ),
    updateCityIdForUnAuth: PropTypes.func.isRequired,
    setShowedCitySelector: PropTypes.func.isRequired,
    shouldShowCitySelector: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    changeFilter: PropTypes.func.isRequired,
    forgetSearch: PropTypes.func.isRequired,
    updateCityId: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }),
    cities: PropTypes.array,
    filters: PropTypes.object.isRequired,
    offlineCities: PropTypes.array,
    isMobile: PropTypes.bool,
    actualCities: PropTypes.array,
};

export default ChooseCity;
