import { connect } from "react-redux";

import { GET, get } from "domain/User/actions";
import { getLoaderProps } from "domain/HttpRequests/selectors";
import GenericLoader from "./Generic";
import { removeToken } from "domain/SiteAPI";

const mapStateToProps = (state) => {
    return { ...getLoaderProps(state, GET), name: "UserLoader" };
};

const mapDispatchToProps = (dispatch) => {
    return {
        load: () => dispatch(get()),
    };
};

const UserLoader = connect(mapStateToProps, mapDispatchToProps)(GenericLoader);

UserLoader.propTypes = GenericLoader.propTypes;

UserLoader.defaultProps = {
    ...GenericLoader.defaultProps,
    renderErrorMessage: () => removeToken() || null,
};

export default UserLoader;
