class SiteAPI {
    constructor(message, payload) {
        this.message = message;
        this.data = payload;
    }
}

SiteAPI.prototype = Object.create(Error.prototype);
SiteAPI.prototype.constructor = SiteAPI;
SiteAPI.prototype.name = `SiteAPIError`;

export default SiteAPI;
