export const NAMESPACE = `COMPLEX`;

export const SET_ITEM = `${NAMESPACE}.SET_ITEM`;

export const setItem = (complex) => {
    return {
        type: SET_ITEM,
        payload: complex,
    };
};
