import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";
import { forget } from "domain/HttpRequests/actions";

export const NAMESPACE = `USERS`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${GET}_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${GET}_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${GET}_REQUEST_ERROR`;

let prevSearchParams = null;

export const get = ({ gameId, playerId, code=  ``, entity = "games" }) => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(GET, prevSearchParams));
    }
    prevSearchParams = { gameId, playerId };

    dispatch(
        call({
            request: API.get.bind(null, { gameId, playerId, code, entity }),
            namespace: GET,
            meta: { gameId, playerId },
        })
    );
};
