export const NAME_ITEMS = "SERVICES";

export const SET_SERVICE = `${NAME_ITEMS}.SET_SERVICE`;
export const SET_SERVICES = `${NAME_ITEMS}.SET_SERVICES`;
export const UPDATE_SERVICE = `${NAME_ITEMS}.UPDATE_SERVICE`;

export const setService = (serviceId, service) => {
    return {
        type: SET_SERVICE,
        payload: { serviceId, service },
    };
};

export const setServices = (services) => {
    return {
        type: SET_SERVICES,
        payload: services,
    };
};

export const updateService = (serviceId, updatedFields) => {
    return {
        type: UPDATE_SERVICE,
        payload: { serviceId, updatedFields },
    };
};
