import { KEY } from "./reducer";

export const getState = state => state[KEY];

export const getSEOMeta = (state, key) => {
    const seo = getState(state);

    if (!seo) {
        return null;
    }

    return seo[key] || {};
};

export const getSEOText = (state, key) => {
    const meta = getSEOMeta(state, key);

    if (!meta) {
        return null;
    }

    return meta.text;
};

export const getSEOTitleH1 = (state, key) => {
    const meta = getSEOMeta(state, key);

    if (!meta) {
        return null;
    }

    return meta.title_h1;
};
