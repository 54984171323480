import PropTypes from "prop-types";

export const avatarType = PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
});

export const profileType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    family_name: PropTypes.string,
    gender: PropTypes.string,
    birthday: PropTypes.string,
    height: PropTypes.number,
    city_id: PropTypes.number,
    email: PropTypes.string,
    phone: PropTypes.string,
    avatar: avatarType,
    visited_games_count: PropTypes.number,
    missed_games_count: PropTypes.number,
    organized_games_count: PropTypes.number,
});
