import * as types from "./actions";

export const KEY = `captcha`;

export const initialState = {
    attempts: window.NAIGRU?.recaptcha?.attempts || {},
    input_token_name: window.NAIGRU?.recaptcha?.input_token_name,
    site_key: window.NAIGRU?.recaptcha?.site_key,
    required: !!window.NAIGRU?.recaptcha?.site_key,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.DECREASE_ATTEMPTS:
            // eslint-disable-next-line no-case-declarations
            let value = state.attempts[payload.key];

            if (typeof value === "number" && value > 0) {
                value--;
            } else {
                value = 0;
            }

            return {
                ...state,
                attempts: { ...state.attempts, [payload.key]: value },
            };

        default:
            return state;
    }
};

export default reducer;
