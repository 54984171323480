import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Icon from "components/Icon";

const Auth = () => {
    return (
        <React.Fragment>
            <Link to="/signin" className="sidebar__link">
                <Icon type={`sign_in_square`} inheritColor />

                <span>Войти или создать аккаунт</span>
            </Link>
        </React.Fragment>
    );
};

Auth.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};

export default Auth;
