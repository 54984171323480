import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "components/ErrorBoundary";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import "formdata-polyfill";
import "blueimp-canvas-to-blob";
import 'focus-within-polyfill';
import declarePolyfills from "lib/polyfils";

import App from "./app";

declarePolyfills()

ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById(`app`)
);
