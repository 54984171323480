import * as types from "./actions";
import { cloneDeep } from "lodash-es";

export const KEY = `users`;

const initialState = { users: {} };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                users: { ...cloneDeep(state.items), [payload.id]: payload },
            };

        default:
            return state;
    }
};

export default reducer;
