import PropTypes from "prop-types";

export const notificationsTypes = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOf([
            "game_changed",
            "game_canceled",
            "player_excluded",
            "player_joined_to_main_team",
            "player_moved_to_main_team",
            "player_joined_to_reserve",
            "player_leaved_game_without_reserve",
            "player_leaved_game_with_reserve",
            "player_leaved_reserve",
            "withdrawal_request",
            "cancel_withdrawal_request",
            "approve_withdrawal_request"
        ]),
        title: PropTypes.string,
        description: PropTypes.string,
        payload: PropTypes.object,
        game_id: PropTypes.number,
        created_at: PropTypes.string,
        viewed_at: PropTypes.string,
    })
);
