import inDevelopmentMode from "lib/inDevelopmentMode";
import InMemoryCache from "lib/cache";
import parseResponse from "./parseResponse";
import makeCacheConfig from "./makeCacheConfig";
import makeOptions from "./makeOptions";
import { GET } from "./httpMethods";
import makeURL from "./makeURL";

const TOKEN_PERSIST_KEY = `tkn`;

let TOKEN = {};

export const setToken = (token) => {
    TOKEN = {
        value: token.access_token,
        valid_till: Date.now() + token.expires_in * 1000,
    };

    localStorage.setItem(TOKEN_PERSIST_KEY, JSON.stringify(TOKEN));
};

export const getToken = () => {
    return isValidToken(TOKEN) ? TOKEN.value : null;
};

export const readToken = () => {
    TOKEN = JSON.parse(localStorage.getItem(TOKEN_PERSIST_KEY));
};

export const removeToken = () => {
    TOKEN = {};

    localStorage.removeItem(TOKEN_PERSIST_KEY);
};

export const isValidToken = (token) => {
    // Здесь проверяется только наличие какой-то строки и то, что она не протухла.
    // Сервер вполне может сказать, что токен был отозван, например.

    return token && token.value && token.valid_till > Date.now();
};

const CACHE = new InMemoryCache();

const request = async ({
    url,
    method = GET,
    body = null,
    headers = {},
    cache: _cache = false,
    parameters = null,
    signal = null,
    isService = false,
}) => {
    url = isService ? url : `/api/${url}`

    if (parameters) {
        url = makeURL(url, parameters);
    }

    const cache = makeCacheConfig(url, _cache);

    if (cache.enabled && CACHE.has(cache.key)) {
        return CACHE.get(cache.key);
    }

    const options = makeOptions(TOKEN, headers, method, body, signal, isService);

    if (inDevelopmentMode) {
        console.log(`Site API request`, url, options); // eslint-disable-line no-console
    }

    const response = await fetch(url, options);

    const json = await parseResponse(response);

    if (isService) {
        return json
    }

    if (cache.enabled) {
        CACHE.add(cache.key, json.payload, cache.ttl);
    }

    return json.payload;
};

export default request;
