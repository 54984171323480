import * as types from "./actions";
import { cloneDeep, reduce } from "lodash-es";
import { GET_REQUEST_SUCCESS as SEARCH_GET_REQUEST_SUCCESS } from "domain/GamesSearch/actions";
import { DELETE_REGULAR_ID } from "./actions";

export const KEY = `games`;

const initialState = { items: {}, error: {} };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: { ...cloneDeep(state.items), [payload.id]: payload },
            };

        case types.GET_REQUEST_ERROR:
            return {
                ...cloneDeep(state),
                error: payload.data,
            };

        case types.UPDATE_GAME_DATA:
            return {
                ...cloneDeep(state),
                items: {
                    ...cloneDeep(state.items),
                    [payload.data.id]: payload.data,
                },
            };

        //Воруем данные из листинга, тк пока что они совпадают
        case SEARCH_GET_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: reduce(
                    { ...state.items, ...payload.items },
                    (accum, item) => ({ ...accum, [item.id]: cloneDeep(item) }),
                    {}
                ),
            };

        case DELETE_REGULAR_ID:
            return {
                ...cloneDeep(state),
                items: {
                    ...cloneDeep(state.items),
                    [payload.id]: {
                        ...state.items[payload.id],
                        regular_game_id: null,
                    },
                },
            };

        default:
            return state;
    }
};

export default reducer;
