import isPlainObject from "lodash/isPlainObject";

export default (path, config) => {
    const defaults = {
        enabled: false,
        ttl: undefined,
        key: path,
    };

    if (config === true) {
        return {
            ...defaults,
            enabled: true,
        };
    } else if (isPlainObject(config)) {
        return {
            ...defaults,
            ...config
        };
    } else if (config === false || config === undefined) {
        return defaults;
    } else {
        console.warn(`[SiteAPI]: cache option must be a boolean or a plain object; ${typeof config} given.`); // eslint-disable-line no-console
    }
}
