import React from "react";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "../Icon";

const IS_IOS = /iPhone/i.test(navigator.userAgent);
const root = document.querySelector("html");

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSidebarOpen: false,
        };

        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.showSidebar = this.showSidebar.bind(this);
        this.hideSidebar = this.hideSidebar.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);

        this.xTouchDown = null;
        this.yTouchDown = null;
    }

    get BODY_CLASS() {
        return `sidebar-open`;
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleOutsideClick);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({
                isSidebarOpen: false,
            });
            this.hideSidebar();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
    }

    handleTouchStart = (evt) => {
        const firstTouch = evt.touches[0];
        this.xTouchDown = firstTouch.clientX;
        this.yTouchDown = firstTouch.clientY;
    };

    handleTouchMove = (evt) => {
        if (!this.xTouchDown || !this.yTouchDown) {
            return;
        }

        const xTouchUp = evt.touches[0].clientX;
        const yTouchUp = evt.touches[0].clientY;

        const xDiff = this.xTouchDown - xTouchUp;
        const yDiff = this.yTouchDown - yTouchUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /* left swipe */
            if (xDiff > 0) {
                this.hideSidebar();
            }
        }
        this.xTouchDown = null;
        this.yTouchDown = null;
    };

    disableScroll = () => {
        window.addEventListener("touchstart", this.handleTouchStart, {
            passive: false,
        });
        window.addEventListener("touchmove", this.handleTouchMove, {
            passive: false,
        });
    };

    enableScroll = () => {
        window.removeEventListener("touchstart", this.handleTouchStart, {
            passive: false,
        });
        window.removeEventListener("touchmove", this.handleTouchMove, {
            passive: false,
        });
    };

    showSidebar = () => {
        document.addEventListener("mousedown", this.handler);
        document.body.classList.add(this.BODY_CLASS);
        root.classList.add(this.BODY_CLASS);

        this.setState(() => {
            return { isSidebarOpen: true };
        });
    };

    hideSidebar = () => {
        document.body.classList.remove(this.BODY_CLASS);
        root.classList.remove(this.BODY_CLASS);
        this.enableScroll();
        this.setState(() => {
            return { isSidebarOpen: false };
        });
    };

    toggleSidebar = () => {
        document.body.classList.toggle(this.BODY_CLASS);
        root.classList.toggle(this.BODY_CLASS);

        this.state.isSidebarOpen ? this.enableScroll() : this.disableScroll();

        this.setState(() => {
            return { isSidebarOpen: !this.state.isSidebarOpen };
        });
    };

    handleOutsideClick(e) {
        if (
            !e.target.closest(".burger") &&
            !e.target.closest(".sidebar") &&
            !e.target.closest(".header__logout") &&
            !e.target.closest(".modal")
        ) {
            this.hideSidebar();
        }
    }

    render() {
        const { isSidebarOpen } = this.state;
        const { logout, isLoggedIn } = this.props;

        return (
            <React.Fragment>
                <header
                    className={classNames("header", {
                        "header--fixed": IS_IOS,
                    })}
                >
                    <div
                        className={classNames("header__burger", {
                            active: isSidebarOpen,
                        })}
                    >
                        <button
                            type="button"
                            className="burger"
                            onClick={this.toggleSidebar}
                        >
                            <span className="burger__line" />
                        </button>
                    </div>

                    {isLoggedIn && (
                        <div className="header__logout logout hide-tablet-down">
                            <button
                                className="logout__link"
                                type="button"
                                onClick={logout}
                            >
                                <span>Выйти</span>
                                <Icon type={`sign_out_square`} inheritColor />
                            </button>
                        </div>
                    )}
                </header>

                <Sidebar isOpen={isSidebarOpen} />
            </React.Fragment>
        );
    }
}

Header.defaultProps = {
    location: "",
};

Header.propTypes = {
    location: PropTypes.object,
    logout: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
};

export default Header;
