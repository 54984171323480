import * as types from "./actions";
import { makeKey } from "domain/HttpRequests/reducer";

export const KEY = `seo`;

export const initialState = null;

const reducer = (state = initialState, { type, payload, meta = {} }) => {
    switch (type) {
        case types.SEO_REQUEST_SUCCESS:
            return {
                ...state,
                [makeKey(`seo`, meta)]: {
                    "og:site_name": `Naigru – Запись на спортивные игры`,
                    ...payload,
                },
            };
        case types.SEO_REQUEST_ERROR:
            return {
                ...state,
                [makeKey(`seo`, meta)]: null,
            };
        default:
            return state;
    }
};

export default reducer;
