import request from "domain/SiteAPI";
import { GET, POST, DELETE } from "domain/SiteAPI/httpMethods";
import { getFormDataValue } from "lib/form";

export const get = async () => {
    return await request({
        url: `blacklists`,
        method: GET,
    });
};

export const getById = async (id) => {
    return await request({
        url: `blacklists/${id}`,
        method: GET,
    });
};

export const searchCandidates = async ({
    query = "",
    page = 1,
    per_page = 20,
}) => {
    return await request({
        url: `blacklists/players/search`,
        method: GET,
        parameters: {
            query,
            per_page,
            page,
        },
    });
};

export const addMember = async (fields) => {
    return await request({
        url: `blacklists/${
            getFormDataValue(fields, "groupId") || fields.id
        }/players`,
        method: POST,
        body: fields,
    });
};

export const removeMember = async (groupId, userId) => {
    return await request({
        url: `blacklists/${groupId}/players/${userId}`,
        method: DELETE,
    });
};
