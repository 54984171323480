import * as types from "./actions";

export const KEY = `bookings`;

const initialState = { bookings: [] };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_ITEMS:
            return {
                ...state,
                bookings: payload,
            };

        case types.ADD_ITEM:
            return {
                ...state,
                bookings: [...state.bookings, payload],
            };

        case types.DELETE_ITEM:
            return {
                ...state,
                bookings: state.bookings.filter((item) => item.id !== payload),
            };
        case types.UPDATE_ITEM:
            return {
                ...state,
                bookings: state.bookings.map((book) => {
                    if (book.id === payload.id) {
                        return {
                            ...payload,
                        };
                    }
                    return book;
                }),
            };

        default:
            return state;
    }
};

export default reducer;
