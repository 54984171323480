import { connect } from "react-redux";

import { getLoaderProps } from "domain/HttpRequests/selectors";
import GenericLoader from "components/Loaders/Generic";
import { CHECK, check } from "domain/Notifications/actions";

const mapStateToProps = (state) => {

    const loaderProps = getLoaderProps(state, CHECK, check);

    return {
        ...loaderProps,
    };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
    ...stateProps,
    ...ownProps,
    load: () => dispatch(check()),
});

export default connect(mapStateToProps, null, mergeProps)(GenericLoader);
