const map = new Map();

export const set = (name, value) => {
    map.set(name, value);
};

export const get = (name) => {
    if (!map.has(name)) {
        return Infinity;
    }

    return map.get(name);
};
