import React from "react";
import PropTypes from "prop-types";

import Modal from "components/Modal";
import { defaults } from "lodash-es";

const DEFAULT_SETTINGS = {
    message:
        "Вы пытаетесь перейти по ссылке, при этом некоторые данные могут быть потеряны. Продолжить",
    title: "Попытка перехода",
    ok: "Да",
    cancel: "Отмена",
};

const CustomPrompt = ({ message, cleanUp }) => {
    const cancel = () => cleanUp(false);
    const ok = () => cleanUp(true);

    let settings;

    try {
        settings = JSON.parse(message);
    } catch (e) {
        settings = { message };
    }

    settings = defaults(settings, DEFAULT_SETTINGS);

    return (
        <Modal
            title={settings.title}
            closeOnOverlay
            onClose={cancel}
            modificators={[`small-col`]}
        >

            <p className="fs-body1 mb-24 c-gs-medium-em text-center">
                {settings.message}
            </p>

            <div className="btn-group-row">
                <button type="button" className="btn btn--medium btn--text" onClick={cancel}>
                    {settings.cancel}
                </button>

                <button type="button" className="btn btn--medium btn--primary" onClick={ok}>
                    {settings.ok}
                </button>
            </div>
        </Modal>
    );
};

CustomPrompt.propTypes = {
    message: PropTypes.string.isRequired,
    cleanUp: PropTypes.func.isRequired,
};

export default CustomPrompt;
