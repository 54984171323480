export const NAME_ITEM = "SPORTCOURT";
export const NAME_ITEMS = "SPORTCOURTS";

export const ADD_ITEM = `${NAME_ITEMS}.ADD_ITEM`;
export const SET_ITEMS = `${NAME_ITEMS}.SET_ITEMS`;
export const UPDATE_ITEM = `${NAME_ITEMS}.UPDATE_ITEM`;
export const UPDATE_ITEM_FIELDS = `${NAME_ITEMS}.UPDATE_ITEM_FIELDS`;
export const ADD_INTERVAL = `${NAME_ITEMS}.ADD_INTERVAL`;
export const UPDATE_INTERVAL = `${NAME_ITEMS}.UPDATE_INTERVAL`;
export const DELETE_INTERVAL = `${NAME_ITEMS}.DELETE_INTERVAL`;
export const SET_INTERVALS = `${NAME_ITEMS}.SET_INTERVALS`;
export const SET_SPORTCOURT = `${NAME_ITEM}.SET_SPORTCOURT`;
export const ITEM_ADD_INTERVAL = `${NAME_ITEM}.ITEM_ADD_INTERVAL`;
export const ITEM_UPDATE_INTERVAL = `${NAME_ITEM}.ITEM_UPDATE_INTERVAL`;
export const ITEM_DELETE_INTERVAL = `${NAME_ITEM}.ITEM_DELETE_INTERVAL`;
export const UPDATE_SPORTCOURT = `${NAME_ITEM}.UPDATE_SPORTCOURT`;
export const ITEM_SET_INTERVAL = `${NAME_ITEM}.ITEM_SET_INTERVAL `;

export const addItem = (sportcourt) => {
    return {
        type: ADD_ITEM,
        payload: sportcourt,
    };
};

export const setItems = (sportcourts) => {
    return {
        type: SET_ITEMS,
        payload: sportcourts,
    };
};

export const updateItem = (sportcourtId) => {
    return {
        type: UPDATE_ITEM,
        payload: sportcourtId,
    };
};

export const updateItemFields = (sportcourtId , updatedFields) => {
    return {
        type: UPDATE_ITEM_FIELDS,
        payload: {sportcourtId, updatedFields},
    };
};

export const addInterval = (sportcourtId, interval) => {
    return {
        type: ADD_INTERVAL,
        payload: { sportcourtId, interval },
    };
};

export const updateInterval = (sportcourtId, intervalId, updatedFields) => {
    return {
        type: UPDATE_INTERVAL,
        payload: { sportcourtId, intervalId, updatedFields },
    };
};

export const removeInterval = (sportcourtId, intervalId) => {
    return {
        type: DELETE_INTERVAL,
        payload: { sportcourtId, intervalId },
    };
};

export const setIntervals = (sportcourtId, intervals) => {
    return {
        type: SET_INTERVALS,
        payload: { sportcourtId, intervals },
    };
};

export const setSportcourt = (sportcourt) => {
    return {
        type: SET_SPORTCOURT,
        payload: sportcourt,
    };
};

export const updateSportcourt = (updatedFields) => {
    return {
        type: UPDATE_SPORTCOURT,
        payload: updatedFields,
    };
};

export const addSportcourtInterval = (interval) => {
    return {
        type: ITEM_ADD_INTERVAL,
        payload: interval,
    };
};

export const updateSportcourtInterval = (intervalId, updatedFields) => {
    return {
        type: ITEM_UPDATE_INTERVAL,
        payload: { intervalId, updatedFields },
    };
};

export const removeSportcourtInterval = (intervalId) => {
    return {
        type: ITEM_DELETE_INTERVAL,
        payload: intervalId,
    };
};

export const setSportcourtInterval = (intervals) => {
    return {
        type: ITEM_SET_INTERVAL,
        payload: intervals,
    };
};
