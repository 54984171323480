import { useState, useEffect } from "react";
import Echo from "laravel-echo";
import io from "socket.io-client";

import { getToken, readToken } from "domain/SiteAPI";

const HOST = window.NAIGRU?.laravel_echo?.host;
const TRANSPORT = window.NAIGRU?.laravel_echo?.transport;

//Сокет очень простой и не рассчитан на большое кол-во каналов. Если нужно что-то большее, то надо это переписывать
const WebSocketManager = ({ children }) => {
    const [client, setClient] = useState(null);
    readToken();
    const token = getToken();

    useEffect(() => {
        connect();
    }, []);

    const connect = () => {
        if (!HOST || !TRANSPORT) {
            return null;
        }

        const client = new Echo({
            broadcaster: `socket.io`,
            host: HOST,
            transports: [TRANSPORT],
            client: io,
            auth: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });

        setClient(client);
    };

    if (!client) {
        return null;
    }

    return children(client);
};

export default WebSocketManager;
