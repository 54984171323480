export default (function controlFocusModal() {
    let nonModalNodes = null;

    function fix() {
        let modalNodes = Array.from(document.querySelectorAll("dialog *"));

        nonModalNodes = document.querySelectorAll(`input:not(dialog):not([tabindex="-1"]):not(.form-input__input--modal), 
                                                            textarea:not(dialog):not([tabindex="-1"]), 
                                                            select:not(dialog):not([tabindex="-1"])`);

        for (let i = 0; i < nonModalNodes.length; i++) {
            let node = nonModalNodes[i];

            if (!modalNodes.includes(node)) {
                node._prevTabindex = node.getAttribute("tabindex");
                node.setAttribute("tabindex", -1);
                node.style.outline = "none";
            }
        }
    }

    function unfix() {
        document.body.style.overflow = null;
        for (let i = 0; i < nonModalNodes.length; i++) {
            let node = nonModalNodes[i];
            if (node._prevTabindex) {
                node.setAttribute("tabindex", node._prevTabindex);
                node._prevTabindex = null;
            } else {
                node.removeAttribute("tabindex");
            }
            node.style.outline = null;
        }
    }

    return {
        fix,
        unfix
    };
})();
