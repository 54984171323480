import * as types from "./actions";
import { cloneDeep } from "lodash-es";

export const KEY = `blacklists`;

const initialState = { query: "", items: {} };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:

            return {
                ...cloneDeep(state),
                items:
                    payload?.reduce(
                        (accum, item) => ({ ...accum, [item.id]: item }),
                        {}
                    ) || {},
            };

        case types.GET_BY_ID_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: {
                    ...cloneDeep(state.items),
                    [payload.id]: payload,
                },
            };

        case types.SEARCH_CANDIDATES_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: {
                    ...cloneDeep(state.items),
                    ...payload.reduce(
                        (accum, group) => ({ ...accum, [group.id]: group }),
                        {}
                    ),
                },
            };

        case types.UPDATE_GROUPS_STATE:
            return {
                ...cloneDeep(state),
                items: { ...cloneDeep(state.items), ...payload },
            };

        case types.UPDATE_GROUP_DATA:
            return {
                ...cloneDeep(state),
                items: {
                    ...cloneDeep(state.items),
                    [payload.groupId]: payload.group,
                },
            };

        case types.SET_QUERY:
            return {
                ...cloneDeep(state),
                query: payload,
            };

        default:
            return state;
    }
};

export default reducer;
