import React from "react";
import PropTypes from "prop-types";

import Modal from "components/Modal";
import Button from "ui/Button";

const NotFound = ({ handleCLose }) => {
    return (
        <Modal
            visible={true}
            onClose={close}
            closeOnOverlay={false}
            bodyModificators={[`app-hide`]}
            modificators={[`tablet-page`, `sign`, `keyboard`, `registration`]}
        >
            <div className="reg reg--not-found">
                <div className="reg__top">
                    <h1 className="fs-h1 reg__title">404 Not found</h1>
                </div>

                <div className="reg__body">
                    <div className="reg__main">
                        <p className="c-gs-medium-em mb-32">
                            В адресе есть ошибка или страница удалена.
                        </p>

                        <div className="btn-group btn-group--bottom">
                            <Button
                                className="btn btn--medium"
                                primary
                                onClick={handleCLose}
                            >
                                Перейти к играм
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

NotFound.propTypes = {
    handleCLose: PropTypes.func.isRequired,
};

export default NotFound;
