import { get as getTtlInSeconds } from "domain/SiteAPI/ttl";
import { getItemState, KEY, makeKey } from "./reducer";
import { startsWith } from "lodash-es";

export const getItems = state => state[KEY];

export const getItem = (state, name, meta = {}) => getItemState(getItems(state), name, meta);

const pendingRequests = new Set();

export const getLoaderProps = (state, name, meta) => {
    const request = getItem(state, name, meta);

    const ttl = getTtlInSeconds(name) * 1000;

    let isLoading = request.isLoading;
    let shouldLoad =
        (!request.isLoaded || Date.now() > (request.loadedAt || 0) + ttl) && !isLoading;

    const key = makeKey(name, meta);

    if (shouldLoad) {
        if (!pendingRequests.has(key)) {
            pendingRequests.add(key);
        } else {
            shouldLoad = false;
            isLoading = true;
        }
    }

    if (request.isLoaded) {
        pendingRequests.delete(key);
    }

    return {
        ...request,
        isLoading,
        shouldLoad,
        shouldShowLoadingIndicator: !request.isLoaded || request.isLoading,
        shouldShowErrorMessage: !!request.errorMessage
    };
};

export const hasActiveBasketRequests = state => {
    const items = getItems(state);
    return (
        Object.keys(items)
            .filter(
                key => startsWith(key, "basket.ADD_ITEM") || startsWith(key, "basket.UPDATE_ITEM")
            )
            .filter(key => items[key].isLoading).length > 0
    );
};
