import { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getCityIdForUser } from "domain/User/selectors";
import { constructOptions } from "lib/seoOptionsConstructor";
import { makeFakeSeo } from "domain/SEOmeta/actions";
import { withRouter } from "react-router-dom";

const SetSeoMeta = ({ makeFakeSeo, cityId, options, type, value, history }) => {
    useEffect(() => {
        makeFakeSeo(type, value, cityId, options);
    }, [options.title, history.location.pathname]);
    return null;
};

SetSeoMeta.propTypes = {
    makeFakeSeo: PropTypes.func,
    cityId: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};

const MapStateToProps = (state) => {
    const options = constructOptions(state);
    return {
        cityId: getCityIdForUser(state),
        options,
    };
};

const mapDispatchToProps = {
    makeFakeSeo,
};

export default withRouter(
    connect(MapStateToProps, mapDispatchToProps)(SetSeoMeta)
);
