import { pickBy, identity } from "lodash-es";

import request from "domain/SiteAPI";
import { POST, GET } from "domain/SiteAPI/httpMethods";

//{ password, password_confirmation, old_password, uuid, token }
export const setPassword = async (options) => {
    return await request({
        url: `profile/password`,
        body: { ...pickBy(options, identity) },
        method: POST,
    });
};

export const checkPhoneRegistration = async (phone) => {
    return await request({
        url: `auth/phone/${phone}/check`,
        method: GET,
    });
};

export const resetPass = async (phone, other = {}) => {
    return await request({
        url: `auth/reset_password`,
        body: { phone, ...pickBy(other, identity) },
        method: POST,
    });
};

export const requestRegistration = async (phone, other = {}) => {
    return await request({
        url: `auth/register`,
        body: { phone, ...pickBy(other, identity) },
        method: POST,
    });
};

export const requestConfirmation = async (uuid, token, options = {}) => {
    return await request({
        url: `confirmations/execute`,
        body: { uuid, token: parseInt(token), options },
        method: POST,
    });
};

export const resendConfirmation = async (uuid) => {
    return await request({
        url: `auth/resend_confirmation_code`,
        body: { uuid },
        method: POST,
    });
};

export const refresh = async () => {
    return await request({
        url: `auth/refresh`,
        method: POST,
    });
};

export const login = async (phone, password, other = {}) => {
    return await request({
        url: `auth/login`,
        body: { phone, password, ...pickBy(other, identity) },
        method: POST,
    });
};

export const logout = async () => {
    return await request({
        url: `auth/logout`,
        method: POST,
    });
};

export const loginFromAdmin = async (token) => {
    return await request({
        url: `auth/login-from-admin`,
        body: { token },
        method: POST,
    });
};
