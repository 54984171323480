import request from "domain/SiteAPI";
import { DELETE, GET, PUT } from "domain/SiteAPI/httpMethods";
import { getFormDataValue } from "lib/form";

export const get = async ({ page, city_id, page_size }) => {
    return await request({
        url: `regular_games`,
        method: GET,
        parameters: {
            page,
            page_size,
            city_id,
        },
    });
};

export const getItem = async ({ id, code }) => {
    return await request({
        url: `regular_games/${id}`,
        method: GET,
        parameters: {
            code,
        },
    });
};

export const updateRegularGame = async (fields) => {
    return await request({
        url: `regular_games/${getFormDataValue(fields, "id") || fields.id}`,
        method: PUT,
        body: fields,
    });
};

export const cancel = async ({ id }) => {
    return await request({
        url: `regular_games/${id}`,
        method: DELETE,
    });
};
