import { connect } from "react-redux";

import Component from "./Component";
import { getNeedFillProfile } from "domain/User/selectors";
import {getWidth} from "domain/Version/selectors";

const mapStateToProps = (state) => {
    return {
        needFillProfile: getNeedFillProfile(state),
        minWidth: getWidth(state),
    };
};

export default connect(mapStateToProps)(Component);
