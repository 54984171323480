import { uniqueId } from "lodash";
import * as types from "./actions";

export const KEY = `sportcourts`;

export const initialState = {
    sportcourts: [
        {
            id: uniqueId(),
            type_id: 1,
            intervals_grouped: [
                {
                    id: uniqueId(),
                    week_days: [],
                    time_from: "",
                    time_to: "",
                    price: "",
                },
            ],
        },
    ],
    sportcourt: {
        id: 0,
        type_id: 1,
        intervals_grouped: [
            {
                id: uniqueId(),
                week_days: [],
                time_from: "",
                time_to: "",
                price: "",
            },
        ],
        is_new: true,
    },
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.ADD_ITEM:
            return {
                ...state,
                sportcourts: [...state.sportcourts, payload],
            };
        case types.UPDATE_ITEM:
            return {
                ...state,
                sportcourts: state.sportcourts.map((court) => {
                    if (court.id === payload) {
                        return {
                            ...state.sportcourt,
                        };
                    }
                    return court;
                }),
            };
        case types.UPDATE_ITEM_FIELDS:
            return {
                ...state,
                sportcourts: state.sportcourts.map((court) => {
                    if (court.id === payload.sportcourtId) {
                        return {
                            ...court,
                            ...payload.updatedFields,
                        };
                    }
                    return court;
                }),
            };

        case types.SET_ITEMS:
            return {
                ...state,
                sportcourts: payload,
            };
        case types.ADD_INTERVAL:
            return {
                ...state,
                sportcourts: state.sportcourts.map((court) => {
                    if (court.id === payload.sportcourtId) {
                        return {
                            ...court,
                            intervals_grouped: [
                                ...court.intervals_grouped,
                                payload.interval,
                            ],
                        };
                    }
                    return court;
                }),
            };
        case types.UPDATE_INTERVAL:
            return {
                ...state,
                sportcourts: state.sportcourts.map((court) => {
                    if (court.id === payload.sportcourtId) {
                        const updatedIntervals = court.intervals_grouped.map(
                            (interval) => {
                                if (interval.id === payload.intervalId) {
                                    return {
                                        ...interval,
                                        ...payload.updatedFields,
                                    };
                                }
                                return interval;
                            }
                        );

                        return {
                            ...court,
                            intervals_grouped: updatedIntervals,
                        };
                    }
                    return court;
                }),
            };
        case types.DELETE_INTERVAL:
            return {
                ...state,
                sportcourts: state.sportcourts.map((court) => {
                    if (court.id === payload.sportcourtId) {
                        const updatedIntervals = court.intervals_grouped.filter(
                            (interval) => interval.id !== payload.intervalId
                        );

                        return {
                            ...court,
                            intervals_grouped: updatedIntervals,
                        };
                    }
                    return court;
                }),
            };
        case types.SET_INTERVALS:
            return {
                ...state,
                sportcourts: state.sportcourts.map((court) => {
                    if (court.id === payload.sportcourtId) {
                        return {
                            ...court,
                            intervals_grouped: payload.intervals,
                        };
                    }
                    return court;
                }),
            };
        case types.SET_SPORTCOURT:
            return {
                ...state,
                sportcourt: payload,
            };
        case types.UPDATE_SPORTCOURT:
            return {
                ...state,
                sportcourt: { ...state.sportcourt, ...payload },
            };
        case types.ITEM_ADD_INTERVAL:
            return {
                ...state,
                sportcourt: {
                    ...state.sportcourt,
                    intervals_grouped: [
                        ...state.sportcourt.intervals_grouped,
                        payload,
                    ],
                },
            };
        case types.ITEM_DELETE_INTERVAL:
            return {
                ...state,
                sportcourt: {
                    ...state.sportcourt,
                    intervals_grouped: state.sportcourt.intervals_grouped.filter(
                        (interval) => interval.id !== payload
                    ),
                },
            };
        case types.ITEM_UPDATE_INTERVAL:
            return {
                ...state,
                sportcourt: {
                    ...state.sportcourt,
                    intervals_grouped: state.sportcourt.intervals_grouped.map(
                        (interval) => {
                            if (interval.id === payload.intervalId) {
                                return {
                                    ...interval,
                                    ...payload.updatedFields,
                                };
                            }
                            return interval;
                        }
                    ),
                },
            };
        case types.ITEM_SET_INTERVAL:
            return {
                ...state,
                sportcourt: {
                    ...state.sportcourt,
                    intervals_grouped: payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;
