import React from "react";
import PropTypes from "prop-types";

import { notificationsTypes } from "domain/Notifications/types";
import { DateTime as Luxon } from "luxon";
import { Link } from "react-router-dom";
import ChangedFields from "components/NotificationsItem/ChangedFields";
import { noop } from "lodash-es";
import classNames from "classnames";

const NotificationsItem = ({
    notification,
    gameType,
    gameLink,
    withLink,
    short,
    setViewed,
    isInsideMenu,
}) => {
    const {
        title,
        game,
        created_at,
        viewed_at,
        description,
        sport_complex,
    } = notification;
    const organizer = `${game?.organizer.family_name} ${game?.organizer.name}`;
    const date = Luxon.fromSQL(game?.starts_at).toFormat("dd.LL");
    const time = Luxon.fromSQL(game?.starts_at).toFormat("H:mm");
    const hasOwnDescription = description !== null;
    const playgroundName = game?.playground.name;
    const sportComplexLink = sport_complex
        ? `/${sport_complex?.city?.code}/sportcourt/${sport_complex?.id}`
        : null;

    let commonDescription = `${gameType?.name} ${date} в ${time}, ${playgroundName}, ${organizer}`;

    const isCanceled = Boolean(game?.cancel_reason || null);

    const unavailable =
        notification.type === "game_canceled" ||
        isCanceled ||
        (!notification.game?.is_public &&
            !notification.game?.is_participate &&
            !notification.game?.private_code);

    const isGameLink = withLink && gameLink && !unavailable;

    const content = (
        <div className="notification__content">
            <div className="notification__header flex aic jsb">
                <h4 className="notification__title">{title}</h4>
                {!short && (
                    <span className="notification__time">
                        {Luxon.fromSQL(created_at).toFormat("H:mm dd.LL.yyyy")}
                    </span>
                )}
            </div>

            <ChangedFields notification={notification} />

            {notification.game ? (
                <div className="notification__footer">
                    {hasOwnDescription && (
                        <div className="mb-8">{description}</div>
                    )}
                    {!short && commonDescription}
                </div>
            ) : (
                isInsideMenu &&
                description && (
                    <div className="notification__footer">
                        <div className="mb-8">{description}</div>
                    </div>
                )
            )}
        </div>
    );

    const handleClick = (event) => {
        if (unavailable && !viewed_at) {
            event.preventDefault();
            setViewed([notification.id]);
            return false;
        }
    };

    return (
        <>
            {isGameLink || sportComplexLink ? (
                <Link
                    to={
                        isGameLink
                            ? gameLink
                            : sportComplexLink && sportComplexLink
                    }
                    className={classNames(`notification`, {
                        new: !viewed_at,
                    })}
                >
                    {content}
                </Link>
            ) : (
                <div
                    onClick={withLink ? handleClick : noop}
                    className={classNames(`notification`, {
                        "notification--not-menu": !isInsideMenu,
                        new: !viewed_at && isInsideMenu,
                    })}
                >
                    {content}
                </div>
            )}
        </>
    );
};

NotificationsItem.propTypes = {
    notification: notificationsTypes,
    //Херовое название, тк не всегда есть линк.см обработчик клика
    gameLink: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    withLink: PropTypes.bool,
    short: PropTypes.bool,
    setViewed: PropTypes.func.isRequired,
    isInsideMenu: PropTypes.bool,
};

export default NotificationsItem;
