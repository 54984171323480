import { KEY } from "./reducer";
import { getCityById } from "domain/Directories/selectors";

export const getState = (state) => state[KEY] || {};

export const getGames = (state) => getState(state).items || {};

export const getGameById = (state, id) => getGames(state)[id] || {};

export const getGameOrganizer = (state, id) => getGameById(state, id).organizer;

export const getGamePlayerById = (state, gameId, playerId) =>
    getGamePlayers(state, gameId).find((player) => player.id === playerId);

export const getGamesListLink = (city) => (city ? `/${city.code}/games/` : "/");

export const getGameLink = ({
    state,
    gameId,
    withCode = true,
    full = false,
    gameData,
    city,
    isRegular,
}) => {
    const game = gameData ? gameData : getGameById(state, gameId);
    if (!game.id) {
        return `/city/game/${gameId}`;
    }

    const gameCity = city
        ? city
        : getCityById(state, game?.playground?.city_id);
    let link = `/${gameCity.code}/game/${game.id}`;

    if (withCode && !game.is_public && game.private_code) {
        link += `?code=${game.private_code}`;
    }

    if (full) {
        link = document.location.origin + link;
    }

    if (isRegular) {
        link = `/${gameCity.code}/regular-game/${game.id}`;
    }

    return link;
};

export const getGamePlayers = (state, gameId) =>
    getGameById(state, gameId).players || [];

export const findUserFromGame = (state, gameId, userId) =>
    getGamePlayers(state, gameId).find((player) => player.id === userId) || {};

export const getUserReserveOrder = (state, gameId, userId) =>
    findUserFromGame(state, gameId, userId).reserve_order;

export const getIsParticipate = (state, gameId, userId) =>
    !!findUserFromGame(state, gameId, userId).id;

export const getIsOrganizer = (state, gameId, userId) =>
    getGameById(state, gameId)?.organizer?.id === userId || false;

export const getIsTrainer = (state, gameId, userId) =>
    getGameById(state, gameId)?.trainer?.id === userId || false;

export const getGamePaymentMethod = (state, gameId) =>
    getGameById(state, gameId)?.payment_method;

export const getIsPaySuccess = (state, gameId) => {
    const status = getGameById(state, gameId)?.pay_status;

    if (status === "succeeded" || status === "waiting_for_capture") {
        return true;
    } else {
        return false;
    }
};

export const getIsPayStatus = (state, gameId) =>
    getGameById(state, gameId)?.pay_status;

export const getGamePaymentMethodLabel = (state, gameId) => {
    const method = getGameById(state, gameId)?.payment_method;

    if (method === "online") {
        return "Только онлайн";
    } else if (method === "offline") {
        return "На месте";
    } else {
        return "Возможно онлайн";
    }
};

//Если неправильно введен код - то приходит только type_id и id
export const CanGetGame = (state, id) => !!getGameById(state, id).organizer;

export const gameLoaded = (state, id) => !!getGames(state)[id];

/*------------------------------Обработка ошибок-------------------------*/

export const getError = (state) => getState(state).error;

export const getErrorDescription = (state) => getError(state)?.description;

export const getErrorPayload = (state) => getError(state)?.payload;
