import * as types from "./actions";
import {cloneDeep} from "lodash-es";

export const KEY = `regularGames`;

export const initialState = {
    items: [],
    error: {},
    isItemsLoaded: false,
    page: 1,
    page_size: 12,
    count: null,
    city_id: null,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                ...state,
                page: payload.page,
                items: [
                    ...state.items,
                    ...payload.items.filter(
                        (item) =>
                            !state.items.find((elem) => elem.id === item.id) &&
                            item
                    ),
                ],
                page_size: payload.page_size,
                count: payload.count,
                isItemsLoaded: true,
            };

        case types.GET_ITEM_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                items: state.items.find(
                    (elem) => elem.id === payload.regular_game.id
                )
                    ? state.items
                    : state.items.concat(payload.regular_game),
            };

        case types.ORDERS_FORGET_ITEM:
            return {
                ...cloneDeep(state),
                items: state.items.filter((elem) => elem.id !== payload),
            };

        case types.ORDERS_CHANGE_PAGE:
            return {
                ...cloneDeep(state),
                page: payload,
            };

        case types.UPDATE_GAME_DATA:
            return {
                ...cloneDeep(state),
                items:
                    state.items.length > 0 &&
                    state.items.find((elem) => elem.id === payload.id)
                        ? state.items.map((item) =>
                              item.id == payload.id ? payload.data : item
                          )
                        : state.items.concat(payload.data),
            };

        //ловим смену города, чтобы показать что сбросили список игр
        case types.UPDATE_CITY_ID:
            return {
                ...cloneDeep(state),
                items:state.city_id === payload
                    ? [...state.items]
                    : [],
                page: 1,
                city_id: payload,
            };

        default:
            return state;
    }
};

export default reducer;
