import { connect } from "react-redux";

import {
    getCookieCityIdFromState,
    getCityIdFromCookie,
    getShouldShowCitySelector,
} from "domain/User/selectors";
import { updateCookieCityId } from "domain/User/actions";
import { isLoggedIn } from "domain/Auth/selectors";
import {
    getActualCities,
    getCities,
    getOfflineCities,
} from "domain/Directories/selectors";
import { setShowedCitySelector } from "domain/User/actions";
import { changeFilter, forgetSearch } from "domain/GamesSearch/actions";
import Component from "./Component";
import { withRouter } from "react-router-dom";
import { updateCityId } from "domain/RegularGames/actions";
import { getFilters } from "domain/GamesSearch/selectors";
import { isMobile } from "domain/Version/selectors";

const mapStateToProps = (state) => {
    return {
        cities: getCities(state),
        cityId: getCookieCityIdFromState(state),
        cityIdFromCookie: getCityIdFromCookie(state),
        citiesCollection: getCities(state),
        isLoggedIn: isLoggedIn(state),
        shouldShowCitySelector: getShouldShowCitySelector(state),
        filters: getFilters(state),
        actualCities: getActualCities(state),
        offlineCities: getOfflineCities(state),
        isMobile: isMobile(state),
    };
};

const mapDispatchToProps = {
    updateCityIdForUnAuth: updateCookieCityId,
    setShowedCitySelector,
    changeFilter,
    updateCityId,
    forgetSearch,
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Component)
);
