import { useSelector } from "react-redux";
import { getFilterState, KEY } from "./reducer";
import { getCityById } from "domain/Directories/selectors";

export const getState = (state) => state[KEY] || {};

export const getPage = (state) => getState(state).page;

export const getPageSize = (state) => getState(state).page_size;

export const getCount = (state) => getState(state).count;

export const getItems = (state) => getState(state).items || [];

export const isItemsLoaded = (state) => getState(state).isItemsLoaded;

export const getItemById = (state, id) =>
    getItems(state).find((item) => item.id === id);

export const getFilters = (state) => getState(state).filters || {};

export const getFiltersKeys = (state) => Object.keys(getState(state).filters);

export const getMetaKeys = (state) => [...getFiltersKeys(state), "page_size"];

export const getMetaObject = (state) => ({
    ...getFilters(state),
    page_size: getPageSize(state),
    page: getPage(state),
});

export const getFilterItem = (state, id) => {
    return getFilterState(getState(state), id);
};

export const getResponse = (state) => getState(state).response;

export const getAboutFilters = (state) => getState(state).aboutFilters || {};

export const getActiveGameDate = () =>
    useSelector((state) => getState(state).activeGameDate);

export const getIsOrganizer = (state, gameId, userId) =>
    getItemById(state, gameId)?.organizer?.id === userId || false;

export const getIsTrainer = (state, gameId, userId) =>
    getItemById(state, gameId)?.trainer?.id === userId || false;

export const getShouldScrollToTop = () =>
    useSelector((state) => getState(state).shouldScrollToTop);

export const getGamePlayers = (state, gameId) =>
    getItemById(state, gameId).players || [];

export const findUserFromGame = (state, gameId, userId) =>
    getGamePlayers(state, gameId).find((player) => player.id === userId) || {};

export const getIsParticipateItem = (state, gameId, userId) =>
    !!findUserFromGame(state, gameId, userId).id;

export const getUserReserveOrderItem = (state, gameId, userId) =>
    findUserFromGame(state, gameId, userId).reserve_order;

export const getGamePaymentMethodItem = (state, gameId) =>
    getItemById(state, gameId)?.payment_method;

export const getGamePaymentMethodLabel = (state, gameId) => {
    const method = getItemById(state, gameId)?.payment_method;

    if (method === "online") {
        return "Только онлайн";
    } else if (method === "offline") {
        return "На месте";
    } else {
        return "Возможно онлайн";
    }
};

export const getIsPaySuccessItem = (state, gameId) => {
    const status = getItemById(state, gameId)?.pay_status;

    if (status === "succeeded" || status === "waiting_for_capture") {
        return true;
    } else {
        return false;
    }
};

export const getIsPayStatusItem = (state, gameId) =>
    getItemById(state, gameId)?.pay_status;

export const getGameLinkFromList = ({
    state,
    gameId,
    full = false,
    gameData,
    city,
    isRegular,
}) => {
    const game = gameData ? gameData : getItemById(state, gameId);

    if (!game.id) {
        return `/city/game/${gameId}`;
    }

    const gameCity = city ? city : getCityById(state, game.playground.city_id);
    let link = `/${gameCity.code}/game/${game.id}`;

    if (full) {
        link = document.location.origin + link;
    }

    if (isRegular) {
        link = `/${gameCity.code}/regular-game/${game.id}`;
    }

    return link;
};
