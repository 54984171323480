import PropTypes from "prop-types";

export const NAMESPACE = `DIRECTORIES`;

export const CITIES = `${NAMESPACE}.CITIES`;
export const CITIES_REQUEST_START = `${CITIES}_REQUEST_START`;
export const CITIES_REQUEST_SUCCESS = `${CITIES}_REQUEST_SUCCESS`;
export const CITIES_REQUEST_ERROR = `${CITIES}_REQUEST_ERROR`;

export const GAME_TYPES = `${NAMESPACE}.GAME_TYPES`;
export const GAME_TYPES_REQUEST_START = `${GAME_TYPES}_REQUEST_START`;
export const GAME_TYPES_REQUEST_SUCCESS = `${GAME_TYPES}_REQUEST_SUCCESS`;
export const GAME_TYPES_REQUEST_ERROR = `${GAME_TYPES}_REQUEST_ERROR`;

export const GAME_LEVELS = `${NAMESPACE}.GAME_LEVELS`;
export const GAME_LEVELS_REQUEST_START = `${GAME_LEVELS}_REQUEST_START`;
export const GAME_LEVELS_REQUEST_SUCCESS = `${GAME_LEVELS}_REQUEST_SUCCESS`;
export const GAME_LEVELS_REQUEST_ERROR = `${GAME_LEVELS}_REQUEST_ERROR`;

export const PLAYGROUND_OPTIONS = `${NAMESPACE}.PLAYGROUND_OPTIONS`;
export const PLAYGROUND_OPTIONS_REQUEST_START = `${PLAYGROUND_OPTIONS}_REQUEST_START`;
export const PLAYGROUND_OPTIONS_REQUEST_SUCCESS = `${PLAYGROUND_OPTIONS}_REQUEST_SUCCESS`;
export const PLAYGROUND_OPTIONS_REQUEST_ERROR = `${PLAYGROUND_OPTIONS}_REQUEST_ERROR`;

export const PLAYGROUND_TYPES = `${NAMESPACE}.PLAYGROUND_TYPES`;
export const PLAYGROUND_TYPES_REQUEST_START = `${PLAYGROUND_TYPES}_REQUEST_START`;
export const PLAYGROUND_TYPES_REQUEST_SUCCESS = `${PLAYGROUND_TYPES}_REQUEST_SUCCESS`;
export const PLAYGROUND_TYPES_REQUEST_ERROR = `${PLAYGROUND_TYPES}_REQUEST_ERROR`;

export const COUNTRIES = `${NAMESPACE}.COUNTRIES`;
export const COUNTRIES_REQUEST_START = `${COUNTRIES}_REQUEST_START`;
export const COUNTRIES_REQUEST_SUCCESS = `${COUNTRIES}_REQUEST_SUCCESS`;
export const COUNTRIES_REQUEST_ERROR = `${COUNTRIES}_REQUEST_ERROR`;

export const METRO_STATIONS = `${NAMESPACE}.METRO_STATIONS`;
export const METRO_STATIONS_REQUEST_START = `${METRO_STATIONS}_REQUEST_START`;
export const METRO_STATIONS_REQUEST_SUCCESS = `${METRO_STATIONS}_REQUEST_SUCCESS`;
export const METRO_STATIONS_REQUEST_ERROR = `${METRO_STATIONS}_REQUEST_ERROR`;

export const METRO_LINES = `${NAMESPACE}.METRO_LINES`;
export const METRO_LINES_REQUEST_START = `${METRO_LINES}_REQUEST_START`;
export const METRO_LINES_REQUEST_SUCCESS = `${METRO_LINES}_REQUEST_SUCCESS`;
export const METRO_LINES_REQUEST_ERROR = `${METRO_LINES}_REQUEST_ERROR`;

export const cityTypes = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
});

export const citiesTypes = PropTypes.arrayOf(cityTypes);

export const gameTypeTypes = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image_url: PropTypes.string,
});

export const levelTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const genderTypes = PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string,
});

export const playgroundTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    city_id: PropTypes.number.isRequired,
    type_id: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.number),
    media: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        })
    ),
});
