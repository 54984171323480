import { KEY } from "./reducer";

export const getState = (state) => state[KEY] || {};

export const getNeedImport = (state) => getState(state).needImport;

export const getUserId = (state) => getState(state).userId;

export const getHash = (state) => getState(state).hash;

export const getImportedData = (state) => getState(state).importedData;

export const getShowMessage = (state) => getState(state).showMessage;
