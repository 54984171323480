import numeral from "numeral";

numeral.register(`locale`, `s-k`, {
    delimiters: {
        thousands: ` `,
        decimal: `.`
    },
    abbreviations: {
        thousand: `тыс.`,
        million: `млн.`,
        billion: `млрд.`,
        trillion: `трлн.`
    },
    ordinal: function() {
        return `.`;
    },
    currency: {
        symbol: ``
    }
});

numeral.locale(`s-k`);

export default numeral;
