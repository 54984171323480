import SiteAPI from "./SiteAPI";

class TooManyRequests {
    constructor(response) {
        this.message = `Слишком много запросов`;
        this.retryAfter = +response.headers.get(`retry-after`);
    }
}

TooManyRequests.prototype = Object.create(SiteAPI.prototype);
TooManyRequests.prototype.constructor = TooManyRequests;
TooManyRequests.prototype.name = `TooManyRequests`;

export default TooManyRequests;
