import { FORGET } from "./actions";
import { LOG_OUT } from "domain/Auth/actions";

export const KEY = `httpRequests`;

const initialState = {};

const initialItemState = {
    isLoading: false,
    isLoaded: false,
    isFirstLoad: true,
    errorMessage: ``,
    errorsCount: 0,
    loadedAt: null,
};

export const makeKey = (name, meta) => {
    let id = ``;

    Object.keys(meta || {})
        .sort()
        .forEach((field) => (id += `-${field}-${meta[field]}`));

    return `${name}/${id}`;
};

export const getItemState = (state, keyOrName, meta = undefined) =>
    state[meta === undefined ? keyOrName : makeKey(keyOrName, meta)] ||
    initialItemState;

const changeItemState = (state, key, changes = {}) => {
    const item = getItemState(state, key);

    const hasChanges = Object.keys(changes).reduce(
        (hasChanges, field) => hasChanges || item[field] !== changes[field],
        false
    );

    if (!hasChanges) {
        return state;
    }

    return {
        ...state,
        [key]: {
            ...item,
            ...changes,
        },
    };
};

const START_SUFFIX = `_REQUEST_START`;
const SUCCESS_SUFFIX = `_REQUEST_SUCCESS`;
const ERROR_SUFFIX = `_REQUEST_ERROR`;

const ERRORS_THRESHOLD = 3;

const reducer = (state = initialState, { type, payload, meta }) => {
    if (type === LOG_OUT) {
        return initialState;
    }

    if (type === FORGET) {
        const key = makeKey(payload.name, payload.meta);

        changes = {
            isLoading: false,
            isLoaded: false,
            errorMessage: ``,
            errorsCount: 0,
        };

        return changeItemState(state, key, changes);
    }

    const isStart = type.endsWith(START_SUFFIX);
    const isSuccess = !isStart && type.endsWith(SUCCESS_SUFFIX);
    const isError = !isSuccess && type.endsWith(ERROR_SUFFIX);

    if (!isStart && !isSuccess && !isError) {
        return state;
    }

    let suffix = ``;

    if (isStart) {
        suffix = START_SUFFIX;
    } else if (isSuccess) {
        suffix = SUCCESS_SUFFIX;
    } else if (isError) {
        suffix = ERROR_SUFFIX;
    }

    const name = type.substring(0, type.indexOf(suffix));
    const key = makeKey(name, meta);
    let changes;

    if (isStart) {
        changes = {
            isLoading: true,
            errorMessage: ``,
        };
    } else if (isSuccess) {
        changes = {
            isLoading: false,
            isLoaded: true,
            isFirstLoad: false,
            errorMessage: ``,
            errorsCount: 0,
            loadedAt: Date.now(),
        };
    } else if (isError) {
        const item = getItemState(state, key);
        const errorsCount = item.errorsCount + 1;
        const errorsThresholdExceeded = errorsCount >= ERRORS_THRESHOLD;

        changes = {
            isLoading: false,
            isLoaded: errorsThresholdExceeded,
            errorMessage: payload.message || `Ошибка выполнения запроса`,
            loadedAt: errorsThresholdExceeded ? Date.now() : 0,
            errorsCount,
        };
    }

    return changeItemState(state, key, changes);
};

export default reducer;
