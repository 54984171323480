import * as types from "./actions";

export const KEY = `services`;

export const serviceSample = {
    id: 0,
    name: "",
    price: "",
    is_new: true,
};

export const initialState = {
    items: [],
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_SERVICE:
            return {
                ...state,
                items: state.items?.map((service) =>
                    service.id === payload.serviceId ? service : service
                ),
            };
        case types.SET_SERVICES:
            return {
                ...state,
                items: payload,
            };
        case types.UPDATE_SERVICE:
            return {
                ...state,
                items: state.items.map((service) =>
                    service.id == payload.serviceId
                        ? { ...service, ...payload.updatedFields }
                        : service
                ),
            };
        default:
            return state;
    }
};

export default reducer;
