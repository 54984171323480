import React from "react";
import PropTypes from "prop-types";

const NotificationsCounter = ({ count }) => {
    if (count === 0) {
        return null;
    }

    return <div className="sidebar__counter">{count}</div>;
};

NotificationsCounter.propTypes = {
    count: PropTypes.number.isRequired,
};

export default NotificationsCounter;
