export default (text, dict) => {
    if (!text) {
        return null;
    }

    if (typeof text !== "string") {
        return text;
    }

    const replacer = /\{|}/g;

    return text.replace(/\{name}|\{city}|\{tel}/g, match => {
        const variable = match.replace(replacer, ``);

        if (!dict[variable]) {
            return match;
        }

        return dict[variable];
    });
};
