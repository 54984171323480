import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getSEOMeta } from "domain/SEOmeta/selectors";
import { makeKey } from "domain/HttpRequests/reducer";
//import Loader from "components/Loaders/SEOMeta";
import Component from "./Component";
import { getCityForUser } from "domain/User/selectors";

const SEOMeta = (props) => {
    return <Component {...props} />
    /*return (
        <Loader type={props.type} value={props.value}>
            <Component {...props} />
        </Loader>
    );*/
};

const mapStateToProps = (state, { type, value, name }) => {
    const city = getCityForUser(state);
    const url = window.location.origin + window.location.pathname;

    const metaFields = {
        ...getSEOMeta(state, makeKey(`seo`, { type, value, cityId: city.id })),
    };

    metaFields[`twitter_image_src`] = metaFields[`twitter_image`];

    if (metaFields[`city_id`] === null) {
        metaFields[`og_url`] = url;
    }

    if (!name) {
        name = metaFields.title;
    }

    delete metaFields[`city_id`];
    delete metaFields[`twitter_image`];
    delete metaFields[`text`];

    return {
        fields: metaFields,
        city: city.name,
        name,
        url,
    };
};

SEOMeta.propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default connect(mapStateToProps)(SEOMeta);
