import Component from "./Component";
import { connect } from "react-redux";

import { isLoggedIn } from "domain/Auth/selectors";
import { logout } from "domain/Auth/actions";
import {
    getCityForUser,
    getProfile,
    getCityIdForUser,
    getOrganizedRegularGamesCount,
    getShowPopover,
} from "domain/User/selectors";

import { updateCookieCityId, updateCookiePopover } from "domain/User/actions";
import { changeFilter, forgetSearch } from "domain/GamesSearch/actions";
import { getFilters } from "domain/GamesSearch/selectors";

const mapStateToProps = (state) => {
    return {
        profile: getProfile(state),
        isLoggedIn: isLoggedIn(state),
        city: getCityForUser(state),
        city_id: getCityIdForUser(state),
        organized_regular_games_count: getOrganizedRegularGamesCount(state),
        showPopover: getShowPopover(state),
        filters: getFilters(state),
    };
};

const mapDispatchToProps = {
    logout,
    updateCityIdForUnAuth: updateCookieCityId,
    updateCookiePopover,
    forgetSearch,
    changeFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
