import status from "http-status";

import inDevelopmentMode from "lib/inDevelopmentMode";
import SiteAPIError from "errors/SiteAPI";
import ValidationError from "errors/Validation";
import TooManyRequestsError from "errors/TooManyRequests";
import AuthExpiredError from "errors/AuthExpired";

const parseResponse = async (response) => {
    if (response.status === status.TOO_MANY_REQUESTS) {
        throw new TooManyRequestsError(response);
    }

    if (response.status === status.UNAUTHORIZED) {
        throw new AuthExpiredError();
    }

    const text = await response.text();

    if (inDevelopmentMode) {
        // eslint-disable-next-line no-console
        //console.log(`Site API response`, response.url, response.status, text.substr(0, 2048));
    }

    let json;

    try {
        json = JSON.parse(text);
    } catch (error) {
        throw new SiteAPIError(
            `Не удалось разобрать ответ от сервера${
                inDevelopmentMode ? `: ${text}` : ``
            }`
        );
    }

    if (response.status === status.UNPROCESSABLE_ENTITY) {
        throw new ValidationError(json);
    }

    if (json?.response?.GeoObjectCollection) {
        return json;
    }

    // noinspection JSUnresolvedVariable
    if (!json.is_ok) {
        throw new SiteAPIError(
            json.description || json.error || `Ошибка обращения к серверу`,
            json || {}
        );
    }

    return json;
};

export default parseResponse;
