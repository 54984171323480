import request from "domain/SiteAPI";
import { GET, POST, DELETE, PUT } from "domain/SiteAPI/httpMethods";

export const get = async (id) => {
    return await request({
        url: `lists/${id}`,
        method: GET,
    });
};

export const create = async (fields) => {
    return await request({
        url: `lists`,
        method: POST,
        body: fields,
    });
};

export const remove = async (id) => {
    return await request({
        url: `lists/${id}`,
        method: DELETE,
    });
};

export const search = async (query = "") => {
    return await request({
        url: `lists`,
        method: GET,
        parameters: {
            query,
        },
    });
};

export const update = async (id, newFields = {}) => {
    return await request({
        url: `lists/${id}`,
        method: PUT,
        body: newFields,
    });
};

export const addMember = async (groupId, userId) => {
    return await request({
        url: `lists/${groupId}/players/${userId}/add`,
        method: POST,
    });
};

export const removeMember = async (groupId, userId) => {
    return await request({
        url: `lists/${groupId}/players/${userId}/exclude`,
        method: POST,
    });
};
