import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import { citiesTypes } from "domain/Directories/types";

class CitySelector extends React.Component {
    constructor(props) {
        super(props);
    }

    handleCitySelectChange = (item) => {
        this.setState({
            cityName: item.value,
        });
        this.props.onChange(item);
    };

    handleCityChange = () => {
        this.props.setShowedCitySelector(false);
    };

    componentDidUpdate(prevProps) {
        if (this.props.cityId !== prevProps.cityId) {
            this.props.onChange(this.props.cityId);
        }
    }

    render() {
        const { cities, cityId, className } = this.props;

        const selectedCityName = cities.find((city) => {
            return city.id === cityId;
        }).name;

        return (
            <div className={className}>
                <button
                    onClick={this.handleCityChange}
                    className="btn city-selector__btn sidebar__item"
                >
                    <Icon type={`location`} inheritColor />

                    <span className="hide-tablet-down">{selectedCityName}</span>
                </button>
            </div>
        );
    }
}

CitySelector.propTypes = {
    cityId: PropTypes.number,
    defaultValue: PropTypes.number,
    cities: citiesTypes,
    minWidth: PropTypes.number,
    onChange: PropTypes.func,
    setShowedCitySelector: PropTypes.func,
    className: PropTypes.string,

};

export default CitySelector;
