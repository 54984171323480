export const NAMESPACE = `HTTP_REQUESTS`;

export const FORGET = `${NAMESPACE}.FORGET`;

export const forget = (name, meta = {}) => {
    return {
        type: FORGET,
        payload: {name, meta},
    };
};
