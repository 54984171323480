import { forget } from "domain/HttpRequests/actions";
import call from "domain/SiteAPI/reduxAction";
import * as API from "domain/RegularGames/api";

export const NAMESPACE = `REGULAR_GAMES`;

export const GET = `${NAMESPACE}.GET`;

export const GET_REQUEST_START = `${NAMESPACE}.GET_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${NAMESPACE}.GET_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${NAMESPACE}.GET_REQUEST_ERROR`;

export const ORDERS_CHANGE_PAGE = `${NAMESPACE}_CHANGE_PAGE`;
export const UPDATE_CITY_ID = `${NAMESPACE}_UPDATE_CITY_ID`;

export const UPDATE_GAME_DATA = `${NAMESPACE}.UPDATE_GAME_DATA`;

export const NAME_ITEM = "REGULAR_GAME";

export const GET_ITEM = `${NAME_ITEM}.GET`;

export const GET_ITEM_REQUEST_START = `${NAME_ITEM}.GET_REQUEST_START`;
export const GET_ITEM_REQUEST_SUCCESS = `${NAME_ITEM}.GET_REQUEST_SUCCESS`;
export const GET_ITEM_REQUEST_ERROR = `${NAME_ITEM}.GET_REQUEST_ERROR`;
export const ORDERS_FORGET_ITEM = `${NAMESPACE}_FORGET_ITEM`;

let prevSearchParams = null;

export const get = ({ page = 1, city_id, page_size = 12 }) => (dispatch) => {
    if (prevSearchParams) {
        dispatch(forget(GET, prevSearchParams));
    }
    const meta = { page, city_id, page_size };
    prevSearchParams = { ...meta };

    dispatch(
        call({
            request: API.get.bind(null, {
                page,
                city_id,
                page_size,
            }),
            namespace: GET,
            meta,
        })
    );
};

export const getItem = ({ id, code }) => {
    return call({
        request: API.getItem.bind(null, {
            id,
            code,
        }),
        namespace: GET_ITEM,
        meta: { id, code },
    });
};

export const recall = ({ id, code }) => (dispatch) => {
    dispatch(forget(GET_ITEM, { id, code }));
    dispatch(getItem({ id, code }));
};

export const changePage = (page) => (dispatch) => {
    dispatch({
        type: ORDERS_CHANGE_PAGE,
        payload: page,
    });
};

export const updateGameData = (id, data) => {
    return {
        type: UPDATE_GAME_DATA,
        payload: { id, data },
    };
};

export const forgetItem = (id) => (dispatch) => {
    dispatch({
        type: ORDERS_FORGET_ITEM,
        payload: id,
    });
};

export const updateCityId = (value) => {
    return {
        type: UPDATE_CITY_ID,
        payload: value,
    };
};
