import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Component from "components/ErrorNotFound";

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        handleCLose: () => dispatch(ownProps.history.push("/")),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(Component));
