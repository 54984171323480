import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import routes, { needFeelProfileRoutes } from "../../routes";
import Layout from "../Layout";
import NotFound from "../../Pages/NotFound";
import ErrorBoundary from "components/ErrorBoundary";
import ProtectedRoute from "components/Router/ProtectedRoute";
import CustomPrompt from "components/CustomPrompt";
import { toast } from "react-toastify";

const Router = ({ needFillProfile, minWidth }) => {
    const renderUserConfirmation = (message, callback) => {
        const node = document.getElementById("custom-prompt");

        const cleanUp = (answer) => {
            ReactDOM.unmountComponentAtNode(node);
            callback(answer);
        };

        ReactDOM.render(
            <CustomPrompt message={message} cleanUp={cleanUp} />,
            node
        );
    };

    useEffect(() => {
        if (needFillProfile) {
            toast.warn(
                "Пожалуйста заполните профиль, чтобы получить доступ к всем разделам сайта"
            );
        }
    }, [needFillProfile]);

    const _routes = needFillProfile ? needFeelProfileRoutes(minWidth) : routes;

    return (
        <BrowserRouter getUserConfirmation={renderUserConfirmation}>
            <Layout needFillProfile={needFillProfile}>
                <Switch>
                    {_routes.map((route) => {
                        if (route.withAuthorized) {
                            return (
                                <ProtectedRoute key={route.path} {...route} />
                            );
                        } else {
                            return (
                                <Route
                                    key={route.path}
                                    {...route}
                                    render={() => {
                                        return (
                                            <ErrorBoundary>
                                                {route.render ? (
                                                    route.render()
                                                ) : (
                                                    <route.component />
                                                )}
                                            </ErrorBoundary>
                                        );
                                    }}
                                />
                            );
                        }
                    })}

                    <Route component={NotFound} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
};

Router.propTypes = {
    needFillProfile: PropTypes.bool,
    minWidth: PropTypes.number,
};

export default Router;
