import * as types from "./actions";
import {GET_REQUEST_ERROR, GET_REQUEST_SUCCESS} from "domain/User/actions";

export const KEY = `auth`;

export const STATUS_GUEST = `guest`;
export const STATUS_CLIENT = `client`;

export const initialState = STATUS_GUEST;

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_REQUEST_SUCCESS:
            return payload ? STATUS_CLIENT : STATUS_GUEST;

        case GET_REQUEST_ERROR:
        case types.LOG_OUT:
            return STATUS_GUEST;

        default:
            return state;
    }
};

export default reducer;
