import loadable from "@loadable/component";
import { Redirect } from "react-router-dom";
import React from "react";
import { memoize } from "lodash-es";

const routes = [
    {
        path: `/`,
        component: loadable(() => import("./Pages/Home")),
        exact: true,
    },
    {
        path: `/signin`,
        component: loadable(() => import("./Pages/SignIn")),
        exact: true,
    },
    {
        path: `/signup`,
        component: loadable(() => import("./Pages/SignUp")),
        exact: true,
    },
    {
        path: `/password_reset`,
        component: loadable(() => import("./Pages/PasswordRecovery")),
        exact: true,
    },
    {
        path: `/profile`,
        component: loadable(() => import("./Pages/Profile")),
        withAuthorized: true,
    },
    {
        path: `/notifications`,
        component: loadable(() => import("./Pages/Notifications")),
        withAuthorized: true,
    },
    {
        path: `/groups`,
        component: loadable(() => import("./Pages/Groups/index")),
        withAuthorized: true,
    },
    {
        path: `/create_profile`,
        component: loadable(() => {
            return import("./Pages/CreateProfile");
        }),
        withAuthorized: true,
    },
    {
        path: `/settings`,
        component: loadable(() => import("./Pages/Settings")),
        withAuthorized: true,
    },
    {
        path: `/agreement`,
        component: loadable(() => import("./Pages/UserAgreement")),
        exact: true,
    },
    {
        path: `/about`,
        component: loadable(() => import("./Pages/About")),
    },
    {
        path: `/contacts`,
        component: loadable(() => import("./Pages/Contacts")),
    },

    {
        path: `/:cityAbbr/game/create`,
        component: loadable(() => import("./Pages/CreateGame")),
        exact: false,
        withAuthorized: true,
    },
    {
        path: `/:cityAbbr/game/:id`,
        component: loadable(() => import("./Pages/PageGameDetails")),
        exact: true,
    },
    {
        path: `/:cityAbbr/regular_game/:id`,
        component: loadable(() => import("./Pages/PageRegularGameDetails")),
        exact: true,
    },
    {
        path: `/editGame/:id`,
        component: loadable(() => import("./Pages/EditGame")),
        exact: false,
        withAuthorized: true,
    },
    {
        path: `/edit_regular_game/:id`,
        component: loadable(() => import("./Pages/EditRegularGame")),
        exact: false,
        withAuthorized: true,
    },
    {
        path: `/:cityAbbr/games`,
        component: loadable(() => import("./Pages/Games")),
        exact: false,
    },
    {
        path: `/:cityAbbr/regular_games`,
        component: loadable(() => import("./Pages/RegularGames")),
        exact: false,
    },
    {
        path: `/:cityAbbr/schools`,
        component: loadable(() => import("./Pages/Schools/Component")),
        exact: false,
    },
    {
        path: `/:cityAbbr/school/:id/edit`,
        component: loadable(() => import("./Pages/EditSchoolPage/index")),
        exact: false,
    },
    {
        path: `/:cityAbbr/school/:id`,
        component: loadable(() => import("./Pages/SchoolPage/index")),
        exact: false,
    },
    {
        path: `/:cityAbbr/sportcourt/new`,
        component: loadable(() => import("./Pages/CreateSportComplexPage")),
        exact: false,
        withAuthorized: true,
    },
    {
        path: `/:cityAbbr/sportcourt/:id/edit`,
        component: loadable(() => import("./Pages/EditSportComplexPage")),
        exact: false,
        withAuthorized: true,
    },
    {
        path: `/:cityAbbr/sportcourt/:id/schedule`,
        component: loadable(() => import("./Pages/SportComplexSchedule")),
        exact: false,
    },
    {
        path: `/:cityAbbr/sportcourt/:id`,
        component: loadable(() => import("./Pages/SportComplexPage")),
        exact: false,
    },
    {
        path: `/:cityAbbr/sportcourts/new_request`,
        component: loadable(() => import("./Pages/AddLandlord/AddLandlord")),
        exact: false,
    },
    {
        path: `/:cityAbbr/sportcourts/my`,
        component: loadable(() => import("./Pages/SportComplexesCabinet")),
        withAuthorized: true,
        exact: false,
    },
    {
        path: `/:cityAbbr/sportcourts`,
        component: loadable(() => import("./Pages/SportComplexes")),
        exact: false,
    },
    {
        path: `/:cityAbbr/cabinet`,
        component: loadable(() => import("./Pages/CabinetOrg")),
        withAuthorized: true,
        exact: false,
    },
    {
        path: `/add_city`,
        component: loadable(() => import("./Pages/AddCity")),
        exact: false,
    },
    {
        path: "*",
        component: loadable(() => import("./Pages/NotFound")),
        exact: false,
    },
];

export const needFeelProfileRoutes = memoize(() => {
    return [
        {
            path: `/create_profile`,
            component: loadable(() => {
                return import("./Pages/CreateProfile");
            }),
            withAuthorized: true,
        },
        {
            path: `*`,
            // eslint-disable-next-line react/display-name
            component: () => {
                return <Redirect to={"/create_profile"} />;
            },
            withAuthorized: true,
        },
    ];
});

export default routes;
