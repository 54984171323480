import request from "domain/SiteAPI";
import { GET } from "domain/SiteAPI/httpMethods";

export const search = async ({
    page,
    city_id,
    type_id,
    page_size = 12,
    my,
    past,
    alias,
    trainings,
    school_id,
    school_group_id,
    trainer_id,
}) => {
    return await request({
        url: `games`,
        method: GET,
        parameters: {
            page,
            page_size,
            city_id,
            type_id,
            my,
            past,
            alias,
            trainings,
            school_id,
            school_group_id,
            trainer_id,
        },
    });
};

export const searchWithCursor = async ({
    city_id,
    type_id,
    page_size = 12,
    my,
    past,
    alias,
    cursor,
    school_id,
    trainings,
    school_group_id,
    trainer_id,
}) => {
    return await request({
        url: `games/cursor`,
        method: GET,
        parameters: {
            page_size,
            city_id,
            type_id,
            my,
            past,
            alias,
            cursor,
            school_id,
            trainings,
            school_group_id,
            trainer_id,
        },
    });
};

export const getDatesList = async ({
    city_id,
    type_id,
    page_size = 12,
    my,
    past,
    alias,
    trainings,
    school_id,
    school_group_id,
    trainer_id,
}) => {
    return await request({
        url: `games/cursor_list`,
        method: GET,
        parameters: {
            page_size,
            city_id,
            type_id,
            my,
            past,
            alias,
            trainings,
            school_id,
            school_group_id,
            trainer_id,
        },
    });
};