import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as types from "domain/Directories/types";
import actions from "domain/Directories/actions";
import { getLoaderProps } from "domain/HttpRequests/selectors";
import GenericLoader from "./Generic";

const mapStateToProps = (state, { names }) => {
    let shouldLoad = false;
    let shouldShowLoadingIndicator = false;
    let shouldShowErrorMessage = false;
    let errorMessage = ``;

    names.forEach((directory) => {
        const request = getLoaderProps(state, types[directory.toUpperCase()]);

        shouldLoad = shouldLoad || request.shouldLoad;
        shouldShowLoadingIndicator =
            shouldShowLoadingIndicator || request.shouldShowLoadingIndicator;
        shouldShowErrorMessage =
            shouldShowErrorMessage || request.shouldShowErrorMessage;
        errorMessage = errorMessage || request.errorMessage;
    });

    return {
        shouldLoad,
        shouldShowLoadingIndicator,
        shouldShowErrorMessage,
        errorMessage,
    };
};

const mapDispatchToProps = (dispatch, { names }) => {
    return {
        load: () => names.forEach(
            (directory) => {dispatch(actions[directory]())}
        ),
    }
};

const DirectoriesLoader = connect(
    mapStateToProps,
    mapDispatchToProps
)(GenericLoader);

DirectoriesLoader.propTypes = {
    ...GenericLoader.propTypes,
    names: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DirectoriesLoader;
