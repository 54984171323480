export const NAMESPACE = `SCHOOLS`;
export const NAME_ITEM = "SCHOOL";

export const SAVE_ITEMS = `${NAMESPACE}.SAVE_ITEMS`;
export const SAVE_ITEM = `${NAME_ITEM}.SAVE_ITEM`;
export const UPDATE_ITEM = `${NAME_ITEM}.UPDATE_ITEM`;

export const setItems = (schools) => {
    return {
        type: SAVE_ITEMS,
        payload: schools,
    };
};

export const setItem = (school) => {
    return {
        type: SAVE_ITEM,
        payload: school,
    };
};

export const updateItem = ( key, value ) => {
    return {
        type: UPDATE_ITEM,
        payload:  {key, value} ,
    };
};
