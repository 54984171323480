import React from "react";
import classNames from "classnames";

import Auth from "components/Header/Auth";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "../../Icon";
import { profileType } from "domain/User/types";
import PersonPreview from "components/PersonPreview";
import { cityTypes } from "domain/Directories/types";
import CitySelector from "../../../../features/Filters/CitySelector";
import NotificationsCounter from "components/NotificationsCounter";
import Popover from "components/Header/Sidebar/Popover";
import { getCookie, setCookie } from "utils/cookies";
import Button from "ui/Button";
import { canShow } from "domain/Playgrounds/api";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCanShow: false,
        };
    }

    async componentDidMount() {
        try {
            const res = await canShow();
            this.setState({ isCanShow: res.show_complex });
        } catch (err) {
            throw Error;
        }
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (
            location.pathname !== prevProps.location.pathname &&
            location.pathname.includes("regular_games")
        ) {
            getCookie("showPopover") > 0 && this.offPopover();
            return;
        }
    }

    offPopover = () => {
        setCookie("showPopover", 0);
        this.props.updateCookiePopover(0);
    };

    changeCity = (cityId) => {
        const { changeFilter, updateCityIdForUnAuth } = this.props;
        changeFilter({ city_id: cityId });
        updateCityIdForUnAuth(cityId);
    };

    clearFilters = () => {
        const { filters, changeFilter, history, city } = this.props;

        if (filters?.alias) {
            changeFilter({ ...filters, alias: null });
        }

        history.push(`/${city.code}/games`);
    };

    render() {
        const {
            isLoggedIn,
            profile,
            logout,
            city,
            location,
            city_id,
            isOpen,
            organized_regular_games_count,
            showPopover,
        } = this.props;

        const { isCanShow } = this.state;
        const isHasOnlinePay = profile.has_online_pay;
        const isLandlord = profile.is_landlord;

        return (
            <nav
                className={classNames("sidebar", {
                    active: isOpen,
                    "sidebar--logged": isLoggedIn,
                })}
            >
                <div className="sidebar__inner">
                    <a href="/" className="sidebar__logo logo">
                        <img
                            src="/images/logo.svg"
                            alt="Логотип Наигру"
                            loading="lazy"
                        />
                    </a>

                    {isLoggedIn ? (
                        <PersonPreview
                            {...profile}
                            showCroppedAvatar
                            mods={[`sidebar__person`]}
                            linkToProfile
                        />
                    ) : (
                        <>
                            <p className="header__slogan">
                                Зарегистрируйся и участвуй в спортивных играх в
                                своем городе
                            </p>
                        </>
                    )}

                    <CitySelector
                        id={`CitySelector${city_id}`}
                        key={`CitySelector${city_id}`}
                        onChange={this.changeCity}
                        value={city_id}
                        className={classNames(
                            "city-selector hide-tablet-down",
                            {
                                "city-selector--logged": isLoggedIn,
                            }
                        )}
                    />

                    <ul className="sidebar__actions">
                        {isLoggedIn ? (
                            <>
                                <li className="sidebar__item sidebar__item--create">
                                    <Link
                                        to={`/${city.code}/game/create`}
                                        className="sidebar__link sidebar__link--create"
                                    >
                                        <Icon
                                            type={`plus`}
                                            inheritColor
                                            className="hide-desktop-up"
                                        />
                                        <span className="sidebar__create-text">
                                            Создать игру
                                        </span>
                                    </Link>
                                </li>

                                <li
                                    className={classNames("sidebar__item", {
                                        active:
                                            location.pathname.indexOf(
                                                "/games"
                                            ) !== -1 ||
                                            location.pathname === "/",
                                    })}
                                >
                                    <Link
                                        to={`/${city.code}/games`}
                                        className="sidebar__link"
                                        onClick={this.clearFilters}
                                    >
                                        <Icon type={`ball`} inheritColor />
                                        <span>Игры</span>
                                    </Link>
                                </li>

                                {organized_regular_games_count > 0 && (
                                    <li
                                        className={classNames("sidebar__item", {
                                            active:
                                                location.pathname.indexOf(
                                                    "/regular_games"
                                                ) !== -1 ||
                                                location.pathname.indexOf(
                                                    "/regular_game"
                                                ) !== -1 ||
                                                location.pathname ===
                                                    "/regular_games",
                                        })}
                                    >
                                        <Link
                                            to={`/${city.code}/regular_games`}
                                            className="sidebar__link"
                                        >
                                            <Icon
                                                type={`refresh`}
                                                inheritColor
                                            />
                                            <span>Регулярные игры</span>
                                        </Link>
                                        {showPopover > 0 && (
                                            <Popover>
                                                <Button
                                                    mixes={["close__popover"]}
                                                    onClick={() =>
                                                        this.offPopover()
                                                    }
                                                >
                                                    <Icon
                                                        type="close"
                                                        inheritColor
                                                    />
                                                </Button>
                                            </Popover>
                                        )}
                                    </li>
                                )}

                                <li
                                    className={classNames("sidebar__item", {
                                        active:
                                            location.pathname.indexOf(
                                                "/schools"
                                            ) !== -1,
                                    })}
                                >
                                    <Link
                                        to={`/${city.code}/schools`}
                                        className="sidebar__link sidebar__link--relative"
                                    >
                                        <Icon type="trainings" inheritColor />
                                        <span>Тренировки</span>
                                    </Link>
                                </li>

                                {isCanShow && (
                                    <li
                                        className={classNames("sidebar__item", {
                                            active:
                                                location.pathname ===
                                                    `/${city.code}/sportcourts` ||
                                                (location.pathname.includes(
                                                    `/${city.code}/sportcourt/`
                                                ) &&
                                                    !location.pathname.includes(
                                                        "schedule"
                                                    ) &&
                                                    !location.pathname.includes(
                                                        "edit"
                                                    ) &&
                                                    !location.pathname.includes(
                                                        "new"
                                                    )),
                                        })}
                                    >
                                        <Link
                                            to={`/${city.code}/sportcourts`}
                                            className="sidebar__link sidebar__link--relative"
                                        >
                                            <Icon
                                                type="sportcourts"
                                                inheritColor
                                            />
                                            <span>Аренда площадок</span>
                                        </Link>
                                    </li>
                                )}

                                <li
                                    className={classNames("sidebar__item", {
                                        active: location?.pathname?.startsWith(
                                            "/groups"
                                        ),
                                    })}
                                >
                                    <Link
                                        to="/groups"
                                        className="sidebar__link"
                                    >
                                        <Icon type="group" inheritColor />
                                        <span>Списки</span>
                                    </Link>
                                </li>

                                <li
                                    className={classNames("sidebar__item", {
                                        active:
                                            location.pathname ===
                                            "/notifications",
                                    })}
                                >
                                    <Link
                                        to="/notifications"
                                        className="sidebar__link sidebar__link--relative"
                                    >
                                        <Icon type="bell" inheritColor />
                                        <span>Уведомления</span>

                                        <NotificationsCounter />
                                    </Link>
                                </li>

                                {(isHasOnlinePay || isLandlord) && (
                                    <>
                                        <hr className="sidebar__line" />
                                        <li
                                            className={classNames(
                                                "sidebar__item sidebar__long",
                                                {
                                                    active:
                                                        location.pathname.indexOf(
                                                            "/cabinet"
                                                        ) !== -1,
                                                }
                                            )}
                                        >
                                            <Link
                                                to={`/${city.code}/cabinet`}
                                                className="sidebar__link"
                                            >
                                                <Icon
                                                    type="requisite"
                                                    inheritColor
                                                />
                                                <span>Кабинет организации</span>
                                            </Link>
                                        </li>
                                    </>
                                )}

                                {isLandlord && (
                                    <li
                                        className={classNames("sidebar__item", {
                                            active:
                                                location.pathname.includes(
                                                    "/sportcourts/my"
                                                ) ||
                                                location.pathname.includes(
                                                    "schedule"
                                                ) ||
                                                (location.pathname.includes(
                                                    "sportcourt"
                                                ) &&
                                                    location.pathname.includes(
                                                        "edit"
                                                    )) ||
                                                location.pathname.includes(
                                                    "new"
                                                ),
                                        })}
                                    >
                                        <Link
                                            to={`/${city.code}/sportcourts/my`}
                                            className="sidebar__link"
                                        >
                                            <Icon type="edit" inheritColor />
                                            <span>Площадки</span>
                                        </Link>
                                    </li>
                                )}

                                <li className="sidebar__logout logout">
                                    <button
                                        className="logout__link"
                                        type="button"
                                        onClick={logout}
                                    >
                                        <Icon
                                            type={`sign_out_square`}
                                            inheritColor
                                        />
                                        <span>Выйти</span>
                                    </button>
                                </li>
                            </>
                        ) : (
                            <li className="sidebar__item">
                                <Auth />
                            </li>
                        )}
                    </ul>

                    <div className="sidebar__about">
                        <a
                            className="fs-caption c-gs-label"
                            href="/about"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            О сервисе
                        </a>
                        <Link className="fs-caption c-gs-label" to="/contacts">
                            Контакты
                        </Link>
                        <a
                            className="fs-caption c-gs-label"
                            href="/agreement"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            Пользовательское соглашение
                        </a>
                        <a
                            className="fs-caption c-gs-label"
                            href="/agreement"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            Политика конфиденциальности
                        </a>
                        <a
                            className="fs-caption c-gs-label"
                            href="https://itgro.ru/"
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            Разработано в Айтигро
                        </a>
                    </div>
                </div>
            </nav>
        );
    }
}

Sidebar.defaultProps = {
    location: "",
};

Sidebar.propTypes = {
    isOpen: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    profile: profileType,
    logout: PropTypes.func.isRequired,
    city: cityTypes,
    location: PropTypes.object,
    changeFilter: PropTypes.func,
    updateCityIdForUnAuth: PropTypes.func,
    city_id: PropTypes.number,
    organized_regular_games_count: PropTypes.number,
    showPopover: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    updateCookiePopover: PropTypes.func,
    filters: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(Sidebar);
