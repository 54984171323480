//https://learn.javascript.ru/cookie#prilozhenie-funktsii-dlya-raboty-s-kuki
export const getCookie = (name) => {
    let matches = document.cookie.match(
        // eslint-disable-next-line
        new RegExp(
            "(?:^|; )" +
                // eslint-disable-next-line
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                "=([^;]*)"
        )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, options = {}) => {
    options = {
        path: "/",
        // при необходимости добавьте другие значения по умолчанию
        ...options,
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
        encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};

export const getCurrentDomain = () => {
    return window.location.hostname;
};
