import * as types from "./actions";
import { cloneDeep } from "lodash-es";
import filter from "lodash-es/filter";

export const KEY = `GROUPS`;

const initialState = { query: "", groups: {} };

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                groups: { ...cloneDeep(state.groups), [payload.id]: payload },
            };

        case types.SEARCH_REQUEST_SUCCESS:
            return {
                ...cloneDeep(state),
                groups: {
                    ...cloneDeep(state.groups),
                    ...payload.reduce(
                        (accum, group) => ({ ...accum, [group.id]: group }),
                        {}
                    ),
                },
            };

        case types.UPDATE_GROUPS_STATE:
            return {
                ...cloneDeep(state),
                groups: { ...cloneDeep(state.groups), ...payload },
            };

        case types.UPDATE_GROUP_DATA:
            return {
                ...cloneDeep(state),
                groups: {
                    ...cloneDeep(state.groups),
                    [payload.groupId]: payload.group,
                },
            };

        case types.SET_QUERY:
            return {
                ...cloneDeep(state),
                query: payload,
            };

        case types.REMOVE:
            return {
                ...cloneDeep(state),
                groups: cloneDeep(
                    filter(
                        state.groups,
                        // eslint-disable-next-line
                        ({ id }) => parseInt(id) !== parseInt(payload)
                    )
                ),
            };

        default:
            return state;
    }
};

export default reducer;
