import { connect } from "react-redux";
import queryString from "qs";
import trim from "lodash-es/trim";

import { getLoaderProps } from "domain/HttpRequests/selectors";
import GenericLoader from "components/Loaders/Generic";
import { GET, get, setFilters } from "domain/GamesSearch/actions";
import { getMetaObject } from "domain/GamesSearch/selectors";
import { getCityForUser, getCityIdForUser } from "domain/User/selectors";
import { getCityByCode, getGameTypeByCode } from "domain/Directories/selectors";
import { updateCookieCityId } from "domain/User/actions";
import { getParameterByName } from "lib/url";
import { cloneDeep } from "lodash-es";
import initialState from "domain/GamesSearch/initialState";

let isFirstRequests = true;
let forced = false;

export const getInitialFilters = (state) => {
    let initialFilters = {};

    const query = queryString.parse(location);
    const pathnameArr = trim(query.pathname, "/").split("/");

    pathnameArr.forEach((value, index) => {
        if (!value || value === "") {
            return;
        }

        if (index === 0) {
            let city = getCityByCode(state, pathnameArr[0]);
            if (!city) {
                city = getCityForUser(state);
            }
            return (initialFilters.city_id = city.id);
        }

        if (["my", "past"].indexOf(value) !== -1) {
            return (initialFilters[value] = true);
        }

        if (index < 2) {
            return;
        }

        const gameType = getGameTypeByCode(state, value);

        if (index === 2 && !gameType?.code ) {
            return (initialFilters["alias"] = value);
        }

        if (gameType) {
            return (initialFilters.type_id = gameType.id);
        }
    });

    return initialFilters;
};

const mapStateToProps = (state) => {
    const forceGetValue = getParameterByName("force");

    const needForceUrl = forceGetValue && !forced;

    if (needForceUrl) {
        forced = true;
    } else if (forced && !forceGetValue) {
        forced = false;
    }

    let meta = getMetaObject(state);
    if (!meta.city_id) {
        meta.city_id = getCityIdForUser(state);
    }

    if (isFirstRequests || needForceUrl) {
        const initMeta = getInitialFilters(state);
        meta = needForceUrl
            ? {
                  page: initialState.page,
                  page_size: initialState.page_size,
                  ...cloneDeep(initialState.filters),
                  ...initMeta,
              }
            : { ...meta, ...initMeta };
    }


    const loaderProps = getLoaderProps(state, GET, meta);

    return {
        ...loaderProps,
        ...meta,
        searchParams: meta,
        needForceUrl,
    };
};

const mergeProps = (stateProps, { dispatch }, ownProps) => {
    if (isFirstRequests || stateProps.needForceUrl) {
        isFirstRequests = false;
        dispatch(setFilters(stateProps.searchParams));
        if (stateProps.searchParams.city_id) {
            dispatch(updateCookieCityId(stateProps.searchParams.city_id));
        }
    }

    return {
        ...stateProps,
        ...ownProps,
        load: () => dispatch(get(stateProps.searchParams)),
    };
};

export default connect(mapStateToProps, null, mergeProps)(GenericLoader);
