import { connect } from "react-redux";

import { getGameTypes, getLevels } from "domain/Directories/selectors";
import Component from "./Component";

const mapStateToProps = (state) => {
    return { gameTypes: getGameTypes(state), levels: getLevels(state) };
};

export default connect(mapStateToProps)(Component);
