import request from "domain/SiteAPI";
import { GET, POST } from "domain/SiteAPI/httpMethods";

export const get = async ({ offset, page_size }) => {
    return await request({
        url: `notifications`,
        method: GET,
        parameters: { offset, page_size },
    });
};

export const check = async () => {
    return await request({
        url: `notifications/check`,
        method: GET,
    });
};

export const setViewed = async (ids) => {
    return await request({
        url: `notifications/read`,
        method: POST,
        body: { items: Array.isArray(ids) ? ids : [ids] },
    });
};

export const setSeen = async () => {
    return await request({
        url: `notifications/see`,
        method: POST,
    });
};
