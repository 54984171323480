import React from "react";
import PropTypes from "prop-types";

class LoadingIndicator extends React.Component {
    render() {
        if (!this.props.visible) {
            return null;
        }

        return (
            <div className="preloader-fix">
                <div className="preloader-boundary">
                    <div className="preloader">
                        <div
                            className="preloader-spinner"
                            style={{
                                width: this.props.size,
                                height: this.props.size
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

LoadingIndicator.defaultProps = {
    visible: true,
    size: `35px`
};

LoadingIndicator.propTypes = {
    visible: PropTypes.bool,
    size: PropTypes.string
};

export default LoadingIndicator;
