import call from "domain/SiteAPI/reduxAction";
import * as API from "./api";

export const NAMESPACE = `PLAYGROUNDS`;

export const GET = `${NAMESPACE}.GET`;
export const GET_REQUEST_START = `${GET}_REQUEST_START`;
export const GET_REQUEST_SUCCESS = `${GET}_REQUEST_SUCCESS`;
export const GET_REQUEST_ERROR = `${GET}_REQUEST_ERROR`;

export const get = ({ id }) => {
    return call({
        request: API.get.bind(null, { id }),
        namespace: GET,
        meta: { id },
    });
};
