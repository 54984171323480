import React from "react";
import PropTypes from "prop-types";

import Modal, { modalPropTypes } from "components/Modal";

import {
    ANIMATION_CLASS_OPENED,
    ANIMATION_CLASS_CLOSED,
    ANIMATION_DURATION,
} from "components/Modal";

class AnimatedModal extends React.Component {
    close = () => {
        const { onClose } = this.props;

        document.body.classList.add(ANIMATION_CLASS_CLOSED);
        setTimeout(() => {
            document.body.classList.remove(ANIMATION_CLASS_CLOSED);
            document.body.classList.remove(ANIMATION_CLASS_OPENED);
            onClose();
        }, ANIMATION_DURATION);
    };

    render() {
        const { children } = this.props;
        return (
            <Modal {...this.props} onClose={this.close} withAnimation>
                {typeof children === "function"
                    ? children(this.close)
                    : children}
            </Modal>
        );
    }
}

AnimatedModal.propTypes = {
    ...modalPropTypes,
    children: PropTypes.any,
    onClose: PropTypes.func.isRequired,
};

export default AnimatedModal;
